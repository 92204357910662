import React from 'react';

export const lazyLoadWithRetry = (importer) => {
    const retryImport = async () => {
        try {
            return await importer();
        } catch (error) {
            console.log("error importing.... retrying");
            // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
            for (let i = 0; i < 5; i++) {
                await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));

                var chunkUrls = error.toString().match(/(https(.)+)/);
                if (!chunkUrls || chunkUrls.length === 0) {
                    throw error;
                }
                var chunkUrl = chunkUrls[0];
                chunkUrl = chunkUrl.substring(0, chunkUrl.length - 1);
                const url = new URL(chunkUrl);
                // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
                url.searchParams.set("t", `${+new Date()}`);

                try {
                    return await import(url.href);
                } catch (e) {
                    console.log("retrying import");
                }
            }
            throw error;
        }
    };
    return React.lazy(retryImport);
};
