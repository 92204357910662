import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';

const BuyerSideClosingCosts = ({ data, setClosingCostsTotal, className, saving, setBuyersClosingCosts, loadedNetSheet }) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */
    const watchDocPrep = watch("documentationPreparationFee");
    const watchLendersTitlePolicy = watch("lendersTitlePolicy");
    const watchLendersTitlePolicySalesTax = watch("lendersTitlePolicySalesTax");
    const watchBuyersHomeOwnersPolicy = watch("homeownersPolicy");
    const watchBuyersHomeOwnersPolicySalesTax = watch("homeownersPolicySalesTax");
    const watchEndorsements = watch("endorsements");
    const watchGovernmentRecordingFees = watch("governmentRecordingFees");
    const watchSurveyFee = watch("surveyFee");
    const watchEscrowFee = watch("escrowFee");
    const watchEscrowFeeSalesTax = watch("escrowFeeSalesTax");
    const watchLoanOrgFee = watch("loanOriginationFee");
    const watchLoanProcessing = watch("loanProcessingFee");
    const watchPointsNumber = watch("discountPointsNumber");
    const watchPointsDollar = watch("discountPointsDollars");
    const watchCreditReportFee = watch("creditReportFee");
    const watchBuyerTaxServiceFee = watch("taxServiceFee");
    const watchFloodCertification = watch("floodCertificationFee");
    const watchCourierFee = watch("courierFee");
    const watchProtectionletter = watch("closingProtectionLetter");
    const watchWireFee = watch("wireFee");
    const watchBuyersThirdPartyServiceFee = watch("buyersThirdPartyServiceFee");
    const watchBuyersThirdPartyServiceFeeSalesTax = watch("buyersThirdPartyServiceFeeSalesTax");
    const watcheRecordingFee = watch("eRecordingFee");
    const watchAppraisalFee = watch("appraisalFee");
    const watchMortgageInsurancePremium = watch("mortgageInsurancePremium");
    const watchVAFundingFee = watch("vaFundingFee");
    const watchTotalClosingCosts = watch("totalClosingCosts");

    /* state vars */
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.buyersClosingCosts)) {
                if (newArr.includes(key)) {
                    if (key === 'discountPointsNumber') {
                        setValue(key, value);
                    } else { 
                        setValue(key, currency(value, { separator: ',', symbol: '' }).format());
                    }
                }
            }
            setManuallyChangedInputs(newArr);
            setDefaults(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data) return;
            setDefaults(manuallyChangedInputs);
        }
    }, [loadedNetSheet, data]);

    const setDefaults = (manuallyChanged) => {
        for (const [key, value] of Object.entries(data.buyersClosingCosts)) {
            if (!manuallyChanged.includes(key) && key !== 'totalClosingCosts' && !key.includes('SalesTax')) {
                setValue(key, currency(value, { separator: ',', symbol: '' }).format());
            }
        }
    }

    useEffect(() => {
        const sum = currency(watchDocPrep, { separator: ',', symbol: '' })
            .add(watchLendersTitlePolicy)
            .add(watchLendersTitlePolicySalesTax)
            .add(watchBuyersHomeOwnersPolicy)
            .add(watchBuyersHomeOwnersPolicySalesTax)
            .add(watchEndorsements)
            .add(watchGovernmentRecordingFees)
            .add(watchSurveyFee)
            .add(watchEscrowFee)
            .add(watchEscrowFeeSalesTax)
            .add(watchLoanOrgFee)
            .add(watchLoanProcessing)
            .add(watchPointsDollar)
            .add(watchCreditReportFee)
            .add(watchBuyerTaxServiceFee)
            .add(watchFloodCertification)
            .add(watchCourierFee)
            .add(watchProtectionletter)
            .add(watchWireFee)
            .add(watchBuyersThirdPartyServiceFee)
            .add(watchBuyersThirdPartyServiceFeeSalesTax)
            .add(watcheRecordingFee)
            .add(watchMortgageInsurancePremium)
            .add(watchVAFundingFee)
            .add(watchAppraisalFee).format();
        setValue("totalClosingCosts", sum);
        setClosingCostsTotal(sum);

    }, [watchDocPrep, watchLendersTitlePolicy, watchBuyersHomeOwnersPolicy, watchEndorsements, watchGovernmentRecordingFees,
        watchSurveyFee, watchEscrowFee, watchLoanOrgFee, watchLoanProcessing, watchPointsDollar, watchCreditReportFee,
        watchBuyerTaxServiceFee, watchFloodCertification, watchCourierFee, watchProtectionletter, watchWireFee,
        watchBuyersThirdPartyServiceFee, watchBuyersThirdPartyServiceFeeSalesTax,
        watcheRecordingFee, watchAppraisalFee, watchEscrowFeeSalesTax, watchBuyersHomeOwnersPolicySalesTax,
        watchLendersTitlePolicySalesTax, watchMortgageInsurancePremium, watchVAFundingFee]);

    useEffect(() => {
        if (!data) return;
        setValue('discountPointsDollars', currency(data.netSheetFormData.buyersLoanAmount1, { separator: ',', symbol: '' }).divide(100).multiply(watchPointsNumber).format());
    }, [watchPointsNumber, data])

    const setManuallyChanged = (e) => {
        for (const [key, value] of Object.entries(data.buyersClosingCosts)) {
            if (key === e.target.id
                && currency(value, { separator: ',', symbol: '' }).format() !== e.target.value
                && !manuallyChangedInputs.includes(e.target.id)) {
                setManuallyChangedInputs([...manuallyChangedInputs, e.target.id])
            }
        }
    }

    useEffect(() => { 
        setSalesTax("escrowFee", watchEscrowFee);
    }, [watchEscrowFee, data])

    useEffect(() => { 
        setSalesTax("homeownersPolicy", watchBuyersHomeOwnersPolicy);
    }, [watchBuyersHomeOwnersPolicy, data])

    useEffect(() => { 
        setSalesTax("lendersTitlePolicy", watchLendersTitlePolicy);
    }, [watchLendersTitlePolicy, data])

    useEffect(() => {
        setSalesTax("buyersThirdPartyServiceFee", watchBuyersThirdPartyServiceFee);
    }, [watchBuyersThirdPartyServiceFee, data])

    const setSalesTax = (id, val) => { 
        if (!data) return;
        var salesTaxId = id + 'SalesTax';
        setValue(salesTaxId, currency(val, { separator: ',', symbol: '' }).multiply(data.salesTaxRate).format())
    }

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setBuyersClosingCosts({formVals, manuallyChangedInputs});
    };

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseBuyerSideClosingCosts" aria-expanded="false" aria-controls="collapseBuyerSideCommonData">
                    <div className="col fw-bold">Closing Costs</div>
                    <div className="col text-end">Total: <span className="no-localization">{currency(watchTotalClosingCosts, { separator: ',', symbol: '$' }).format()}</span> </div>
                </div>
                <form onBlur={e => { formatInput(e); setManuallyChanged(e) }} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseBuyerSideClosingCosts">
                        <div className="accordion-body">
                            <div className="row row-cols-1 row-cols-sm-2">
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="documentationPreparationFee">Doc Prep:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="documentationPreparationFee" type="text" defaultValue="0.00" maxLength="8" id="documentationPreparationFee" className="form-control clear-zero currency" {...register("documentationPreparationFee")}/>
                                        </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="lendersTitlePolicy">Lender's Title Policy:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">

                                            <span className="input-group-text">$</span>
                                                <input name="lendersTitlePolicy" type="text" defaultValue="0.00" maxLength="8" id="lendersTitlePolicy" className="form-control hint clear-zero currency"  {...register("lendersTitlePolicy")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="lendersTitlePolicySalesTax">Lender's Title Policy Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">

                                            <span className="input-group-text">$</span>
                                            <input name="lendersTitlePolicySalesTax" type="text" defaultValue="0.00" maxLength="8" id="lendersTitlePolicySalesTax" readOnly="readonly" className="form-control hint clear-zero currency"  {...register("lendersTitlePolicySalesTax")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                                    
                                <div className={data.buyerPaysHomeOwnersCoverageOnePolicy ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="homeownersPolicy">Homeowner's Policy (CoverageOne):</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="homeownersPolicy" type="text" defaultValue="0.00" maxLength="8" id="homeownersPolicy" className="form-control hint clear-zero currency"  {...register("homeownersPolicy")} />
                                            </div>
                                            {currency(watchBuyersHomeOwnersPolicy).value > 1000000 && <span className="text-danger">Please call for a quote.</span>}                   
                                        </div>
                                    </div>
                                </div>

                                <div className={data.buyerPaysHomeOwnersCoverageOnePolicy && currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="homeownersPolicySalesTax">Homeowner's Policy Sales Tax::</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="homeownersPolicySalesTax" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="homeownersPolicySalesTax" className="form-control hint clear-zero currency"  {...register("homeownersPolicySalesTax")}/>
                                            </div>
                                                                
                                        </div>
                                    </div>
                                </div>
                                                    
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <span id="endorsementsLabel">{data.buyersClosingCosts.endorsementsLabel}:</span>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="endorsements" type="text" defaultValue="0.00" maxLength="8" id="endorsements" className="form-control clear-zero currency"  {...register("endorsements")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="governmentRecordingFees">Government Recording Fee*:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="governmentRecordingFees" type="text" defaultValue="0.00" maxLength="8" id="governmentRecordingFees" className="form-control clear-zero currency"  {...register("governmentRecordingFees")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="surveyFee">Survey:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="surveyFee" type="text" defaultValue="0.00" maxLength="8" id="surveyFee" className="form-control clear-zero currency"  {...register("surveyFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="escrowFee">Escrow Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="escrowFee" type="text" defaultValue="0.00" maxLength="8" id="escrowFee" className="form-control clear-zero currency"  {...register("escrowFee")}/>
                                        </div></div>
                                    </div>
                                </div>
                                <div className={currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="escrowFeeSalesTax">Escrow Fee Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">

                                            <span className="input-group-text">$</span>
                                            <input name="escrowFeeSalesTax" type="text" defaultValue="0.00" maxLength="8" id="escrowFeeSalesTax" readOnly="readonly" className="form-control hint clear-zero currency"  {...register("escrowFeeSalesTax")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>           
                                <div className={data.netSheetFormData.loanType === 'Cash' ? "d-none" : "col"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="loanOriginationFee">Loan Origination Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                            <input name="loanOriginationFee" type="text" defaultValue="0.00" maxLength="8" id="loanOriginationFee" className="form-control clear-zero currency"  {...register("loanOriginationFee")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col">
                                <div className="row pt-1">
                                    <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                        <label htmlFor="loanProcessingFee">Loan Processing Fee:</label>
                                    </div>
                                    <div className="col-12 col-sm-7">
                                        <div className="input-group">
                                            <span className="input-group-text">$</span>
                                        <input name="loanProcessingFee" type="text" defaultValue="0.00" maxLength="8" id="loanProcessingFee" className="form-control clear-zero currency"  {...register("loanProcessingFee")}/>
                                    </div>
                                    </div>
                                </div>
                            </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="discountPointsNumber">Discount Points:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">#</span>
                                                <input name="discountPointsNumber" type="number" step="1" defaultValue="0" id="discountPointsNumber" className="form-control" {...register("discountPointsNumber")}/>
                                                <span className="input-group-text">$</span>
                                                <input name="discountPointsDollars" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="discountPointsDollars" className="form-control"  {...register("discountPointsDollars")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="creditReportFee">Credit Report:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="creditReportFee" type="text" defaultValue="0.00" maxLength="8" id="creditReportFee" className="form-control clear-zero currency"  {...register("creditReportFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeBuyerTaxServiceFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="taxServiceFee">Tax Service Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="taxServiceFee" type="text" defaultValue="0.00" maxLength="8" id="taxServiceFee" className="form-control clear-zero currency"  {...register("taxServiceFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="floodCertificationFee">Flood Cert Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="floodCertificationFee" type="text" defaultValue="0.00" maxLength="8" id="floodCertificationFee" className="form-control clear-zero currency"  {...register("floodCertificationFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeBuyerForCourier ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="courierFee">Courier*:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="courierFee" type="text" defaultValue="0.00" maxLength="8" id="courierFee" className="form-control clear-zero currency"  {...register("courierFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.netSheetFormData.loanType === 'Cash' ? "d-none" : data.chargeForClosingProtectionLetter ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="closingProtectionLetter">Closing Protection Letter (per loan):</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="closingProtectionLetter" type="text" defaultValue="0.00" maxLength="8" id="closingProtectionLetter" className="form-control clear-zero currency"  {...register("closingProtectionLetter")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeForWire ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="wireFee">Wire*:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="wireFee" type="text" defaultValue="0.00" maxLength="8" id="wireFee" className="form-control clear-zero currency"  {...register("wireFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeBuyerThirdPartyServiceFee ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="buyersThirdPartyServiceFee">3rd Party Service Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="buyersThirdPartyServiceFee" type="text" defaultValue="0.00" maxLength="8" id="buyersThirdPartyServiceFee" className="form-control clear-zero currency"  {...register("buyersThirdPartyServiceFee")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeBuyerThirdPartyServiceFee && currency(data.salesTaxRate).value !== currency(0).value ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="buyersThirdPartyServiceFeeSalesTax">3rd Party Service Fee Sales Tax:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="buyersThirdPartyServiceFeeSalesTax" type="text" defaultValue="0.00" maxLength="8" id="buyersThirdPartyServiceFeeSalesTax" className="form-control clear-zero currency"  {...register("buyersThirdPartyServiceFeeSalesTax")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.chargeForeRecording ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="eRecordingFee">eRecording:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="eRecordingFee" type="text" defaultValue="0.00" maxLength="8" id="eRecordingFee" className="form-control clear-zero currency"  {...register("eRecordingFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={data.netSheetFormData.buyersDownPaymentPct < 20 ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="mortgageInsurancePremium">Mortgage Insurance Premium (MIP):</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="mortgageInsurancePremium" type="text" defaultValue="0.00" maxLength="8" id="mortgageInsurancePremium" className="form-control clear-zero currency"  {...register("mortgageInsurancePremium")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className={data.netSheetFormData.loanType === 'VA' ? "col" : "d-none"}>
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="vaFundingFee">VA Funding Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="vaFundingFee" type="text" defaultValue="0.00" maxLength="8" id="vaFundingFee" className="form-control clear-zero currency"  {...register("vaFundingFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="appraisalFee">Appraisal Fee:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="appraisalFee" type="text" defaultValue="0.00" maxLength="8" id="appraisalFee" className="form-control clear-zero currency"  {...register("appraisalFee")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                    <div className="col-12 col-sm-4 form-label fw-bold">
                                        <label htmlFor="totalClosingCosts">Total Closing Costs:</label>
                                    </div>
                                    <div className="col-12 col-sm-7">
                                        <div className="input-group">
                                            <span className="input-group-text">$</span>
                                            <input name="totalClosingCosts" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="totalClosingCosts" tabIndex="-1" className="form-control" {...register("totalClosingCosts")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default BuyerSideClosingCosts;