import React, { useState, useEffect, Fragment, useContext} from 'react';
import apiTools from '../../services/api-tools';
import { MdOutlineCalculate } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { toTitleCase } from '../../services/utilities';
import { UserContext } from '../../contexts/user-context';
import "./recent-properties.scss"

const RecentProperties = ({  onRecentlySelectedProperty, selectedProperty }) => {
    const [recentProps, setRecentProps] = useState(null);
    const { activeRegion } = useContext(UserContext);
    useEffect(() => {
        apiTools.get('/viewed-properties?regionId=' + activeRegion.regionId)
           .then((response) => {
               setRecentProps(response.data.filter(t => activeRegion.stateAndCounties.map(t2 => t2.county.toLowerCase()).includes(t.county.toLowerCase())));
           });
    }, [selectedProperty, activeRegion]);

    const onSelectRecentProperty = (recentProps) => {
        var county = activeRegion.stateAndCounties.find(t => t.county.toLowerCase() === recentProps.county.toLowerCase());
        var addressStrings = recentProps.address.split(", ");
        onRecentlySelectedProperty({ parcelId: recentProps.parcelId, siteAddress: addressStrings[0], siteCity: addressStrings[1], ownerName: recentProps.ownerName }, county, county.state );
    }
 
  return (
     <>  
        <div className="row p-0">
            <div className="col-12">
                <div className="row recentInfo">
                    <div className="col-3 p-0 ps-md-2"><strong>Viewed</strong></div>
                    <div className="col-6 p-0"><strong>Property Address / County</strong></div>
                      <div className="col-3 text-center p-0"><strong>Net Sheet</strong></div>
                </div>
                <div className="row">
                    {(!recentProps || recentProps.length===0) ? <div className="col-12">No Recent Properties</div> : 
                    recentProps && recentProps.map(function (recentPropInfo, Index) {
                        return <Fragment key={Index}>
                            <div className="col-3 p-0 ps-md-2 recentInfo no-localization">{DateTime.fromISO(recentPropInfo.dateLastViewed).toFormat("MM/dd/yyyy")}</div>
                            <div className="col-7 p-0 no-localization"><button className="btn btn-link p-0 recentInfo text-start no-localization" onClick={() => onSelectRecentProperty(recentPropInfo)}>{recentPropInfo.address} / {toTitleCase(recentPropInfo.county)}</button></div>
                            <div className="col-2 text-center ps-0 pe-md-3 pe-xl-5 no-localization"><Link to={"/net-sheet/address/" + recentPropInfo.county + "/" + recentPropInfo.parcelId} aria-label={"Start net sheet for " + recentPropInfo.address}><MdOutlineCalculate className="sm-react-icons" /></Link></div>
                        </Fragment>;
                    })
                    }
                </div>
            </div>    
        </div>
     </>
  );
}

export default RecentProperties;