import React from 'react';
import { OopsContent } from './oops';
import apiPublic from '../services/api-public';
import { BrandContext } from '../contexts/brand-context';
import { BuildAxiosClientErrorDetail } from '../services/utilities';


export class ErrorBoundary extends React.Component {
    static contextType = BrandContext;
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        const postError = async (errObj) => {
            await apiPublic.post(`/log-error`, errObj, { noErrorHandling: true });
        }
        if (error.config && error.status && error.data) {
            postError({ ...BuildAxiosClientErrorDetail(error), brand: this.context.selectedBrand });
        }
        else {
            postError({ title: "Caught In Error Boundary", detailObject: errorInfo, detail: error.toString(), url: window.location.href, brand: this.context.selectedBrand });
        }

    }

    render() {
        if (this.state.hasError) {
          return <OopsContent />;
        }

        return this.props.children;
    }
}
