import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import SpinnerLoader from '../../layout/spinner-loader';
import { EventTrack } from '../../../services/utilities';

const ClosingCostCalcSaveName = ({ previouslySavedQuoteName, saveQuoteName, savedQuoteName, quoteNameSaved, setQuoteNameSaved, propAddress, loanAmount, logActivity }) => {
    const { register, setValue, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    
    const onSubmit = data => {
        setLoading(true);
        saveQuoteName(data.savedQuoteName);
        logActivity("Closing Cost Quote Saved", `Quote Name: ${data.savedQuoteName}, \nAddress: ${propAddress}, \nLoan Amount: ${loanAmount}`);
        EventTrack("Closing Cost Quote", "Saved", propAddress);
    }

    useEffect(() => {
        setLoading(false);
    }, [quoteNameSaved]);

    useEffect(() => {
        if (!previouslySavedQuoteName) return;
        setValue('savedQuoteName', previouslySavedQuoteName);
    }, [previouslySavedQuoteName]);

    return (<>
        <div className="modal fade" id="saveClosingCostQuoteNameModal" tabIndex="-1" aria-labelledby="saveClosingCostQuoteNameModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="saveClosingCostQuoteModalLabel">Save Closing Cost Quote</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {quoteNameSaved && !loading && <p>Thank you, your quote has been saved.</p>}
                            {!quoteNameSaved && !loading && <div className="row">
                                <div className="col-12 mt-2">
                                    <label className="fw-bold required">Quote Name:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <input type="text" className="form-control" id="savedQuoteName" {...register("savedQuoteName", { required: true })} />
                                    {errors?.savedQuoteName?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                            </div>}
                            {loading && <SpinnerLoader />}
                            <div className="modal-footer">
                                {!quoteNameSaved && <button type="submit" className="btn btn-primary m-auto">Save Quote</button>}
                                <span onClick={() => setQuoteNameSaved(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{quoteNameSaved ? "Close" : "Cancel"}</button></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default ClosingCostCalcSaveName;