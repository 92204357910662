import { useContext } from 'react';
import { BrandContext } from '../../../contexts/brand-context';
import SiteSearchNav from './site-search-nav'
import UserProfileNav from './user-profile-nav'
import SocialLinks from '../social-links';
import LanguageSelection from './language-selection'
import './nav-lead.scss';

const NavLead = () => {
    const { selectedBrand } = useContext(BrandContext);

    return (
        <nav aria-label="social nav" className="navbar bg-light d-none d-lg-block d-print-none">
            <div className="container-fluid container-xl justify-content-end">
                <div className="d-flex">
                    <SiteSearchNav className="pe-3" />
                    {selectedBrand && selectedBrand.brandId !== 4 && <UserProfileNav className="pe-3" />}
                    <div className="socialLinks">
                        <SocialLinks brand={selectedBrand} />
                        <LanguageSelection />
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavLead;