import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import LinkExternal from '../layout/link-external';
import './street-map.scss';
import { MdDirectionsWalk, MdDirectionsBike, MdDirectionsBus } from 'react-icons/md'

const StreetMap = ({ property, setStreetMapAvailable, directions, setDirections, position, setPosition, setOriginAddress, selectedPropertyP1Data, state }) => {
    const [origin, setOrigin] = useState(null);
    const [noStreetMap, setNoStreetMap] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

    const defaultZoomLevel = 19;

    async function createGoogleMap() {
		const { Map } = await window.google.maps.importLibrary("maps");
        const { SearchBox } = await window.google.maps.importLibrary("places");
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        new SearchBox(document.getElementById("origin"));

		var position = {lat: 43.667872, lng: -116.325073};
		var map = new Map(document.getElementById("map"), {
			zoom: 4,
			center: position,
			zoomControlOptions: {
				position: window.google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
			},
            mapId: '158f46f8615e65d2'
		});
		if (!map) return;
        if ( !property.latitude || !property.longitude) {
            axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + property.siteAddress + "," + property.siteCity + "," + state + "&key=" + googleMapsAPIKey)
                .then((response) => {
                    if (response.data.results.length === 0) {
                        setNoStreetMap(true);
                        setStreetMapAvailable(false);
                        return;
                    } else {
                        setNoStreetMap(false);
                        setStreetMapAvailable(true);
                        position = new window.google.maps.LatLng(response.data.results[0].geometry.location.lat, response.data.results[0].geometry.location.lng);
                        map.setZoom(defaultZoomLevel);
                        map.setCenter(position);
                        setMap(map);
                        setPosition(position);
                        const advancedMarker = new AdvancedMarkerElement({
                            map,
                            position: position,
                        });
                        setMarker(advancedMarker);

                    }
                });
        } else {
            position = new window.google.maps.LatLng(property.latitude, property.longitude);
            map.setZoom(defaultZoomLevel);
            map.setCenter(position);
            setMap(map);
            setPosition(position);
            const advancedMarker = new AdvancedMarkerElement({
                map,
                position: position,
            });
            setMarker(advancedMarker);
        }

		setMap(map);
    };

	useEffect(() => {
        if (!window.google || !window.google.maps) {
            (g => { var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window; b = b[c] || (b[c] = {}); var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams, u = () => h || (h = new Promise(async (f, n) => { await (a = m.createElement("script")); e.set("libraries", [...r] + ""); for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]); e.set("callback", c + ".maps." + q); a.src = `https://maps.${c}apis.com/maps/api/js?` + e; d[q] = f; a.onerror = () => h = n(Error(p + " could not load.")); a.nonce = m.querySelector("script[nonce]")?.nonce || ""; m.head.append(a) })); d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)) })({
                key: googleMapsAPIKey,
                v: "weekly",
            });
        }
	 }, []);

    useEffect(() => {
        setDirections(null);
        setOrigin(null);
        setPosition(null);
        reset();
        document.getElementById('stepsPanel').innerHTML = '';
        createGoogleMap();
    }, [property]);

    const onSubmit = (data) => {
        setOriginAddress(data.origin);
        axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + data.origin + "&key=" + googleMapsAPIKey)
        .then((response) => {
            var point = new window.google.maps.LatLng(response.data.results[0].geometry.location.lat, response.data.results[0].geometry.location.lng);
            setOrigin(point);
        });
    }
    async function renderDirections() {
        const {DirectionsRenderer, DirectionsService} = await window.google.maps.importLibrary("routes")
        const directionsRenderer = new DirectionsRenderer();
        const directionsService = new DirectionsService();
        directionsRenderer.setMap(null);
        directionsRenderer.setMap(map);
        directionsRenderer.setPanel(null);
        directionsRenderer.setPanel(document.getElementById('stepsPanel'));
        directionsService.route(
          {
            origin: origin,
            destination: position,
            travelMode: window.google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
                directionsRenderer.setDirections(result);
                marker.position = null;
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
    }
    useEffect(() => {
        if (!origin || !position) return;
        renderDirections();
    }, [origin, position]);

    return (<>       
        {noStreetMap && <div>Sorry, there is no street map available for this property.</div>}
        <div className="row">
            <div className="col-4 col-lg-2 no-localization">
                <MdDirectionsWalk className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Walk Score<sup>&reg;</sup>: {selectedPropertyP1Data && selectedPropertyP1Data.wsWalkScore ? selectedPropertyP1Data.wsWalkScore : 0}
                </LinkExternal>
            </div>
            {selectedPropertyP1Data && selectedPropertyP1Data.wsBikeScore && <div className="col-4 col-lg-2 no-localization"><MdDirectionsBike className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Bike Score<sup>&reg;</sup>: {selectedPropertyP1Data.wsBikeScore}</LinkExternal></div>}
            {selectedPropertyP1Data && (selectedPropertyP1Data.wsTransitScore || selectedPropertyP1Data.wsTransitScore === 0) && <div className="col-4 col-lg-2 no-localization"><MdDirectionsBus className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Transit Score<sup>&reg;</sup>: {selectedPropertyP1Data.wsTransitScore}</LinkExternal></div>}
        </div>
        <div className={(!property || noStreetMap) ? "d-none" : "row mx-1"}>
			<div className="col map-container">
				<div id="map" className="card"></div>
			</div>
            <div className="col-12 col-md-6 pt-2 pt-md-0">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mb-2">
                        <div className="col-8">
                            <input name="origin" type="text" id="origin" placeholder="Enter from address" className="form-control" {...register("origin", { required: true })}/>
                            {errors?.searchCriteria?.type === "required" && <small className="text-danger">This field is required</small>}
                        </div>
                        <div className="col-4">
                            <button type="submit" className="btn btn-primary float-end">Get Directions</button>
                        </div> 
                    </div>
                </form>
                <div id="stepsPanel" className={directions ? "overflow-auto" : "d-none"} style={{ height: '492px' }}></div>
            </div>            
        </div>
    </>
    );     
}

export default StreetMap;