import React, {useCallback, useEffect, useState }from 'react';
import apiPublic from '../../services/api-public';
import "./credit-card-payment.scss"


const CreditCardPayment = (props) => {
    const authData = { apiLoginId: process.env.REACT_APP_AUTH_NET_LOGIN, clientKey: process.env.REACT_APP_AUTH_NET_PUBLIC_CLIENT_KEY };
    const [errors, setErrors] = useState(null);
    const [transactionErrors, setTransactionErrors] = useState([]);
    const scrollEnabled = useTrait(true);

    function useTrait(initialValue) {
        const [trait, updateTrait] = useState(initialValue);

        let current = trait;

        const get = () => current;

        const set = newValue => {
            current = newValue;
            updateTrait(newValue);
            return current;
        }

        return {
            get,
            set,
        }
    }
    const submitting = useTrait(false);

    const cardSubmit = (response) => {
        props.onClose();
        if (response && response.messages.resultCode === "Ok") {
            const acceptPaymentTransaction = async () => {
                await apiPublic.post(`/payment`, { fee: props.fee, description: props.description, opaqueData: response.opaqueData, invoiceNumber: props.invoiceNumber }).then((res) => {
                    if (res.data.errors.length > 0) {
                        setTransactionErrors(res.data.errors);
                    }
                    else {
                        setTransactionErrors([]);
                    }
                    props.setTransactionResponse(res.data);
                    enableScrolling();
                    submitting.set(false);
                });
            }
            acceptPaymentTransaction();
        }
    };

    useEffect(() => {
        var src = process.env.REACT_APP_AUTH_NET_ENVIRONMENT === 'SANDBOX' ? "https://jstest.authorize.net/v3/AcceptUI.js" : "https://js.authorize.net/v3/AcceptUI.js";
        var loadScript = function (src) {
          var tag = document.createElement('script');
            tag.async = false;
            tag.src = src;
            tag.type = "text/javascript";
            document.head.appendChild(tag)
        }
        const scripts = document.head.getElementsByTagName('script')
        Array.from(scripts)
            .filter(
                script =>
                    script.src === src
            ).forEach(injectedScript => document.head.removeChild(injectedScript));
        loadScript(src);
        props.setTransactionResponse(null);
        setErrors(null);
        setTransactionErrors([]);
    }, [props.description])

    const responseHandler = useCallback(
        (response) => {
            if (submitting.get()) return;
            submitting.set(true);
            if (response.messages.resultCode === 'Error') {
                setErrors(response.messages.message);
            } else {
                setErrors(null);
                cardSubmit(response);
            }
    }, [cardSubmit]);

    useEffect(() => {
        window.responseHandler = responseHandler;
    }, [responseHandler]);

    function disableScrolling(){
        scrollEnabled.set(false);
        var x=window.scrollX;
        var y=window.scrollY;
        window.onscroll=function(){window.scrollTo(x, y);};
        document.onmouseover = function () { enableScrolling();};
    }

    function enableScrolling(){
        if (scrollEnabled.get()) return;
        scrollEnabled.set(true);
        window.onscroll=function(){};
    }
    return (<>
        <button
            type="button"
            style={props.buttonStyle}
            className={"AcceptUI btn btn-primary " + ((props.className) ? props.className : "")}
            data-billingaddressoptions={JSON.stringify(props.billingAddressOptions)}
            data-apiloginid={authData.apiLoginId}
            data-clientkey={authData.clientKey}
            data-acceptuiformbtntxt={props.formButtonText}
            data-acceptuiformheadertxt={props.formHeaderText}
            data-paymentoptions={JSON.stringify(props.paymentOptions)}
            data-responsehandler="responseHandler"
            disabled={props.disabled}
            onMouseDown={() => {disableScrolling();} }

        >
             {props.buttonText}
        </button>
        {errors && (
            <div style={props.errorTextStyle} className="text-danger">
                {typeof errors === 'string'
                ? errors
                : errors.map((error, index) => (
                    <small key={`error-${index}`}>
                        Error {error.code}: {error.text}
                    </small>
                    ))}
            </div>
        )}
        {transactionErrors && (
            <div style={props.errorTextStyle} className="text-danger">
                {transactionErrors.map((error, index) => {
                    return <span key={`error-${index}`}>
                        Error {error.errorCode}: {error.errorText}
                    </span>;
                })}
            </div>
        )}
        </>);
}

export default CreditCardPayment;