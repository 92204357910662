import './language-selection.scss';
import { useLocation } from 'react-router-dom';
import { GetSelectedLanguage } from '../../../services/utilities';

const LanguageSelection = () => {
    const location = useLocation();
    const selectedLanguage = GetSelectedLanguage();

    return (
        <>
            {(selectedLanguage === "en") && <a hrefLang="es" className="language-link ps-0 ps-lg-2" href={`${window.location.origin}/es${location.pathname}`}>ESPAÑOL</a> }
            {(selectedLanguage === "es") && <a hrefLang="en" className="no-localization language-link ps-0 ps-lg-2" href={`${window.location.origin}${location.pathname}`}>ENGLISH</a> }
        </>
    );
}

export default LanguageSelection;