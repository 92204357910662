import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import apiPublic from '../../../services/api-public';
import { BrandContext } from '../../../contexts/brand-context';

const FooterLocations = () => {
    const [offices, setOffices] = useState([]);
    const { selectedBrand } = useContext(BrandContext);

    useEffect(() => {
        if (!selectedBrand) return;
        const getOffices = async () => {

            apiPublic.get('/officesforfooter/', { noErrorHandling: true })
                .then((resp) => {
                    setOffices(resp.data);
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                });
        }
        getOffices();
    }, []);

    return offices && offices.map(function (state, stateIndex) {
        return <div key={stateIndex}>
            <div className="d-lg-none">
                <div className="row no-localization">
                    <a href={'#' + state[0].stateFullName + 'Collapse'} role="button" aria-expanded="false" aria-controls={state[0].stateFullName + 'Collapse'} data-bs-toggle="collapse" className="col-12 pt-lg-1 fw-bold">{state[0].stateFullName}</a>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-4 collapse no-localization" id={state[0].stateFullName + 'Collapse'}>
                    {state && state.map(function (office, officeIndex) {
                        return <Link key={officeIndex} className="col pe-0" to={"/locations/" + office.officeName}>{office.officeName}</Link>;
                    })}
                </div>
            </div>
            <div className="d-none d-lg-block">
                <div className="row">
                    <div className="col-12 pt-1 fw-bold no-localization">{state[0].stateFullName}</div>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-4">
                    {state && state.map(function (office, officeIndex) {
                        return <Link key={officeIndex} className="col pe-0 no-localization" to={"/locations/" + office.officeName}>{office.officeName}</Link>;
                    })}
                </div>
            </div>
            

        </div>;
    });
}

export default FooterLocations;