import React, { Fragment } from 'react';
import { FaLinkedin, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import LinkExternal from './link-external';
import './social-links.scss';

const SocialLinks = ({brand}) => {

    var getSocialLink = function (typeName) {
        return (brand && brand.brandLinks) ? brand.brandLinks.find(bl => bl.brandLinkType.typeName === typeName) : null;
    };

    return (
        <Fragment>
            {getSocialLink('Facebook') && <LinkExternal href={getSocialLink('Facebook').urlOrRoute} className="" aria-label="Facebook - opens in a new tab"><FaFacebookSquare /></LinkExternal> }
            {getSocialLink('Instagram') && <LinkExternal href={getSocialLink('Instagram').urlOrRoute} className="" aria-label="Instagram - opens in a new tab"><FaInstagramSquare /></LinkExternal>}
            {getSocialLink('LinkedIn') && <LinkExternal href={getSocialLink('LinkedIn').urlOrRoute} className="" aria-label="LinkedIn - opens in a new tab"><FaLinkedin /></LinkExternal>}
        </Fragment>
    );

}

export default SocialLinks;