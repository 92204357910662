import React, { useContext, useState } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { useForm } from "react-hook-form";
import apiTools from '../../services/api-tools';
import { Link } from 'react-router-dom';
import { MdOutlineEmail } from 'react-icons/md';
import { EventTrack } from '../../services/utilities';

const CcrAndPlatEmail = ({ ccr, getDownloadLink, id , folderName, logActivity}) => {
    const { selectedBrand } = useContext(BrandContext);
    const [emailSent, setEmailSent] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        var emailCCRObject = {
            to: data.to,
            brand: selectedBrand,
            notes: data.notes,
            documentName: ccr.display ? ccr.display : ccr.documentName + `(${ccr.instrumentType})`,
            downloadLink: getDownloadLink(ccr),
            folderName: folderName
        }

        const sendEmail = async () => {
            await apiTools.post(`/email-ccr`, emailCCRObject).then((response) => setEmailSent(true));
            logActivity("CC&R Emailed", `Emailed ${(ccr.display) ? ccr.display : ccr.folderName + ' ' + ccr.documentName}`);
            EventTrack("CC&R and Plat Search", "Emailed", ccr.display);
        }
        sendEmail();
    }

    return (<>
        <div className="col-2 float-end text-end">
            <Link to="" data-bs-toggle="modal" data-bs-target={"#emailCCRsModal" + id}><MdOutlineEmail className="sm-react-icons pe-1" /></Link><Link to="" data-bs-toggle="modal" data-bs-target={"#emailCCRsModal" + id} className="ps-1">Email</Link>
        </div>
        <div className="modal fade" id={"emailCCRsModal" + id} tabIndex="-1" aria-labelledby="emailCCRsModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="emailCCRsModalLabel">Email CC&Rs/Plats</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                <div className="modal-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {emailSent ? <p>Thank you, your email has been sent.</p> : <div className="row">
                            <div className="col-12 mt-2">
                                <label className="fw-bold required" htmlFor="emailTo">To address(es):</label>
                            </div>
                            <div className="col-12 mt-2">
                                <input type="email" multiple className="form-control" id="emailTo" {...register("to", { required: true })} />
                                <small>Separate multiple addresses with a comma (,).</small>
                                {errors?.to?.type === "required" && <small className="text-danger">This field is required</small>}
                            </div>
                            <div className="col-12 mt-2">
                                <label className="fw-bold" htmlFor="emailNotes">Notes:</label>
                            </div>
                            <div className="col-12 mt-2">
                                <textarea id="emailNotes" rows="3" className="form-control" {...register("notes")} ></textarea>
                            </div>
                        </div>}

                        <div className="modal-footer">
                            {!emailSent && <button type="submit" className="btn btn-primary m-auto">Send Email</button>}
                            <span onClick={() => setEmailSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{emailSent ? "Close" : "Cancel"}</button></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default CcrAndPlatEmail;