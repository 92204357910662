import axios from 'axios';
import { BuildAxiosClientErrorDetail } from './utilities';

const apiPublic = axios.create({
  baseURL: process.env.REACT_APP_API_PUBLIC_BASE_URL
});

apiPublic.interceptors.request.use(
    request => {
        request.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_PUBLIC_SUBSCRIPTION_KEY;
        return request;
    },
    error => {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

apiPublic.interceptors.response.use(
    response => {
        // Return a successful response back to the calling service
        return response;
    },
    error => {
        if (error.response.status !== 0 && !error.config.noErrorHandling) {
            const selectedBrand = JSON.parse(localStorage.getItem("selectedBrand"));
            const errObj = { ...BuildAxiosClientErrorDetail(error), brand: selectedBrand };

            return apiPublic.post(`/log-error`, errObj, { noErrorHandling: true })
                .finally(() => {
                    window.location.href = '/oops';
                });
        }
        return new Promise((resolve, reject) => {
            reject(error.response);
        });
    }
);

export default apiPublic;