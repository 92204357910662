import React, { useRef, useState, useEffect } from 'react';
import currency from 'currency.js';
import BuyerSideCommonData from './buyer-side-common-data';
import BuyerSideClosingCosts from './buyer-side-closing-costs';
import BuyerSidePrepaidExpenses from './buyer-side-prepaid-expenses';
import BuyerSideMiscellaneous from './buyer-side-miscellaneous';
import BuyerSideTotals from './buyer-side-totals';

const BuyerSide = ({ data, className, saving, setBuyersData, setMiscOpposites, miscData, loadedNetSheet}) => {
    const headingRef = useRef(null);
    const [propertyInsurance, setPropertyInsurance] = useState(null);
    const [closingCostsTotal, setClosingCostsTotal] = useState(0);
    const [prepaidExpensesTotal, setPrepaidExpensesTotal] = useState(0);
    const [miscellaneousTotal, setMiscellaneousTotal] = useState(0);
    const [cashNeeded, setCashNeeded] = useState(0);

    const [buyersCommonData, setBuyersCommonData] = useState(null);
    const [buyersClosingCosts, setBuyersClosingCosts] = useState(null);
    const [buyersPrepaidExpenses, setBuyersPrepaidExpenses] = useState(null);
    const [buyersMiscellaneous, setBuyersMiscellaneous] = useState(null);
    const [buyersTotals, setBuyersTotals] = useState(null);

    useEffect(() => { 
        if (!buyersCommonData || !buyersClosingCosts || !buyersPrepaidExpenses || !buyersMiscellaneous || !buyersTotals || !data) return;
        var obj = {
            buyerCommonData: { ...buyersCommonData, principalAndInterestPerMonth: currency(data.monthlyPayment1 + data.monthlyPayment2) },
            buyersClosingCosts: { ...buyersClosingCosts.formVals }, 
            buyersPrepaidExpenses: { ...buyersPrepaidExpenses.formVals }, 
            buyersMiscellaneousChargesAndCredits: { ...buyersMiscellaneous.formVals }, 
            ...buyersTotals,
            ManuallyChangedValues: [...new Set([ ...buyersClosingCosts.manuallyChangedInputs, ...buyersPrepaidExpenses.manuallyChangedInputs, ...buyersMiscellaneous.manuallyChangedInputs])]
        };
        setBuyersData(obj);
    }, [buyersCommonData, buyersClosingCosts, buyersPrepaidExpenses, buyersMiscellaneous, buyersTotals])

    useEffect(() => { 
        if (!data) return;
        const sum = currency(closingCostsTotal, { separator: ',', symbol: '' })
            .add(prepaidExpensesTotal)
            .add(miscellaneousTotal)
            .add(data.netSheetFormData.buyersDownPayment)
            .value;
        setCashNeeded(sum);
    }, [closingCostsTotal, prepaidExpensesTotal, miscellaneousTotal, data])


    return (
        <div className={className}>      
            <h1 ref={headingRef}>Buyer's Net Sheet</h1>
            <BuyerSideCommonData data={data} setPropertyInsurance={setPropertyInsurance} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setBuyersCommonData={setBuyersCommonData} />
            <BuyerSideClosingCosts data={data} setClosingCostsTotal={setClosingCostsTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setBuyersClosingCosts={setBuyersClosingCosts } />
            <BuyerSidePrepaidExpenses data={data} propertyInsurance={propertyInsurance} setPrepaidExpensesTotal={setPrepaidExpensesTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setBuyersPrepaidExpenses={setBuyersPrepaidExpenses } />
            <BuyerSideMiscellaneous data={data} setMiscellaneousTotal={setMiscellaneousTotal} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setBuyersMiscellaneous={setBuyersMiscellaneous}  setMiscOpposites={setMiscOpposites} miscData={miscData} />
            <BuyerSideTotals data={data} total={cashNeeded} className="mb-2 accordion" saving={saving} loadedNetSheet={loadedNetSheet} setBuyersTotals={setBuyersTotals}/>
        </div>
    );
}

export default BuyerSide;