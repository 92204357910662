import React, { useState, useEffect, useContext } from 'react';
import { MdOutlineInsertDriveFile } from 'react-icons/md'
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { FormatPhoneNumber, EventTrack } from '../../services/utilities';
import { BrandContext } from '../../contexts/brand-context';
import BlaineCcrs from './blaine-ccrs';
import CcrAndPlatEmail from '../real-estate-pros/ccr-and-plat-email';
import fileDownload from 'js-file-download';
import QuickProfileRequest from './quick-profile-request';

const CCRs = ({ pcUser, activeRegion, subdivisionSearchData, selectedPropertyP1Data, forSearchPage, logActivity, ccrState, setCcrState, folderGroupOpen, setFolderGroupOpen, showQuickProfileRequestTab }) => {
    const { selectedBrand } = useContext(BrandContext);
    const [doingDownload, setDoingDownload] = useState(false);
    const [downloadError, setDownloadError] = useState(null);

    useEffect(() => {
        const getCcrs = async (state, county, subName, parcelId) => {
            var trimmedSubdivision = subName.trim();
            var encodedSubdivision = encodeURIComponent(trimmedSubdivision);
            apiTools.get(`/ccrs?state=${state}&county=${county}&subdivision=${encodedSubdivision}` + ((parcelId) ? `&parcelId=${parcelId}` : ""))
                .then((response) => {
                    setCcrState({ ccrs: response.data, loading: false });
                }).catch((e) => {
                    setCcrState({ ccrs: [], loading: false });
                });
        }

        setDownloadError(null);
        setCcrState({ ccrs: [], loading: true });
        if (selectedPropertyP1Data) {
            if (selectedPropertyP1Data.subdivision) {
                getCcrs(selectedPropertyP1Data.state, selectedPropertyP1Data.county, selectedPropertyP1Data.subdivision, selectedPropertyP1Data.parcelId);
            }
            else {
                setCcrState({ ccrs: [], loading: false });
            }
        }
        else if (subdivisionSearchData) {
            getCcrs(subdivisionSearchData.state, subdivisionSearchData.county, subdivisionSearchData.subdivision);
        }
    }, [selectedPropertyP1Data, subdivisionSearchData]);

    const getDisplayName = (ccr) => {
        return <span>{ccr.documentNumber ? ccr.documentNumber + ' - ' : ''}{ccr.display ? ccr.display : ccr.documentName + (ccr.instrumentType.includes('204') ? '' : ` (${ccr.instrumentType})`)}</span>
    }

    const getDownloadLink = (ccr) => {
        var state = selectedPropertyP1Data ? selectedPropertyP1Data.state : subdivisionSearchData ? subdivisionSearchData.state : '';
        var county = selectedPropertyP1Data ? selectedPropertyP1Data.county : subdivisionSearchData ? subdivisionSearchData.county : '';
        return process.env.REACT_APP_API_TOOLS_BASE_URL +
            `download-ccr?subscription-key=${process.env.REACT_APP_API_TOOLS_SUBSCRIPTION_KEY}&` +
            `state=${state}&county=${county}&` +
            ((ccr.documentSource !== 'Azure') ? `docNum=${ccr.ccrId ? ccr.ccrId : ccr.documentNumber}&` : '') +
            ((ccr.documentSource === 'Azure') ? `fileName=${encodeURIComponent(ccr.documentName ? ccr.documentName : ccr.fileName.replace(/\.[^/.]+$/, ""))}&` : '') +
            (ccr.documentLink ? `documentLink=${encodeURIComponent(ccr.documentLink)}&` : '') +
            (selectedBrand.brandName ? `brandName=${selectedBrand.brandName}&` : '') +
            (ccr.documentSource ? `docSource=${ccr.documentSource}` : '')
    }

    const doDownload = async (ccr) => {
        setDoingDownload(true);
        var downloadLink = getDownloadLink(ccr);
        apiTools.get(downloadLink, { responseType: 'blob', noErrorHandling: true })
            .then((response) => {
                var fileName = (ccr.documentName ? ccr.documentName : ccr.fileName.replace(/\.[^/.]+$/, "")) + ".pdf";
                setDoingDownload(false);
                setDownloadError(null);
                try {
                    fileDownload(response.data, `${selectedBrand.brandName.replace(/ /g, "-")}-${fileName}`);
                    logActivity("CC&R Lookup", `Viewed ${ccr.display}`);
                    EventTrack("CC&R and Plat Search", "Downloaded", ccr.display);
                }
                catch (ex) {
                    console.log(ex);
                }
            })
            .catch((err) => {
                setDownloadError("There was a problem retrieving the file you requested, please try again.");
                setDoingDownload(false);
                console.log("error downloading ccr", err);
            });
    }
    
    return (
        <>
            {!ccrState.loading && !doingDownload && downloadError && <div className="alert alert-dark text-danger mb-0" role="alert">{downloadError}</div>}

            {(ccrState.loading || doingDownload) && <SpinnerLoader />}
            {!ccrState.loading && !doingDownload && ccrState.ccrs && ccrState.ccrs.length > 0 &&
                <div className="p-2">
                    {!forSearchPage && <p className="text-danger">Due to the complicated nature of property descriptions, not all documents shown may apply.</p>}
                    {selectedBrand.brandId === 2 ? <BlaineCcrs forSearchPage={forSearchPage} ccrs={ccrState.ccrs} getDownloadLink={getDownloadLink} logActivity={logActivity} folderGroupOpen={folderGroupOpen} setFolderGroupOpen={setFolderGroupOpen} /> :
                        ccrState.ccrs.map((ccr, index) => {
                            return <div className="row" key={index}>
                                <div className="col-10">
                                    <button className="btn btn-link fw-bold d-block text-start ps-0 no-localization" onClick={() => doDownload(ccr)}><MdOutlineInsertDriveFile className="sm-react-icons pe-1" />{getDisplayName(ccr)}</button>
                                </div>
                                {forSearchPage && <CcrAndPlatEmail id={"email_" + index} ccr={ccr} getDownloadLink={getDownloadLink} logActivity={logActivity} />}
                            </div>
                        })}
                    {!forSearchPage && <><p>Not seeing what you were looking for?  Please contact our customer service department. We will quickly research your request to determine what is available.</p>
                        {activeRegion && <span>By Phone: {FormatPhoneNumber(activeRegion.stateAndCounties[0].phoneNumber)}<br />By Email: <a className="no-localization" href={"mailto:" + activeRegion.stateAndCounties[0].csEmailAddress}>{activeRegion.stateAndCounties[0].csEmailAddress}</a><br /></span>}
                    </>}
                </div>
            }
            {!ccrState.loading && (!ccrState.ccrs || ccrState.ccrs.length === 0) && <>
                <p>The CC&Rs for this property could not be located automatically. This is typically due to the property being section land, a lot split, tax lot, etc.</p>
                {!forSearchPage && <>

                    <p><button type="button" className="btn btn-primary" onClick={() => { showQuickProfileRequestTab(); EventTrack("PropertyOne", "Quick Profile Request Button", "CCR Tab"); } }>Request Property Profile</button> </p>
                </>}
                {forSearchPage && <>
                    <p>Fill out the form below to have Customer Service locate the documents and email them to you directly.</p>
                    <QuickProfileRequest pcUser={pcUser} activeRegion={activeRegion} subdivisionSearchData={subdivisionSearchData} />
                </>}
            </>}
        </>
    );
}

export default CCRs;
