import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';


const SellerSideCompensation = ({data, setCompensationTotal, className, saving, setSellersCompensation, loadedNetSheet}) => {
    const { register, setValue, getValues, watch, handleSubmit } = useForm();
    const [changedElement, setChangedElement] = useState(null);
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    /* watchers */
    const watchSellerSellingCompensationPercentage = watch("sellingSidePercentage");
    const watchSellerListingCompensationPercentage = watch("listingSidePercentage");
    const watchSellerSellingCompensationAmount = watch("sellingSideFee");
    const watchSellerListingCompensationAmount = watch("listingSideFee");
    const watchSellerSellingCompensationFlatFee = watch("sellingSideFlatFee");
    const watchSellerListingCompensationFlatFee = watch("listingSideFlatFee");
    const watchTotalCompensation= watch("totalCompensation");

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data) {
            setDefaults();
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.sellersCompensation)) {
                if (newArr.includes(key)) {
                    currency(value).value > 0 ? setValue(key, currency(value, { separator: ',', symbol: '' }).format()) : setValue(key, value);
                    switchChangedElement(key, true);
                }
            }
            setManuallyChangedInputs(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data) return;
            setDefaults();
        }
    }, [loadedNetSheet, data]);

    const setDefaults = () => {
        var startingCompensation = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(0.03).format();
        setValue('sellingSidePercentage', 3.00);
        setValue('sellingSideFee', startingCompensation);
        setValue('listingSidePercentage', 3.00);
        setValue('listingSideFee', startingCompensation);

        calculateSum(getValues("sellingSideFee"), getValues("sellingSideFlatFee"), getValues("listingSideFee"), getValues("listingSideFlatFee"));
    }

    const setManuallyChanged = (e) => {
        if (!manuallyChangedInputs.includes(e.target.id)) {
            setManuallyChangedInputs([...manuallyChangedInputs, e.target.id])           
        }
    }

    const calculateSum = (sellingPctAmount, sellingFlatFee, listingPctAmount, listingFlatFee ) => {
        const sum = currency(sellingPctAmount, { separator: ',', symbol: '' })
            .add(sellingFlatFee)
            .add(listingPctAmount)
            .add(listingFlatFee)
            .format();
        setValueCustom("totalCompensation", sum);
        setCompensationTotal(sum);
    }


    const switchChangedElement = (id, fromLoaded) => { 
        var sellerSellingCompensationPercentage = fromLoaded ? loadedNetSheet.sellersCompensation.sellingSidePercentage : watchSellerSellingCompensationPercentage;
        var sellerListingCompensationAmount = fromLoaded ? loadedNetSheet.sellersCompensation.listingSideFee : watchSellerListingCompensationAmount;
        var sellerListingCompensationFlatFee = fromLoaded ? loadedNetSheet.sellersCompensation.listingSideFlatFee : watchSellerListingCompensationFlatFee;
        var sellerSellingCompensationAmount = fromLoaded ? loadedNetSheet.sellersCompensation.sellingSideFee : watchSellerSellingCompensationAmount;
        var sellerSellingCompensationFlatFee = fromLoaded ? loadedNetSheet.sellersCompensation.sellingSideFlatFee : watchSellerSellingCompensationFlatFee;
        var sellerListingCompensationPercentage = fromLoaded ? loadedNetSheet.sellersCompensation.listingSidePercentage :watchSellerListingCompensationPercentage;
        var amount = 0;
        switch (id) {
            case 'sellingSidePercentage':
                amount = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(sellerSellingCompensationPercentage / 100).format();
                setValueCustom('sellingSideFee', amount);
                if (currency(sellerSellingCompensationPercentage).value !== 0) {
                    setValueCustom("sellingSideFlatFee", 0);
                    calculateSum(amount, 0, sellerListingCompensationAmount, sellerListingCompensationFlatFee);
                } else {
                    setValueCustom("sellingSideFee", 0);
                    calculateSum(amount, sellerSellingCompensationFlatFee, sellerListingCompensationAmount, sellerListingCompensationFlatFee);
                }
                break;
            case 'listingSidePercentage':
                amount = currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).multiply(sellerListingCompensationPercentage / 100).format();
                setValueCustom('listingSideFee', amount);
                if (currency(sellerListingCompensationPercentage).value !== 0) { 
                    setValueCustom("listingSideFlatFee", 0);
                    calculateSum(sellerSellingCompensationAmount, sellerSellingCompensationFlatFee, amount, 0);
                } else {
                    setValueCustom("listingSideFee", 0);
                    calculateSum(sellerSellingCompensationAmount, sellerSellingCompensationFlatFee, amount, sellerListingCompensationFlatFee);
                }
                break;
            case 'sellingSideFlatFee':
                if (currency(sellerSellingCompensationFlatFee).value !== 0) { 
                    setValueCustom("sellingSidePercentage", 0);
                    setValueCustom("sellingSideFee", 0);
                    calculateSum(0, sellerSellingCompensationFlatFee, sellerListingCompensationAmount, sellerListingCompensationFlatFee);
                } else {
                    calculateSum(sellerSellingCompensationAmount, 0, sellerListingCompensationAmount, sellerListingCompensationFlatFee);
                }
                break;            
            case 'listingSideFlatFee':
                if (currency(sellerListingCompensationFlatFee).value !== 0) { 
                    setValueCustom("listingSidePercentage", 0)
                    setValueCustom("listingSideFee", 0)
                    calculateSum(sellerSellingCompensationAmount, sellerSellingCompensationFlatFee, 0, sellerListingCompensationFlatFee);
                } else {
                    calculateSum(sellerSellingCompensationAmount, sellerSellingCompensationFlatFee, sellerListingCompensationAmount, 0);
                }                
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (!changedElement) return;
        switchChangedElement(changedElement.id, false);
    }, [watchSellerSellingCompensationPercentage, watchSellerListingCompensationPercentage, watchSellerSellingCompensationFlatFee ,watchSellerListingCompensationFlatFee]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setSellersCompensation({formVals, manuallyChangedInputs});
    };

    const setValueCustom = (el, val) => { 
        if (changedElement && changedElement.id === el) return;
        setValue(el, currency(val , { separator: ',', symbol: '' }).format());
    }

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideCompensation" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Compensation</div>
                    <div className="col text-end">Total: {currency(watchTotalCompensation, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onChange={e => setChangedElement(e.target)} onBlur={e => { formatInput(e); setManuallyChanged(e) }} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideCompensation">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className="col-12 fw-bold">Real Estate Fee (Selling Side):</div>
                            </div> 
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellingSidePercentage">Percentage:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <input name="sellingSidePercentage" type="number" defaultValue="3.00" id="sellingSidePercentage" className="form-control clear-zero" step="0.01" {...register("sellingSidePercentage")}/>
                                        <span className="input-group-text">%</span>
                                        <span className="input-group-text">$</span>
                                        <input name="sellingSideFee" type="text" defaultValue="0.00" readOnly="readonly" id="sellingSideFee" tabIndex="-1" className="form-control" {...register("sellingSideFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellingSideFlatFee">Flat Fee:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="sellingSideFlatFee" type="text" defaultValue="0.00" id="sellingSideFlatFee" className="form-control clear-zero currency"  {...register("sellingSideFlatFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 fw-bold">Real Estate Fee (Listing Side):</div>
                            </div> 
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="listingSidePercentage">Percentage:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <input name="listingSidePercentage" type="number" defaultValue="3.00" id="listingSidePercentage" className="form-control clear-zero" step="0.01" {...register("listingSidePercentage")}/>
                                        <span className="input-group-text">%</span>
                                        <span className="input-group-text">$</span>
                                        <input name="listingSideFee" type="text" defaultValue="0.00" readOnly="readonly" id="listingSideFee" tabIndex="-1" className="form-control"  {...register("listingSideFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="listingSideFlatFee">Flat Fee:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="listingSideFlatFee" type="text" defaultValue="0.00" id="listingSideFlatFee" className="form-control clear-zero currency"  {...register("listingSideFlatFee")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalCompensation">Total Compensation:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalCompensation" type="text" defaultValue="0.00"  readOnly="readonly" id="totalCompensation" tabIndex="-1" className="form-control" {...register("totalCompensation")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideCompensation;