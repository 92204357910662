import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import SpinnerLoader from '../../layout/spinner-loader';

const EmailNetSheet = ({ doDownload, downloading}) => {
    const { register,  handleSubmit, formState: { errors } } = useForm();
    const [netSheetSent, setNetSheetSent] = useState(false);
    const [sending, setSending] = useState(false);

    const onEmailSubmit = data => {
        setSending(true);
        doDownload(data);
    }  
    useEffect(() => {
        if (!sending) return;
        if (!downloading) { 
            setSending(false);
            setNetSheetSent(true);
        }
    }, [downloading]);

    return (<>
         <div className="modal" id="emailNetSheetModal" tabIndex="-1" aria-labelledby="emailNetSheetModalLabel" aria-hidden="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="emailNetSheetModalLabel">Email Net Sheet</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onEmailSubmit)}>
                            {netSheetSent && !sending && <p>Thank you, your net sheet has been sent.</p>}
                            {!netSheetSent && !sending && <div className="row">
                                <div className="col-12 mt-2">
                                    <label className="fw-bold required">To address(es):</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <input type="email" multiple className="form-control" id="emailNetSheetTo" {...register("to", { required: true })} />
                                    <small>Separate multiple addresses with a comma (,).</small>
                                    {errors?.to?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col-12 mt-2">
                                    <label className="fw-bold">Notes:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <textarea id="emailNetSheetNotes" rows="3" className="form-control" {...register("emailNetSheetNotes")} ></textarea>
                                </div>
                            </div>}
                            {sending && <SpinnerLoader />}
                            <div className="modal-footer">
                                {!netSheetSent && <button type="submit" className="btn btn-primary m-auto">Send</button>}
                                <span onClick={() => setNetSheetSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{netSheetSent ? "Close" : "Cancel"}</button></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>        
        </>
    );
}

export default EmailNetSheet;