import { useContext } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user-context";
import SpinnerLoader from "../components/layout/spinner-loader";
import { useEffect } from "react";

const RequireAuth = ({ children }) => {
    const { pcUser, activeRegion, loadingUser, errorLoadingPcProfile } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (pcUser && !activeRegion) {
            navigate('/preferred-customer/switch-brand');
        }
    }, [pcUser, activeRegion]);

    return (
        <>
            {loadingUser && <SpinnerLoader />}
        
            {!loadingUser && (!pcUser || errorLoadingPcProfile) && <Navigate to="/preferred-customer/account-required" state={{ from: location }} replace={true} />}
        
            {!loadingUser && pcUser && children}

        </>
        );
};

export default RequireAuth