import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import SpinnerLoader from '../layout/spinner-loader';

const EmailP1Package = ({emailFrom, setEmailSent, emailSent, doDownload}) => {
    const [emailSending, setEmailSending] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        setEmailSending(true);
        doDownload(data);
    }

    useEffect(() => {
        if (!emailSending) return;
        if (emailSent) setEmailSending(false);
    },[emailSent])
    return (
        <div className="modal" id="emailModal" tabIndex="-1" aria-labelledby="emailModalLabel" aria-hidden="true" role="dialog">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="emailModalLabel">Email Property Package</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {!emailSending &&
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {emailSent ? <p>Thank you, your email has been sent.</p> : <div className="row">
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold required">From:</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <input className="form-control" id="from" readOnly="readonly" value={emailFrom} />
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label className="fw-bold required" htmlFor="emailTo">To address(es):</label>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <input type="email" multiple className="form-control" id="emailTo" {...register("to", { required: true })} />
                                        <small>Separate multiple addresses with a comma (,).</small>
                                        {errors?.to?.type === "required" && <small className="text-danger">This field is required</small>}
                                </div>
                                <div className="col-12 mt-2">
                                    <label className="fw-bold" htmlFor="note">Additional comments:</label>
                                </div>
                                <div className="col-12 mt-2">
                                    <textarea id="comments" rows="3" className="form-control" {...register("comments")} ></textarea>
                                </div>
                                </div>}

                                <div className="modal-footer">
                                    {!emailSent && <button type="submit" className="btn btn-primary m-auto">Send Email</button>}
                                    <span onClick={() => setEmailSent(false)}><button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">{emailSent ? "Close" : "Cancel"}</button></span>
                                </div>
                            </form>
                        }
                        {emailSending && <SpinnerLoader /> }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailP1Package;