import React, { useState, useContext, Fragment } from 'react';
import { MdOutlineAdd, MdOutlineRemove, MdOutlineFileDownload} from 'react-icons/md'
import { Link } from 'react-router-dom';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { BrandContext } from '../../contexts/brand-context';
import CcrAndPlatEmail from '../real-estate-pros/ccr-and-plat-email';
import fileDownload from 'js-file-download';
import { EventTrack } from '../../services/utilities';

const BlaineCcrs = ({ ccrs, getDownloadLink, forSearchPage, logActivity, folderGroupOpen, setFolderGroupOpen }) => {
    const { selectedBrand } = useContext(BrandContext);
    const [doingDownload, setDoingDownload] = useState(false);
    const [downloadError, setDownloadError] = useState(null);

    const doDownload = async (ccr) => {
        setDoingDownload(true);
        apiTools.get(getDownloadLink(ccr), { responseType: 'blob' })
            .then((response) => {
                setDoingDownload(false);
                setDownloadError(null);
                try {
                    var fileName = (ccr.documentName ? ccr.documentName : ccr.fileName.replace(/\.[^/.]+$/, "")) + ".pdf";
                    fileDownload(response.data, `${selectedBrand.brandName.replace(/ /g, "-")}-${fileName}`);
                    logActivity("CC&R Lookup", `Viewed ${ccr.folderName} ${ccr.documentName}`);
                    EventTrack("CC&R and Plat Search", "Downloaded", ccr.display);
                }
                catch (ex) {
                    console.log(ex);
                }
            })
            .catch((err) => {
                setDownloadError("There was a problem retrieving the file you requested, please try again.");
                setDoingDownload(false);
                console.log("error downloading ccr", err);
            });
    }

    return (
        <>
            {!doingDownload && downloadError && <div className="alert alert-dark text-danger mb-0" role="alert">{downloadError}</div>}
            {doingDownload && <SpinnerLoader />}


            {!doingDownload && <div id="ccrList">
                {Object.entries(ccrs.reduce(function (r, a) {
                    r[a.folderName] = r[a.folderName] || [];
                    r[a.folderName].push(a);
                    return r;
                }, Object.create(null))).map((folder, index) => {
                    return <Fragment key={"folder" + index}><span className="fw-bold d-block" >{folderGroupOpen === index ? <MdOutlineRemove className="sm-react-icons pe-1" /> : <MdOutlineAdd className="sm-react-icons pe-1" />}<Link to="" onClick={() => setFolderGroupOpen(folderGroupOpen === index ? -1 : index)} data-bs-toggle="collapse" data-bs-target={"#folder" + index}>{folder[0]}</Link></span>
                        <div className="collapse" data-bs-parent="#ccrList" id={"folder" + index}>
                            <div className="row">
                                <div className="d-none d-sm-block col-2 fw-bold">Download</div>
                                <div className="col-5 offset-2 offset-sm-0 fw-bold">File Name</div>
                                <div className="col-2 fw-bold"><span className="d-none d-sm-inline">File </span>Type</div>
                            </div>
                            {folder[1].map((ccr, ccrIndex) => {
                                return <div className="row" key={"ccr" + ccrIndex}>
                                    <div className="col-2"><button className="btn btn-link pt-0 d-block text-start ps-0 ps-sm-2" onClick={() => doDownload(ccr)}><MdOutlineFileDownload className="sm-react-icons pe-1" /></button></div>
                                    <div className="col-5 no-localization">{ccr.documentName}</div>
                                    <div className="col-2 no-localization">{ccr.instrumentType}</div>
                                    {forSearchPage && <CcrAndPlatEmail ccr={ccr} getDownloadLink={getDownloadLink} id={"email_" + ccrIndex} folderName={folder[0]} logActivity={logActivity} />}
                                </div>

                            })}

                        </div>
                        <ul>

                        </ul>
                    </Fragment>
                })}
            </div>}
        </>
        );
    }

export default BlaineCcrs;