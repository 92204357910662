import React, { useContext, useState } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import { useEffect } from 'react';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import orderBy from 'lodash/orderBy';
import { Fragment } from 'react';
import { EventTrack, FormatPhoneNumber } from '../../services/utilities';

const HoaSearchResults = ({ loadingSearchResults, searchResults, searchCounty, expandResults, trackView }) => {
    const { selectedBrand } = useContext(BrandContext);
    const { activeRegion, logActivity } = useContext(UserContext);
    const [ countyInfo, setCountyInfo ] = useState(null);
    const [resultsWithDetails, setResultsWithDetails] = useState([]);

    useEffect(() => {
        if (selectedBrand && searchCounty && activeRegion) {
            setCountyInfo(Array.isArray(searchCounty) ? searchCounty[0] : searchCounty);
        }
    }, [selectedBrand, searchCounty, activeRegion]);

    useEffect(() => {
        setResultsWithDetails([]);
        if (searchResults && (searchResults.length === 1 || expandResults)) {
            getHOADetails(searchResults);
        }
    }, [searchResults, expandResults]);

    const getHOADetails = (resutsToGetDetailsFor) => {
        var hoaDetailPromises = [];
        resutsToGetDetailsFor.forEach((sr) => {
            const promise = apiTools.get(`/hoa/${sr.id}`);
            hoaDetailPromises.push(promise);
        });
        Promise.all(hoaDetailPromises)
            .then((responses) => {
                var details = [];
                responses.forEach((resp) => {
                    details.push(resp.data);
                    if (trackView) {
                        logActivity("Homeowners Assoc. Contact Lookup", `Viewed = ${resp.name}`);
                        EventTrack("HOA", "Viewed", resp.name);
                    }
                });
                setResultsWithDetails(details);
            });

    };

    return (
        <>
            {loadingSearchResults && <SpinnerLoader />}

            {!loadingSearchResults && searchResults && searchResults.length === 0 && countyInfo &&
                <div className="row pt-2">
                    <div className="col-8 col-lg-6 offset-4">
                        <p className="fw-bold">No results found.</p>
                        <p>
                            Please try your search again or contact a {selectedBrand.brandName} Customer Service Representative:<br />
                            By Phone: <span className="no-localization">{FormatPhoneNumber(countyInfo.phoneNumber)}</span><br />
                            By Email: <a className="no-localization" href={"mailto:" + countyInfo.csEmailAddress}>{countyInfo.csEmailAddress}</a>
                        </p>
                    </div>
                </div>
            }

            {!loadingSearchResults && searchResults && searchResults.length > 0 && resultsWithDetails.length === 0  &&
                orderBy(searchResults, ['name']).map((c, idx) =>
                    <Fragment key={idx}><button className="btn btn-link px-0 text-start no-localization" key={idx} onClick={() => getHOADetails([c])}>{c.name} {(Array.isArray(searchCounty)) && <span className="no-localization">({c.county})</span>}</button><br /></Fragment>)
            }
            {!loadingSearchResults && resultsWithDetails.length > 0 &&
                <>
                    {searchResults && !expandResults && searchResults.length > 1 && <div className="py-2"><button className="btn btn-link px-0 text-start" onClick={() => setResultsWithDetails([])}>Return to search results</button></div>}
                    {resultsWithDetails.map((singleHoa, idx) => {
                        return <div key={idx} className="pb-3 no-localization">
                            <div className="fw-bold">{singleHoa.name} - {singleHoa.county} County, {singleHoa.stateAbbr}</div>
                            <div>{singleHoa.contact}</div>
                            <div>{singleHoa.street1}</div>
                            <div>{singleHoa.street2}</div>
                            <div>{singleHoa.city && <>{singleHoa.city},</>} {singleHoa.state} {singleHoa.zip}</div>
                            <div>{(singleHoa.phone) && <>{singleHoa.phone} (phone)</>}</div>
                            <div>{(singleHoa.fax) && <>{singleHoa.fax} (fax)</>}</div>
                            <div>{(singleHoa.email) && <a href={"mailto:" + singleHoa.email}>{singleHoa.email}</a>}</div>
                        </div>;
                    })}
                </>
            }
        </>
    );
}

export default HoaSearchResults;