import React from 'react';
import { Link } from 'react-router-dom';
const TitleOneHomeTextBlock = () => {

    return (
        <p className="lh-base">
            Who is TitleOne? TitleOne, a title and escrow company, is a team of experts creating ways to help real estate professionals be successful. Our innovative and legendary service delivers confidence and peace of mind to real estate professionals and consumers in all areas we serve. Our array of <a href="/our-services">products and services</a> give you big company offerings with the benefit of local service. Tools like <Link to="/about-propertyone">PropertyOne</Link>, our custom <Link to="/net-sheet">net sheet calculators</Link>, and our additional <a href="/tools">web tools</a> are packed with resources and provide 24/7 access to property information, whenever and wherever you need it.
        </p>
    );
}

export default TitleOneHomeTextBlock;