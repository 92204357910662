import React, { useContext } from 'react';
import { MdOutlineFileUpload } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { FilePath } from '../../services/utilities';
import { UserContext } from '../../contexts/user-context';

const AddMyLogo = () => {
    const { pcUser } = useContext(UserContext);

    return (<>   
        <p className="mb-0">As a Preferred Customer you now have the option of customizing Property Profiles by adding your own logo. Simply <Link to="/preferred-customer/profile"><MdOutlineFileUpload className="sm-react-icons pe-1" />upload it on your profile</Link> and indicate when you'd like to use it on the next tab (Download/Email).</p>
        <div className="row pt-1">
            <p className="fw-bold">Current Logo:</p>
            <div className="col-12 col-lg-3">
                {pcUser.logo ? <img className="pt-2 w-100" src={FilePath('/preferredcustomerlogos/' + pcUser.logo)} alt="Preferred Customer Logo" /> : <span>No logo uploaded.</span> }
            </div>
        </div>
        <p className="row"><Link to="/preferred-customer/profile" className="text-center"><MdOutlineFileUpload className="sm-react-icons pe-1"/>To change or remove your logo, click here to update your profile.</Link></p>
    </>
    );     
}

export default AddMyLogo;