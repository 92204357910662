import React, { useContext, Fragment, useEffect, useState } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/user-context';
import { Link } from 'react-router-dom';
import LinkExternal from '../layout/link-external';
import ToolsLinks from './tools-link';
import './tools.scss';
import { FilePath } from '../../services/utilities';
import RegionSelector from '../preferred-customer/region-selector';
import { MdOutlineCalculate, MdOutlineHome, MdOutlineVideoLibrary, MdOutlineFeed, MdLaunch } from 'react-icons/md'
import apiTools from '../../services/api-tools';

const Tools = () => {
   
    const { selectedBrand } = useContext(BrandContext);
    const { activeRegion, logActivity, pcUser } = useContext(UserContext);
    const [toolCounties, setToolCounties] = useState([]);

    useEffect(() => {
        const GetToolCounties = async () => {
            apiTools.get(`/tool-counties/Tools Page/${activeRegion.regionId}`)
                .then((response) => {
                    setToolCounties(response.data);
                });
        }
        if (pcUser && activeRegion) {
            GetToolCounties();
            logActivity("TitleOne Tools", `Viewed = Main Tools Page`);
        }
    }, [pcUser, activeRegion]);

    const T1Tools = (props) => {
        return (
            <div className="row">
                {props.brandToolItems && props.brandToolItems.menuItems.map((menuItem, index) => {
                    return <Fragment key={index}>{createListItem(menuItem, index)}</Fragment>
                })}
            </div>
        );
    }

    const createListItem = (menuItem, index) => {
        if (menuItem.toolItems && menuItem.toolItems.length > 0) {
            var sectionFormat = "col-12 col-sm-6 mb-3";
            var numOfColumns = "sm-box-list";
            var toolIcon = (<MdOutlineVideoLibrary className="lg-react-icons" />);
            if (menuItem.title === "Property Information") {
                toolIcon = (<MdOutlineHome className="lg-react-icons" />);
            } else if (menuItem.title === "Calculators") {
                toolIcon = (<MdOutlineCalculate className="lg-react-icons" />);
            } else if (menuItem.title === "Reference Guides") {
                toolIcon = (<MdOutlineFeed className="lg-react-icons" />);
                sectionFormat = "col-12 mb-3";
                numOfColumns = "lg-box-list";
            } else {
                sectionFormat = "col-12 mb-3";
                numOfColumns = "lg-box-list";
            }

            return (
                <div className={sectionFormat} key={index}>
                    <div className="border p-3">
                        <h2>{toolIcon} {menuItem.title}</h2>
                    
                        <ul className={numOfColumns + " no-bullets"}>
                            {menuItem.toolItems.map((subMenuItem, subindex) => { 
                                if (subMenuItem.title === "OneMap") {
                                    return (<NewItem key={`${index}.${subindex}`} to={subMenuItem.route}>{subMenuItem.title}</NewItem>);
                                }
                                else if (subMenuItem.route) {
                                    return (<RouteItem key={`${index}.${subindex}`} to={subMenuItem.route}>{subMenuItem.title}</RouteItem>);
                                }
                                else if (subMenuItem.href && subMenuItem.href.toLowerCase().startsWith('http')) {
                                    return (<LinkItem key={`${index}.${subindex}`} href={subMenuItem.href} target={subMenuItem.target} rel="noreferrer">{subMenuItem.title}</LinkItem>);
                                }
                                else if (subMenuItem.href) {
                                    return (<LinkItem key={`${index}.${subindex}`} href={subMenuItem.href}>{subMenuItem.title}</LinkItem>);
                                }
                                else if (subMenuItem.pdf) {
                                    return (<LinkItem key={`${index}.${subindex}`} href={FilePath(subMenuItem.pdf)}>{subMenuItem.title}</LinkItem>);
                                }
                                else {
                                    return <></>;
                                }
                            })}
                        </ul>
                    </div>
                </div>
            );
        }
    }

    const RouteItem = props => <li><Link {...props} className="text-nowrap">{props.children}</Link></li>;
    const LinkItem = props => <li><LinkExternal className="text-nowrap" {...props}>{props.children}</LinkExternal></li>;
    const NewItem = props => <li><strong>NEW!</strong><Link {...props} className="text-nowrap"> {props.children}</Link></li>;

    return (
        <>
            <Helmet>
                <title>Tools for Real Estate Professionals : {selectedBrand.brandName}</title>
            </Helmet>
            <div>
                <h1>{activeRegion && <>{activeRegion.regionName}</>} Tools</h1>
                {activeRegion && toolCounties &&
                    <div className="pb-2">
                        <span className="pe-1">Serving <span className="text-capitalize">{toolCounties.map((c) => c.county.toLowerCase()).sort().join(", ")}</span> {(toolCounties.length > 1) ? "counties" : "county"}.</span>
                        <MdLaunch className="sm-react-icons" /> <RegionSelector linkText="Find more counties" toolDisplayName="Tools" toolName="Tools Page" toolRoute="tools" />
                    </div>
                }
                <p>Where would you like to go? Use these quick links to get there fast. </p>
                {activeRegion && <T1Tools brandToolItems={selectedBrand && ToolsLinks.find(bmi => bmi.regionId === activeRegion.regionId)} />}
            </div>
        </>
    );
}

export default Tools;