import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { BrandContext } from "../../contexts/brand-context";
import "./home-cards.scss"
import apiPublic from '../../services/api-public';
import LinkExternal from "../layout/link-external";
import { Link } from "react-router-dom";
import { EventTrack } from "../../services/utilities";

const HomePageCards = () => {
    const [cards, setCards] = useState([]);
    const { selectedBrand } = useContext(BrandContext);

    useEffect(() => {
        const getHomeCards = async () => {
            apiPublic.get(`/${selectedBrand.brandId}/home-cards`, { noErrorHandling: true })
                .then((response) => {
                    setCards(response.data);
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                });

        }
        if (selectedBrand) {
            getHomeCards();
        }
    }, [selectedBrand]);

    const getClassName =  (cards.length === 1) ? "col-12 mb-2" : (cards.length === 2) ? "col-lg-6 col-12 mb-2" : "col-lg-4 col-12 mb-2";

    return (
        <> 
            {cards && cards.length > 0 &&
                <>
                    <h2 className="text-center pt-0 pt-md-3">What's New</h2>
                    <div id="homePageCards" className="home-cards row">
                    {cards.map((card, index) => (
                        <div className={getClassName} key={index}><article className="px-2 px-lg-4 py-4 card border-0">
                            <h3>{card.title}</h3>
                            <p className="lh-base">{card.cardText}</p>
                            <div>
                                {card.link.toLowerCase().startsWith("http") ?
                                    <LinkExternal aria-label={card.linkText + ' - ' + card.cardText} href={card.link} className="stretched-link" onClick={() => EventTrack(card.title, "Home Card Clicked", `Position=${index + 1}`)}>{card.linkText}</LinkExternal>
                                    :
                                    <Link to={card.link} aria-label={card.linkText + ' - ' + card.title} className="stretched-link" onClick={() => EventTrack(card.title, "Home Card Clicked", `Position=${index + 1}`)}>{card.linkText}</Link>
                                }
                            </div>
                        </article></div>
                    ))}
                    </div>
                </>
            }
        </>
    );
}

export default HomePageCards;