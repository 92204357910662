import React, {  useContext } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import { useMsal } from "@azure/msal-react";
import { useLocation, Navigate, Link } from 'react-router-dom';
import { UserContext } from '../../contexts/user-context';
import { GetSelectedLanguage, GetSelectedLanguageSubdirectory } from '../../services/utilities';

const PreferredCustomerAccountRequired = (props) => {
    const { selectedBrand } = useContext(BrandContext);
    const { loadingUser, pcUser } = useContext(UserContext);
    const { instance } = useMsal();
    const location = useLocation();
    let requestedRoute = location.state?.from?.pathname || "/";

    const handleLogin = () => {
        var loginRequest = { state: location.pathname };
        if (location.pathname === "/preferred-customer/account-required") {
            loginRequest = {
                ...loginRequest,
                redirectUri: window.location.origin + GetSelectedLanguageSubdirectory() + "/preferred-customer/auth",
                state: requestedRoute,
                extraQueryParameters: {
                    lang: GetSelectedLanguage()
                }
            };

        }

        instance.loginRedirect(loginRequest)
            .catch(e => {
                console.error('Error After Login Redirect', e);
            });
    }

    return (
        <>
            {!loadingUser && pcUser && <Navigate to="/" state={{ from: location }} replace={true} />}

            <Helmet>
                <title>Account Required : {selectedBrand.brandName}</title>
            </Helmet>

            <h1>Preferred Customer Account Required</h1>
            <h2>Already have an account?</h2>
            <p>
                <button className="btn btn-primary" onClick={() => handleLogin()}>Log In</button>
            </p>

            <h2>Not a Preferred Customer? Sign up today!</h2>
            <p><Link to="/preferred-customer/sign-up" className="btn btn-primary" role="button">Sign up</Link></p>
            <p><strong>Why Become a Preferred Customer? It's free for Real Estate Agents and gives access to all of our web tools!</strong></p>
            <p>We specialize in offerings to serve real estate professionals including agents, lenders, builders, and developers.</p>
            <p>Access includes:</p>
            <ul>
                <li>Exclusive access to over 30 tools and resources</li>
                <li>Instant property information</li>
                <li>Accessible 24/7 to fit your schedule</li>
                <li>Free for Real Estate Agents to sign up</li>
            </ul>
            <h2>Looking for more?</h2>
            <ul>
                <li><Link to="/">Home page</Link></li>
                <li><Link to="/team">Meet the Team</Link></li>
                <li><Link to="/locations">Contact and Locations</Link></li>
                <li><Link to="/about-us">About Us</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/our-services">List of Services</Link></li>
            </ul>

        </>
    );
}

export default PreferredCustomerAccountRequired;