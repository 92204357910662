import currency from 'currency.js';

export const clearZero = (e) => {
    if (!e.target.className.includes('clear-zero')) return;
    if (currency(e.target.value).value === currency(0).value) { 
        e.target.value = '';
    }
}

export const formatInput = (e) => {
    if (e.target.value === '' && e.target.className.includes('clear-zero')) {
        e.target.value = e.target.className.includes('currency') ? '0.00' : '0';
    } else if (e.target.className.includes('currency')) { 
        e.target.value = currency(e.target.value , { separator: ',', symbol: '' }).format();
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}