import { Link } from 'react-router-dom';
import './footer.scss';
import { Fragment } from 'react';
import DevBrandMenu from './dev-brand-menu';
import { useContext } from 'react';
import { FilePath } from '../../../services/utilities';
import { BrandContext } from '../../../contexts/brand-context';
import FooterLocations from './footer-locations';

const Footer = () => {

    const { selectedBrand } = useContext(BrandContext);
    return (
        <Fragment>
            <div role="contentinfo" className="navbar bg-light mt-auto d-print-none">
                {process.env.REACT_APP_SHOW_BRAND_SWITCHER === 'true' && <DevBrandMenu />}
                <div className="footer container-fluid">
                    <div className="row w-100">
                        <div className="d-none d-lg-flex align-items-center col-lg-2">
                            {selectedBrand &&
                                <Link to="/" className="d-flex justify-content-center no-localization" aria-label={selectedBrand.brandName + " Logo"}>
                                    {selectedBrand.logo.includes(".svg") ? <object type="image/svg+xml" data={FilePath(selectedBrand.logo)} className="brand-image w-75" height="200px" aria-label={selectedBrand.brandName + " Logo"}>{selectedBrand.brandName + " Logo"}</object>
                                        : <img src={FilePath(selectedBrand.logo)} alt={selectedBrand.brandName + " Logo"} className="brand-image w-75" aria-label={selectedBrand.brandName + " Logo"} />}
                                </Link>
                            }
                        </div>
                        {selectedBrand && selectedBrand.brandId !== 4 &&
                            <>
                            <div className="col-4 col-sm-2 aboutLinks">
                                <div className="fw-bold border-bottom border-primary">ABOUT</div>
                                <Link to="/tools">Tools</Link>
                                <Link to="/careers">Careers</Link>
                                <Link to="/team">Team</Link>
                            </div>
                            <div className="col-8 col-sm-10 col-lg-8 locationLinks">
                                <div className="fw-bold border-bottom border-primary">LOCATIONS</div>
                                <FooterLocations/>
                            </div>
                            </>
                        }
                        <div className="col-12 pt-2">
                            <div className="d-flex justify-content-center small flex-wrap footer-legal">
                                <div className="d-inline">&copy;{new Date().getFullYear()} <span className="no-localization">{selectedBrand && selectedBrand.brandName}</span>. All Rights Reserved</div>
                                <Link to="/privacy-policy" className="px-3">Privacy Policy</Link>
                                <Link to="/terms-and-conditions" className="px-3">Terms & Conditions</Link>
                                {selectedBrand && selectedBrand.brandId !== 4 ? <Link to="/sitemap" className="px-3">Site Map</Link> : <Link to="/1031-exchange/regulatory-compliance" className="px-3">Regulatory Compliance</Link>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;