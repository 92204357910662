class SentryTileMapType {
	gmap;
	tileSize;
	state;
	county;
	layerNumbers;

	constructor(gmap, tileSize, state, county, layerNumbers) {
		this.gmap = gmap;
		this.tileSize = tileSize;
		this.state = state;
		this.county = county;
		this.layerNumbers = layerNumbers;
	}
	getTile(tileCoord, zoom, ownerDocument) {
		const div = ownerDocument.createElement("div");

		div.style.border = "0px none";
		div.style.margin = "0px";
		div.style.padding = "0px";
		div.style.overflow = "hidden";
		div.style.position = "absolute";
		div.style.top = "0px";
		div.style.left = "0px";
		div.style.width = "" + this.tileSize.width + "px";
		div.style.height = "" + this.tileSize.height + "px";

		var layersParam = "";
		if (this.layerNumbers && this.layerNumbers !== "")  layersParam = "&layers=show:" + this.layerNumbers.toString();

		var numOfTiles = 1 << zoom;
		var gworldsw = new this.gmap.Point(tileCoord.x * this.tileSize.width / numOfTiles, (tileCoord.y + 1) * this.tileSize.height / numOfTiles);
		var gworldne = new this.gmap.Point((tileCoord.x + 1) * this.tileSize.width / numOfTiles, tileCoord.y * this.tileSize.height / numOfTiles);
		var bnds = new this.gmap.LatLngBounds(fromPointToLatLng(gworldsw, this.gmap), fromPointToLatLng(gworldne, this.gmap));
		var swx = bnds.getSouthWest().lng();
		var nex = bnds.getNorthEast().lng();
		if (swx > nex) { swx = swx - 180; }
		var bbox = "" + swx + "," + bnds.getSouthWest().lat() + "," + nex + "," + "" + bnds.getNorthEast().lat();
		var reqPath = this.state + "/geo_" + this.state.toLowerCase() + "_" + this.county.toLowerCase() + "/MapServer/export?f=image&bbox="
			+ bbox + "&size=" + this.tileSize.width.toString() + "," + this.tileSize.height.toString() + "&imageSR=102113&bboxSR=4326&format=png32" + layersParam + "&transparent=true";
		var url = process.env.REACT_APP_API_TOOLS_BASE_URL + "map-image/?requestPath=" + encodeURIComponent(reqPath);
		div.style.backgroundImage = "url(" + url + ")";
		return div;
	}
	releaseTile(tile) { }
}

function fromPointToLatLng(pixel, gmap) {
	if (pixel === null) {
		return null;
	}
	var x = pixel.x * 156543.033928 + -20037508.342787;
	var y = 20037508.342787 - pixel.y * 156543.033928;
	var geo = inverseGeoCoordinates([x, y]);
	return new gmap.LatLng(geo[1], geo[0]);
}

const inverseGeoCoordinates = (coords) => {
	var RAD_DEG = 0.017453292519943295;
	var a = 6378137;
	var E = coords[0];
	var N = coords[1];
	var phi = Math.PI / 2 - 2 * Math.atan(Math.exp(-N / a));
	var lamda = E / a;
	return [lamda / RAD_DEG, phi / RAD_DEG];
};

const fromMapOverlaysToJSON = (g, gmap) => {
	var json = "{";
	if (g instanceof gmap.LatLngBounds) {
		json += "xmin:" + g.getSouthWest().lng() + ",ymin:" + g.getSouthWest().lat() + ",xmax:" + g.getNorthEast().lng() + ",ymax:" + g.getNorthEast().lat();
	} else {
		json += "x:" + g.lng() + ",y:" + g.lat();
	}
	return json + ", spatialReference:{wkid:4326}}";
};

const sortFeaturesByNameAndArea  = (gglmaps, features, propertyName) => {
	var sortedArray = features.sort(function (a, b) {
		var polyAarea = 0;
		var polyBarea = 0;
		var geometryA = a.getGeometry();
		var geometryB = b.getGeometry();
		if ((geometryA.getType() === "Polygon" && geometryB.getType() === "Polygon")) {
			var polya = new gglmaps.Polygon({ paths: geometryA.getAt(0).getArray() })
			var polyb = new gglmaps.Polygon({ paths: geometryB.getAt(0).getArray() })
			polyAarea = gglmaps.geometry.spherical.computeArea(polya.getPath());
			polyBarea = gglmaps.geometry.spherical.computeArea(polyb.getPath());
		}
		return a.getProperty(propertyName).localeCompare(b.getProperty(propertyName)) || (polyBarea - polyAarea);
	});
	return sortedArray;
};

const addCentroidPrototype = (ggl) => {
	ggl.maps.Polygon.prototype.getApproximateCenter = function () {
		var boundsHeight = 0,
			boundsWidth = 0,
			centerPoint,
			heightIncr = 0,
			maxSearchLoops,
			maxSearchSteps = 10,
			n = 1,
			northWest,
			polygonBounds = this.getBoundingBox(),
			testPos,
			widthIncr = 0;

		// Get polygon Centroid
		centerPoint = polygonBounds.getCenter();


		if (window.google.maps.geometry.poly.containsLocation(centerPoint, this)) {
			// Nothing to do Centroid is in polygon use it as is
			return centerPoint;
		} else {
			maxSearchLoops = maxSearchSteps / 2;


			// Calculate NorthWest point so we can work out height of polygon NW->SE
			northWest = new window.google.maps.LatLng(
				polygonBounds.getNorthEast().lat(),
				polygonBounds.getSouthWest().lng()
			);


			// Work out how tall and wide the bounds are and what our search
			// increment will be
			boundsHeight = window.google.maps.geometry.spherical.computeDistanceBetween(
				northWest,
				polygonBounds.getSouthWest()
			);
			heightIncr = boundsHeight / maxSearchSteps;


			boundsWidth = window.google.maps.geometry.spherical.computeDistanceBetween(
				northWest, polygonBounds.getNorthEast()
			);
			widthIncr = boundsWidth / maxSearchSteps;


			// Expand out from Centroid and find a point within polygon at 
			// 0, 90, 180, 270 degrees
			for (; n <= maxSearchSteps; n++) {
				// Test point North of Centroid
				testPos = window.google.maps.geometry.spherical.computeOffset(
					centerPoint,
					(heightIncr * n),
					0
				);
				if (window.google.maps.geometry.poly.containsLocation(testPos, this)) {
					break;
				}


				// Test point East of Centroid
				testPos = window.google.maps.geometry.spherical.computeOffset(
					centerPoint,
					(widthIncr * n),
					90
				);
				if (window.google.maps.geometry.poly.containsLocation(testPos, this)) {
					break;
				}


				// Test point South of Centroid
				testPos = window.google.maps.geometry.spherical.computeOffset(
					centerPoint,
					(heightIncr * n),
					180
				);
				if (window.google.maps.geometry.poly.containsLocation(testPos, this)) {
					break;
				}


				// Test point West of Centroid
				testPos = window.google.maps.geometry.spherical.computeOffset(
					centerPoint,
					(widthIncr * n),
					270
				);
				if (window.google.maps.geometry.poly.containsLocation(testPos, this)) {
					break;
				}
			}

			return (testPos);
		}
	};
};

export { SentryTileMapType, fromMapOverlaysToJSON, addCentroidPrototype, sortFeaturesByNameAndArea  }