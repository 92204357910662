import React from "react";
import { useContext } from "react";
import { BrandContext } from "../../contexts/brand-context";
import TitleOneHomeTextBlock from './brandspecific/titleone-home-text-block';
import SunValleyTitleHomeTextBlock from './brandspecific/sun-valley-title-home-text-block';
import "./home-text-block.scss"

const HomeTextBlockComponents = {
    titleone: TitleOneHomeTextBlock,
    sunvalleytitle: SunValleyTitleHomeTextBlock,
};


const HomeTextBlock = () => {

    const { selectedBrand } = useContext(BrandContext);

    const BrandSpecificHomeTextBlock = function () {
        // component does exist
        if (typeof HomeTextBlockComponents[selectedBrand.brandName.toLowerCase().replace(/ /g, "")] !== "undefined") {
            return React.createElement(HomeTextBlockComponents[selectedBrand.brandName.toLowerCase().replace(/ /g, "")], { key: selectedBrand.brandId });
        }
        // component doesn't exist yet
        return <></>;
    }

    if (!selectedBrand) {
        return <></>;
    }

    return (
        <div className="home-text-block px-2 px-lg-4">
            <BrandSpecificHomeTextBlock />
        </div>
    );
}

export default HomeTextBlock;