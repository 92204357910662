import React, { useEffect, useState } from 'react';
import HoaSearchResults from '../real-estate-pros/hoa-search-results';
import apiTools from '../../services/api-tools';
import CCRs from './ccrs';

const CCRsPlatsHoa = ({ pcUser, activeRegion, selectedPropertyP1Data, logActivity, ccrState, setCcrState, folderGroupOpen, setFolderGroupOpen, showQuickProfileRequestTab }) => {
    const [hoaSearchResults, setHoaSearchResults] = useState(null);
    const [loadingHoaSearchResults, setLoadingHoaSearchResults] = useState(false);

    const cleanSub = (subName) => {
        return subName.replace(/(\s+((ph(\s)*)*[0-9](.)*))+/i, '')
                                    .replace(/(\s+\(amd\))/i, '')
                                    .replace(/(\s+\(the\))/i, '');
    };
    useEffect(() => {
        const getSearchResults = async (searchCriteria) => {
            apiTools.post(`/hoa/search`, searchCriteria)
                .then((response) => {
                    setHoaSearchResults(response.data);
                    setLoadingHoaSearchResults(false);
                });
        }
        if (selectedPropertyP1Data.subdivision) {
            getSearchResults({
                subdivision: cleanSub(selectedPropertyP1Data.subdivision),
                regionCounties: [{ stateAbbr: selectedPropertyP1Data.state, county: selectedPropertyP1Data.county }]
            });
            
        }
    }, [selectedPropertyP1Data]);

    return (<>
        <div>
            <CCRs pcUser={pcUser} activeRegion={activeRegion} forSearchPage={false} selectedPropertyP1Data={selectedPropertyP1Data} logActivity={logActivity} ccrState={ccrState} setCcrState={setCcrState} folderGroupOpen={folderGroupOpen} setFolderGroupOpen={setFolderGroupOpen} showQuickProfileRequestTab={showQuickProfileRequestTab} />
        </div>
        {!loadingHoaSearchResults && hoaSearchResults && hoaSearchResults.length !== 0 && <div className="border-top pt-3 mt-3">
            <h3>Homeowners Association Information</h3>
            <HoaSearchResults loadingSearchResults={loadingHoaSearchResults} searchResults={hoaSearchResults} searchCounty={selectedPropertyP1Data.county} expandResults={true} trackView={false} />
        </div>}
    </>);     
}

export default CCRsPlatsHoa;