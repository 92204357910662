import React, { useContext, useState, useEffect, useRef } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import apiTools from '../../services/api-tools';
import { UserContext } from '../../contexts/user-context';
import AsyncSelect from 'react-select/async';
import CCRs from '../propertyone/ccrs';
import CountySelector from '../layout/county-selector';


const CcrAndPlatSearch = () => {
    const { selectedBrand } = useContext(BrandContext);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const { loadingUser, pcUser, activeRegion, logActivity } = useContext(UserContext);
    const [subdivisionSearchData, setSubdivisionSearchData] = useState({ state: null, county: null, subdivision: null });
    const [ccrState, setCcrState] = useState({ ccrs: [], loading: false });
    const [folderGroupOpen, setFolderGroupOpen] = useState(-1);

    const selectInputRef = useRef();

    useEffect(() => {
        if (!activeRegion) return;
        selectInputRef.current?.clearValue();
        if (!loadingUser && pcUser && activeRegion) {
            setSubdivisionSearchData({ state: null, county: null, subdivision: null });
            setCcrState({ ccrs: [], loading: true });
        }
    }, [loadingUser, pcUser, activeRegion]);

    const loadOptions = (inputValue, callback) => {
        if (inputValue.length < 3) return; 
        const getCCRSubdivisions = async () => {
            var trimmedSubdivision = inputValue.trim();
            var encodedSubdivision = encodeURIComponent(trimmedSubdivision);
            apiTools.get(`/ccrs?state=${selectedCounty.state}&county=${selectedCounty.countyValue}&subdivision=${encodedSubdivision}&namesOnly=true`)
                .then((response) => {    
                    callback([...new Set(response.data)].map(m => {return {value: m, label: m}}));
                }).catch((e) => { 
                    callback(null)
                });
        }
        getCCRSubdivisions();
    }

    const setSelectedSubdivision = (sub) => {
        setCcrState({ ccrs: [], loading: true });
        setSubdivisionSearchData({ state: selectedCounty.state, county: selectedCounty.countyValue, subdivision: sub });
    };

    return (<><Helmet>
        <title>CC&R and Plat Search : {selectedBrand.brandName}</title>
        </Helmet>
        <h1>CC&R and Plat Search</h1>
        {activeRegion && <p>Please note, we strive to have the most complete database of CC&Rs and Plat Maps available.
            If you find a subdivision that does not include a CC&R or Plat Map, please contact a Customer Service
            Representative at {activeRegion.stateAndCounties[0].phoneNumber} or <a href={"mailto:" + activeRegion.stateAndCounties[0].csEmailAddress}>via email</a>. They will quickly research your request to determine what is available
            in your area.</p>}

        <div className="row pt-1">
            <div className="col-3 col-sm-4 p-0 p-sm-1 form-label fw-bold">
                <label htmlFor="county">County:</label>
            </div>
            <div className="col-9 col-sm-8">
                <CountySelector regionId={activeRegion.regionId} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} toolName="CCR Search" toolRoute="ccr-and-plat-search" />
            </div>
        </div>
        <div className="row pt-1 pb-2">
            <div className="col-3 col-sm-4 p-0 p-sm-1 form-label fw-bold">
                <label htmlFor="subdivision">Subdivision:</label>
            </div>
            <div className="col-9 col-sm-8 no-localization">
                <AsyncSelect
                    loadOptions={loadOptions}
                    onChange={(e) => { e && setSelectedSubdivision(e.value) }}
                    ref={selectInputRef}
                    placeholder="Start typing to search"
                    onClick={() => { selectInputRef.current && selectInputRef.current.clearValue() }}
                    isClearable={true}
                />
            </div>
        </div>
        {subdivisionSearchData.subdivision && <><span className="h3">Results for <span className="fw-bold no-localization">{subdivisionSearchData.subdivision}</span>:</span><CCRs pcUser={pcUser} activeRegion={activeRegion} forSearchPage={true} subdivisionSearchData={subdivisionSearchData} ccrState={ccrState} setCcrState={setCcrState} logActivity={logActivity}  folderGroupOpen={folderGroupOpen} setFolderGroupOpen={setFolderGroupOpen} /></>}
        <p>NOTICE: Any property information subject to the following: This report is based on a search of our tract indexes of the county records. 
            This is not a title or ownership report and no examination of the title to the property described has been made. For this reason, no liability beyond 
            the amount paid for this report is assumed hereunder, and the company is not responsible beyond the amount paid for any errors and omissions contained herein.</p>
        </>
    );
}

export default CcrAndPlatSearch;