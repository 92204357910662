import React from 'react';
import { Link } from 'react-router-dom';
import { FilePath } from '../../../services/utilities';
const TitleOneExchangeHome = () => {

    return (
        <div>
            <h1>TitleOne Exchange</h1>
            <h2 className="titleone-exchange-h2">Interested in deferring taxes with a 1031 exchange?</h2>
            <img src={FilePath("/images/titleone-exchange/Home.jpg")} alt="houses and tax sign figurines" className="float-end ps-5 pb-5 w-50" />
            <p>For over 20 years, TitleOne Exchange has served as a qualified intermediary during the 1031 exchange process, helping investors to defer capital gains tax on the sale of their business or investment property.</p>

            <p>If you are planning to sell real estate investment property and would like to defer potential capital gains and tax, you should <Link to="/1031-exchange/basics">consider the benefits of participating in a tax-deferred 1031 exchange</Link>. View our <Link to="/1031-exchange/basics">FAQs</Link> to learn more about the 1031 exchange process to review the most common questions about exchanges.</p>

            <p>There are many benefits to using TitleOne Exchange:</p>

            <ul>
                <li>Ability to execute exchanges nation-wide</li>
                <li>With our <Link to="/1031-exchange/tools">Proceeds Protection Protocol</Link>, we treat your money like it's our own</li>
                <li>Fidelity coverage maintained in the amount of $5,000,000</li>
                <li>Errors & Omissions coverage in the amount of $5,000,000</li>
                <li><Link to="/1031-exchange/tools">Tools and tips for Exchangors and Brokers</Link></li>
                <li>Most valuable is our expertise. Our <Link to="/1031-exchange/contact-us">dedicated staff</Link> has extensive knowledge on a wide variety of topics</li>
                <li>Have questions? TitleOne Exchange offers complimentary consultations &mdash; <Link to="/1031-exchange/contact-us">Contact us today</Link>. We look forward to discussing strategies on deferring long term capital gains and more.</li>
            </ul>

        </div>
    );
}

export default TitleOneExchangeHome;