import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../contexts/brand-context';
import { Link, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { UserContext } from '../../contexts/user-context';
import { MdOutlineDelete, MdOutlineCompare } from 'react-icons/md';
import currency from 'currency.js';
import NetSheetComparison from './net-sheet-comparison';
import { EventTrack } from '../../services/utilities';
import RegionSelector from '../preferred-customer/region-selector';

const SavedNetSheets = () => {
    let { paramRegularOrReverse } = useParams();
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser, activeRegion, logActivity } = useContext(UserContext);

    const [netSheets, setNetSheets] = useState(null);
    const [regularOrReverse, setRegularOrReverse] = useState("regular");
    const [selectedNetSheets, setSelectedNetSheets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletedNetSheet, setDeletedNetSheet] = useState(false);
    const [comparing, setComparing] = useState(false);
    const [compareError, setCompareError] = useState(null);

    useEffect(() => {
        if (!paramRegularOrReverse) return;
        setRegularOrReverse(paramRegularOrReverse === 'reverse' ? 'reverse' : 'regular');
    }, [paramRegularOrReverse]);

    useEffect(() => {
        const getNetSheets = async () => {
            const response = await apiTools.get(`/net-sheets/${activeRegion.regionId}`);
            setNetSheets(response.data);
            setLoading(false);
        }
        if (pcUser && activeRegion) getNetSheets();

    }, [pcUser, activeRegion]);

    useEffect(() => {
        const getNetSheets = async () => {
            setLoading(true);
            const response = await apiTools.get(`/net-sheets/${activeRegion.regionId}`);
            setNetSheets(response.data.sort((a, b) => a.netSheetId < b.netSheetId ? -1 : 1));
            setLoading(false);
            setDeletedNetSheet(false);
        }
        if (deletedNetSheet ) getNetSheets();

    }, [deletedNetSheet]);

    useEffect(() => {
        setSelectedNetSheets([]);
    }, [regularOrReverse]);

    useEffect(() => {
        if (!comparing) setSelectedNetSheets([]);
    }, [comparing]);

    const deleteNetSheet = (netSheetId) => { 
        const del = async () => {
            await apiTools.post(`/net-sheet/delete/${netSheetId}`).then((response) => setDeletedNetSheet(true));
        }
        if (netSheetId) del();
        EventTrack("Net Sheet List", "Delete Saved", "Button");

    }

    const addRemoveNetSheet = (netSheet) => { 
        var netSheetArray = [...selectedNetSheets];
        if (netSheetArray.includes(netSheet)) {
            netSheetArray = netSheetArray.filter((ns) => ns !== netSheet);
        } else { 
            netSheetArray.push(netSheet);
        }
        setSelectedNetSheets(netSheetArray);
    }

    
    const doCompare = () => {
        if (selectedNetSheets.length < 2) {
            setCompareError("You must select at least two net sheets to compare.")
        } else if (selectedNetSheets.length > 5) {
            setCompareError("You may only compare up to 5 net sheets at a time.")
        } else {
            setCompareError(null);
            setComparing(true);
            if (regularOrReverse === 'reverse') {
                logActivity("Reverse Netsheets Compared", ``);
                EventTrack("Net Sheet List", "Reverse Net Sheet Compared");
            } else {
                logActivity("Netsheets Compared", ``);
                EventTrack("Net Sheet List", "Net Sheet Compared");
            } 
        }
    }

    return (
        <>
            <Helmet>
                <title>Saved Net Sheets: {selectedBrand.brandName}</title>
            </Helmet>
            {!comparing && <><h1>Saved Net Sheets</h1>
                {regularOrReverse === 'regular' ? <Link to="/net-sheet" onClick={() => { EventTrack("Net Sheet List", "Net Sheet Started New"); }}>Start New Regular Net Sheet</Link> : <Link to="/reverse-net-sheet" onClick={() => { EventTrack("Net Sheet List", "Reverse Net Sheet Started New"); }}>Start New Reverse Net Sheet</Link>}
            <div>
                Below is a list of your {activeRegion && <>{activeRegion.regionName}</>} saved net sheets. <RegionSelector linkText="Find more counties" toolName="NetSheet" toolDisplayName="Saved Net Sheets" toolRoute="net-sheet-comparison" /> <br />
                Saved net sheets will be stored for one year.
            </div>
            <div className="row">
                <div className="col">Net Sheet Type:</div>
                <div className="col">
                    <span className="form-control">
                        <label  className="me-2"><input type="radio" className="me-2" checked={regularOrReverse === 'regular'} onChange={() => setRegularOrReverse("regular")} />Regular </label>
                        <label><input type="radio" className="me-2" checked={regularOrReverse === 'reverse'} onChange={() => setRegularOrReverse("reverse")} />Reverse</label>
                    </span>
                </div>
            </div>
            <div className="row pt-2">
                <div className="col"></div>
                    <div className="col">
                        <Link to="" className="btn btn-primary" onClick={() => doCompare()}><MdOutlineCompare  /> Compare</Link> 
                </div>
            </div>
            {compareError && <p className="text-danger">{compareError}</p>}

            {loading && <SpinnerLoader/>}
            {!loading && <div className="table-responsive"><table className="table ">
                <thead>
                    <tr>
                        <th scope="col">Last Saved</th>
                        <th scope="col">Name</th>
                        <th scope="col">Closing Date</th>
                        <th scope="col">Purchase Price</th>
                        <th scope="col">Select</th>
                        <th scope="col">Delete</th>
                    </tr>
                </thead>
                <tbody className="no-localization">
                    {netSheets && netSheets.filter((n) => regularOrReverse === 'reverse' ? n.reverse : !n.reverse ).length > 0 ? netSheets.filter((n) => regularOrReverse === 'reverse' ? n.reverse : !n.reverse ).map((netSheet, index) => {
                        return <tr key={"netSheet" + index}>
                            <td>{DateTime.fromISO(netSheet.lastSavedDate).toLocaleString()}</td>
                            <td><Link to={(regularOrReverse === 'reverse' ? '/reverse-' : '/') + "net-sheet/" + netSheet.netSheetId}>{netSheet.netSheetName}</Link></td>
                            <td>{DateTime.fromISO(netSheet.closingDate).toLocaleString()}</td>
                            <td>{currency(netSheet.purchasePrice, { separator: ',', symbol: '$' }).format()}</td>
                            <td><input type="checkbox" onChange={() => addRemoveNetSheet(netSheet)} /></td>
                            <td><span onClick={() => deleteNetSheet(netSheet.netSheetId)}><Link to="" ><MdOutlineDelete /></Link></span></td>
                        </tr>
                    }) : <tr><td colSpan="6">You don't have any saved <strong>{regularOrReverse}</strong> net sheets.</td></tr>}
                </tbody>
            </table></div>}
            <div className="row">
                <div className="col"></div>
                <div className="col">
                    <Link to="" className="btn btn-primary" onClick={() => doCompare()}><MdOutlineCompare /> Compare</Link> 
                </div>
                </div></>}
            {comparing && <NetSheetComparison netSheets={selectedNetSheets} setComparing={setComparing} reverse={regularOrReverse} />}
        </>
    );
}

export default SavedNetSheets;