import React, { useState, useEffect } from 'react';
import apiTools from '../../services/api-tools';
import { MdPersonOutline, MdOutlineList, MdOutlineHome, MdOutlineAttachMoney, MdOutlinedFlag, MdOutlineCottage, MdOutlineCalculate, MdDirectionsWalk, MdDirectionsBike, MdDirectionsBus } from 'react-icons/md'
import { FormatMoney } from "../../services/utilities";
import LinkExternal from '../layout/link-external';
import SpinnerLoader from '../layout/spinner-loader';
import axios from 'axios';

const ParcelInfo = ({ state, county, parcelId, setSelectedPropertyP1Data, selectedPropertyP1Data, setParcelHtml }) => {
    const [propertyInfo, setPropertyInfo] = useState([]);
    const [propertyImage, setPropertyImage] = useState(null);
    const [loadingPropertyInfo, setLoadingPropertyInfo] = useState(false);
    const [loadingPropertyImage, setLoadingPropertyImage] = useState(false);
    const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

    useEffect(() => {
        setLoadingPropertyInfo(true);
        setLoadingPropertyImage(true);
        var wsData = "";
        const getPropertyInfo = async () => {
            apiTools.get(`/property-profile?state=${state}&county=${county}&parcelId=${parcelId}`)
                .then((propResponse) => {
                    setPropertyInfo(propResponse.data);
                  
                    setLoadingPropertyInfo(false);
                    apiTools.get(`/property-image?state=${state}&county=${county}&parcelId=${parcelId}`)
                        .then((response) => {
                            if (response.data && response.data.url) {
                                setPropertyImage(response.data.url);
                            } else {
                                setPropertyImage("https://maps.googleapis.com/maps/api/streetview?size=400x300&location=" + propResponse.data.latitude + "," + propResponse.data.longitude + "&key=" + googleMapsAPIKey);
                            }
                            setLoadingPropertyImage(false);
                        });

                    if (!propResponse.data.latitude || !propResponse.data.longitude) {
                        axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + propResponse.data.siteAddress + "," + propResponse.data.siteCity + "," + state + + ' ' + propResponse.data.siteZip + "&key=" + googleMapsAPIKey)
                            .then((response) => {
                                if (response.data.results.length === 0) {
                                    return;
                                } else {
                                    apiTools.get("/property-walk-score?address=" + encodeURIComponent(propResponse.data.siteAddress + ' ' + propResponse.data.siteCity + ' ' + state + ' ' + propResponse.data.siteZip) + "&lat=" + response.data.results[0].geometry.location.lat + "&lon=" + response.data.results[0].geometry.location.lng)
                                        .then((response) => {
                                            wsData = response.data;
                                            AddPropData(wsData, propResponse.data);
                                        });
                                }
                            });
                    } else {
                        apiTools.get("/property-walk-score?address=" + encodeURIComponent(propResponse.data.siteAddress + ' ' + propResponse.data.siteCity + ' ' + state + ' ' + propResponse.data.siteZip) + "&lat=" + propResponse.data.latitude + "&lon=" + propResponse.data.longitude)
                            .then((response) => {
                                wsData = response.data;
                                AddPropData(wsData, propResponse.data);
                               
                            });
                    }
                });
        }
        getPropertyInfo();
    }, [parcelId, county, state, googleMapsAPIKey, setSelectedPropertyP1Data]);

   
    function AddPropData(wsData, propData) {
        if (wsData.bike) { var wsBikeScore = wsData.bike.score; }
        if (wsData.transit) { var wsTransitScore = wsData.transit.score; }
        setSelectedPropertyP1Data(
            {
                state: state,
                county: county,
                owner: propData.ownerName,
                parcelId: parcelId,
                subdivision: propData.platName,
                legalDescription: propData.legalDescription,
                propertyType: propData.recordTypeDescription,
                address: propData.siteAddress,
                latitude: propData.latitude,
                longitude: propData.longitude,
                wsWalkScore: wsData.walkscore,
                wsBikeScore: wsBikeScore,
                wsTransitScore: wsTransitScore,
                siteHouseNumber: propData.siteHouseNumber,
                siteStreetName: ((propData.siteStreetName) ? propData.siteStreetName + ((propData.siteStreetType) ? ' ' + propData.siteStreetType : '') : null),
                siteDirectionSuffix: propData.siteDirectionSuffix,
                siteUnit: propData.siteUnit,
                siteCity: propData.siteCity,
                siteState: propData.siteState,
                siteZip: propData.siteZip
            });
    }

    useEffect(() => {
        setParcelHtml(document.getElementsByTagName("html")[0].outerHTML);
    }, [propertyInfo, propertyImage, selectedPropertyP1Data])

    return (
        <>
            {loadingPropertyInfo ? <SpinnerLoader /> :
                <div>
                    <h3>{county} County Parcel Information</h3>
                    <div className="row">
                        <div className="col-sm-6 d-block d-md-none">
                            <img src={propertyImage} alt={propertyInfo.siteAddress} className="img-fluid mx-auto d-block" />
                        </div>
                        <div className="col-sm-6">
                            {/* Property Information*/}
                            <h4 className="pt-3"><MdOutlineHome className="lg-react-icons" /> Property Information</h4>
                            <div className="row">
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Parcel ID #:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.parcelId}</div>
                                {propertyInfo.taxAccountNumber && <>
                                    <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Tax / Account #:</div>
                                    <div className="col-12 col-md-8 no-localization">{propertyInfo.taxAccountNumber}</div>
                                </>}
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Property Address:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.siteAddress}{propertyInfo.siteCity !== null ? ' ' + propertyInfo.siteCity : ''}, {propertyInfo.siteState} {propertyInfo.siteZip}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Property Type:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.recordTypeDescription}</div>
                            </div>
                            {/* Owner Information*/}
                            <h4 className="pt-3"><MdPersonOutline className="lg-react-icons" /> Owner Information</h4>
                            <div className="row">
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Owner Name:</div>
                                <div className="col-12 col-md-8 no-localization">{(propertyInfo.ownerName) ? propertyInfo.ownerName : (propertyInfo.ownerFirstName && propertyInfo.ownerLastName) ? propertyInfo.ownerFirstName + " " + propertyInfo.ownerLastName : ""}</div>
                                {(propertyInfo.ownerName2 || (propertyInfo.owner2FirstName && propertyInfo.owner2LastName)) && <>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Second Owner:</div>
                                    <div className="col-12 col-md-8 no-localization">{(propertyInfo.ownerName2) ? propertyInfo.ownerName2 : (propertyInfo.owner2FirstName && propertyInfo.owner2LastName) ? propertyInfo.owner2FirstName + " " + propertyInfo.owner2LastName : ""}</div>
                            </>}
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Mailing Address:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.ownerAddress} {propertyInfo.ownerCity}, {propertyInfo.ownerState} {propertyInfo.ownerZip}</div>
                            </div>
                        </div>
                        <div className="col-sm-6 d-none d-md-block">
                            {loadingPropertyImage ? <SpinnerLoader /> : <img src={propertyImage} alt={propertyInfo.siteAddress} className="img-fluid mx-auto d-block no-localization" />}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            {/* Assessor Information*/}
                            <h4 className="pt-3"><MdOutlineList className="lg-react-icons" /> Assessor Information</h4>
                            <div className="row">
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Abbreviated Legal:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.legalDescription}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Plat / Subdivision:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.platName}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Lot/Block:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.legalLot}/{propertyInfo.legalBlock}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Twn/Rng/Sec:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.town}/{propertyInfo.rng}/{propertyInfo.sec}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Acres:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.acres}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Irrigation District:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.irrigationDistrictName}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Zoning:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.zoneCode}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">Zoning Description:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.zoneDescription}</div>
                                <div className="col-12 col-md-4 text-start text-md-end pe-0 fw-bold">School District:</div>
                                <div className="col-12 col-md-8 no-localization">{propertyInfo.schoolDistrictName}</div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            {/* Tax Info*/}
                            <h4 className="pt-3"><MdOutlineAttachMoney className="lg-react-icons" /> Tax Information</h4>
                            <div className="row">
                                <div className="col-2 text-end pe-0 fw-bold">Year:</div>
                                <div className="col-4 no-localization">{propertyInfo.taxYear1}</div>
                                <div className="col-2 text-end pe-0 fw-bold">Tax:</div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.taxTotal1))}</div>
                            </div>
                            <div className="row">
                                <div className="col-2 text-end pe-0 fw-bold">Year:</div>
                                <div className="col-4 no-localization">{propertyInfo.taxYear2}</div>
                                <div className="col-2 text-end pe-0 fw-bold">Tax:</div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.taxTotal2))}</div>
                            </div>
                            <div className="row">
                                <div className="col-2 text-end pe-0 fw-bold">Year:</div>
                                <div className="col-4 no-localization">{propertyInfo.taxYear3}</div>
                                <div className="col-2 text-end pe-0 fw-bold">Tax:</div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.taxTotal3))}</div>
                            </div>
                            {(propertyInfo.siteState === "MT") && <div className="row">
                                <div className="col-12 text-center">May be subject to additional city taxes.</div>
                            </div>}
                            <div className="row d-print-none">
                                <div className="col-12"><hr /></div>
                            </div>
                            <div>
                                {propertyInfo.taxExemptCode && <div className="row justify-content-between m-0">
                                    <div className="col-7 ps-0 text-start text-md-end fw-bold">Tax Code Area:</div>
                                    <div className="col-5 no-localization">{propertyInfo.taxExemptCode}</div>
                                </div>}
                                <div className="row justify-content-between m-0">
                                    <div className="col-7 ps-0  text-start text-md-end fw-bold">Levy Code {propertyInfo.levyYear}:</div>
                                    <div className="col-5 no-localization">{propertyInfo.levyCode}</div>
                                </div>
                                <div className="row justify-content-between m-0">
                                    <div className="col-7 ps-0  text-start text-md-end fw-bold">Levy Rate {propertyInfo.levyYear}:</div>
                                    <div className="col-5">{propertyInfo.levyRate}</div>
                                </div>
                            </div>
                            <h4 className="pt-3"><MdOutlineCalculate className="lg-react-icons" /> Assessment Information</h4>
                            <div className="row fw-bold">
                                <div className="col-2">Year</div>
                                <div className="col-4">Description</div>
                                <div className="col-2">Acres</div>
                                <div className="col-4">Value</div>
                            </div>
                            <div className="row">
                                <div className="col-2 no-localization">{propertyInfo.assessmentYear}</div>
                                <div className="col-4">Land Value</div>
                                <div className="col-2 no-localization">{propertyInfo.acres}</div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.assessedLandValue))}</div>
                            </div>
                            <div className="row lh-sm">
                                <div className="col-2 no-localization">{propertyInfo.assessmentYear}</div>
                                <div className="col-4">Improvement Value</div>
                                <div className="col-2"></div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.assessedImprovementValue))}</div>
                            </div>
                            <div className="row">
                                <div className="col-6 fw-bold">Totals:</div>
                                <div className="col-2 no-localization">{propertyInfo.acres}</div>
                                <div className="col-4 no-localization">{FormatMoney(Number(propertyInfo.assessedTotalValue))}</div>
                            </div>
                            {(propertyInfo.taxExemptDescription === "N/A") ? 
                                <div className="row">
                                    <div className="col-12"><strong>Homeowner's Exemption: </strong> Contact County</div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-8 fw-bold">Homeowner's Exemption:</div>
                                    <div className="col-4 no-localization">{FormatMoney(Math.abs(Number(propertyInfo.taxExemptAmount)))}</div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <h4 className="pt-3"><MdOutlinedFlag className="lg-react-icons" /> Land Information</h4>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Residential Acres:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.acres}</div>
                            </div>
                            {propertyInfo.waterSource && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Water Source:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.waterSource}</div>
                            </div>}
                            {propertyInfo.sewerDescription && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Sewer:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.sewerDescription}</div>
                            </div>}
                            {propertyInfo.sidewalk && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Sidewalks:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.sidewalk}</div>
                            </div>}
                        </div>
                    </div>
                    <div>
                        <h4 className="pt-3"><MdOutlineCottage className="lg-react-icons" /> Residential Characteristics</h4>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 no-localization"><MdDirectionsWalk className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Walk Score<sup>&reg;</sup>:</LinkExternal></div>
                                {selectedPropertyP1Data && (selectedPropertyP1Data.wsWalkScore || selectedPropertyP1Data.wsWalkScore === 0) && <div className="col-6 col-md-3"><LinkExternal href="https://www.walkscore.com/how-it-works/">{selectedPropertyP1Data.wsWalkScore}</LinkExternal></div>
                                }
                            </div>
                            {selectedPropertyP1Data && selectedPropertyP1Data.wsBikeScore && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 no-localization"><MdDirectionsBike className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Bike Score<sup>&reg;</sup>:</LinkExternal></div>
                                <div className="col-6 col-md-3"><LinkExternal href="https://www.walkscore.com/how-it-works/">{selectedPropertyP1Data.wsBikeScore}</LinkExternal></div>
                            </div>}
                            {selectedPropertyP1Data && (selectedPropertyP1Data.wsTransitScore || selectedPropertyP1Data.wsTransitScore===0) && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 no-localization"><MdDirectionsBus className="sm-react-icons" /> <LinkExternal href="https://www.walkscore.com/how-it-works/">Transit Score<sup>&reg;</sup>:</LinkExternal></div>
                                <div className="col-6 col-md-3"><LinkExternal href="https://www.walkscore.com/how-it-works/">{selectedPropertyP1Data.wsTransitScore}</LinkExternal></div>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Main Floor Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.floor1SquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Deck Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.deckSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Garage Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.garageSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Year Built:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.yearBuilt}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Lower Floor Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.floor1SquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Porch Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.coveredPorchSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Carport Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.carPortSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">AC:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.airConditioning}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Upper Floor Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.floor2SquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Patio Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.patioSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Bedrooms:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.bedCount}</div>
                            </div>
                            {propertyInfo.heatSource && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Heating:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.heatSource}</div>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Attic Fin. Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.finishedAtticSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Attic Unf. Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.unfinishedAtticSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Bathrooms:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.bathTotalCount}</div>
                            </div>
                            {propertyInfo.elevator && <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Elevator:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.elevator}</div>
                            </div>}
                        </div>
                        <div className="row">
                            {(propertyInfo.basementSquareFootage > 0 && propertyInfo.finishedBasementSquareFootage <= 0 && propertyInfo.unfinishedBasementSquareFootage <= 0) &&
                                <div className="col-md-3 row">
                                    <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Bsmnt Sq Ft:</div>
                                    <div className="col-6 col-md-3 no-localization">{propertyInfo.basementSquareFootage}</div>
                                </div>}
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Bsmnt Fin. Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.finishedBasementSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Bsmnt Unf. Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.unfinishedBasementSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Fireplace:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.fireplaceCount}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Total Sq Ft:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.finishedTotalSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Total Unfinished:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.unfinishedTotalSquareFootage}</div>
                            </div>
                            <div className="col-md-3 row">
                                <div className="col-6 col-md-9 text-start text-md-end pe-0 fw-bold">Pool:</div>
                                <div className="col-6 col-md-3 no-localization">{propertyInfo.pool ? "Y" : "N"}</div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export default ParcelInfo;