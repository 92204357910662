import { Link } from 'react-router-dom';
import './user-profile-nav.scss';
import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from '../../preferred-customer/sign-in-button';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user-context';


const UserProfileNav = ({ className }) => {
    const isAuthenticated = useIsAuthenticated();
    const { loadingUser, pcUser } = useContext(UserContext);

    return (
        <div className={`userProfileNav ${className}`}>
            {isAuthenticated && !loadingUser && pcUser &&
                <>
                <Link to="/tools" className="mx-xl-3 mx-2">TOOLS</Link>
                <span className="mx-xl-3 mx-2 text-uppercase"><strong className="welcome">Welcome,</strong> <Link className="no-localization" to="/preferred-customer/profile">{pcUser.firstName}</Link></span>
                <Link to="/preferred-customer/profile" className="mx-xl-3 mx-2">PROFILE</Link>
                <Link to="/preferred-customer/log-out" className="ms-xl-3 ms-2">LOG OUT</Link>
                </>
            }
            {(!isAuthenticated || (!loadingUser && !pcUser)) &&
                <>
                    <Link to="/preferred-customer/sign-up" className="mx-xl-3 mx-2">SIGN UP</Link>
                    <SignInButton cssClasses="btn btn-link p-0 ms-3 align-baseline" />
                </>
             }
        </div>
    );
}

export default UserProfileNav;