import React, { useState, useLayoutEffect, useContext } from 'react';
import apiPublic from '../../services/api-public';
import { BrandContext } from '../../contexts/brand-context';
import './item-of-the-week.scss';
import { UserContext } from '../../contexts/user-context';
import { useLocation } from 'react-router-dom';

const SiteMessage = () => {
    const [siteMessages, setSiteMessages] = useState([]);
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser, activeRegion } = useContext(UserContext);
    const location = useLocation();

    useLayoutEffect(() => {
        if (!selectedBrand) return;
        const getSiteMessages = async () => {
            await apiPublic.get('/site-message/' + selectedBrand.brandId + '/' + (activeRegion ? activeRegion.regionId : '-1') +  '?pagePath=' + location.pathname, { noErrorHandling: true })
                .then((response) => {
                    setSiteMessages(response.data);
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                });
        }
        getSiteMessages();
    }, [selectedBrand, pcUser, location, activeRegion]);

    return (
        <div className="row d-print-none g-0">
            <div className="col text-danger text-center"> 
                {siteMessages && siteMessages.length > 0 && 
                    siteMessages.map((sm, i) => <p key={i + "_" + sm.messageId}>{sm.message}</p>)
                }
            </div>
        </div>
    );
}

export default SiteMessage;