import React from 'react';
import { useLocation } from 'react-router-dom';
import NavLead from './header/nav-lead';
import NavMenu from './header/nav-menu';
import Footer from './footer/footer';
import HomeHero from '../home/home-hero';
import { HelmetProvider } from 'react-helmet-async';
import DefaultHeaderTags from './default-header-tags';
import { BrandProvider } from '../../contexts/brand-context';
import ToolsSlider from '../home/tools-slider'
import BrandSafeOutlet from './brand-safe-outlet';
import { ErrorBoundary } from '../error-boundary';
import IOTW from './item-of-the-week';
import { Suspense } from 'react';
import SpinnerLoader from './spinner-loader';
import SiteMessage from './site-message';

const Layout = () => {
    const location = useLocation();
    const isHomePage = (location.pathname === '/');
    
    return (
        <BrandProvider>
            <HelmetProvider>
                <DefaultHeaderTags />
                <IOTW />
                <div className="d-flex flex-column h-100">
                    <a href="#skip-link-target" className="visually-hidden-focusable">Skip to main content</a>
                        <NavLead />
                        <SiteMessage/>
                        <NavMenu />
                        <div role="complementary">
                            {isHomePage && <HomeHero />}
                            {isHomePage && <ToolsSlider />}
                        </div>
                        <div role="main" className="page-content-container container-fluid container-lg pb-3 pt-3 pt-lg-0" id="skip-link-target">
                        <ErrorBoundary key={location?.pathname}>
                            <Suspense fallback={<div><SpinnerLoader /></div> }>
                                <BrandSafeOutlet />
                            </Suspense>
                        </ErrorBoundary>
                        </div>
                        <Footer />
                </div>
            </HelmetProvider>
        </BrandProvider>
    );
}

export default Layout;