import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"

const SellerSideTotals = ({data, total, className, saving, setSellersTotals, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();
    const watchEstimatedProceeds = watch("estimatedProceeds");
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && loadedNetSheet.sellerNotes) {
            setValue('sellerNotes', loadedNetSheet.sellerNotes);
            setAlreadyLoadedNetSheet(true);
        } 
    }, [loadedNetSheet]);

    useEffect(() => { 
        if (!total) return;
        setValue('totalSellingExpenses', currency(total, { separator: ',', symbol: '' }).format());
        setValue('estimatedProceeds', currency(data.netSheetFormData.purchasePrice, { separator: ',', symbol: '' }).subtract(total).format());
    }, [total])

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        setSellersTotals(formVals);
    };

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideTotals" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Totals</div>
                    <div className="col text-end">Total: {currency(watchEstimatedProceeds, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form >
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideTotals">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalSellingExpenses">Total Selling Expenses:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalSellingExpenses" type="text" defaultValue="0.00" readOnly="readonly" id="totalSellingExpenses" tabIndex="-1" className="form-control" {...register("totalSellingExpenses")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="estimatedProceeds">Estimated Proceeds:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="estimatedProceeds" type="text" defaultValue="0.00" readOnly="readonly" id="estimatedProceeds" tabIndex="-1" className="form-control" {...register("estimatedProceeds")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="sellerNotes">Notes:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <textarea name="sellerNotes" id="sellerNotes" className="form-control" {...register("sellerNotes")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideTotals;