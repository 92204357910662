import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import apiPublic from '../../services/api-public';
import apiTools from '../../services/api-tools';
import { FilePath, FormatNameToFilePath, TeamByNameSortFcn } from "../../services/utilities";
import Select from 'react-select';
import "./team.scss"
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import SpinnerLoader from '../layout/spinner-loader';
import TeamMemberModalBody from './team-member-modal-body';
import { bootstrap } from '../../app';

const Team = () => {
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser } = useContext(UserContext);
    const [loadingTeamMembers, setLoadingTeamMembers] = useState(true);
    const [loadingOffices, setLoadingOffices] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [selectedTeamMember, setSelectedTeamMember] = useState();
    const [selectedOffice, setSelectedOffice] = useState();
    const [nameSearch, setNameSearch] = useState('');
    const [officeNames, setOfficeNames] = useState([]);
    const [departments, setDepartments] = useState([]);
    let { paramLocation, paramDepartment, paramName } = useParams();
    const teamMemberModal = useRef();

    const [selectedDepartment, setSelectedDepartment] = useState(null);

    useEffect(() => {


        var promises = [];
        const getOffices = apiPublic.get(`/offices/${selectedBrand.brandId}`);
        promises.push(getOffices);
        const getDepartments = apiPublic.get(`/teammember-departments`);
        promises.push(getDepartments);
        const getTeamMembers = apiTools.get(`/team/${selectedBrand.brandId}`);
        promises.push(getTeamMembers);

        setLoadingTeamMembers(true);
        setLoadingOffices(true);

        Promise.all(promises)
            .then((responses) => {

                var officeOptions = [];
                responses[0].data.forEach((state) => {
                    var names = [];
                    state.forEach((office) => {
                        names.push({ value: office.officeId, label: office.officeName });
                    });
                    officeOptions.push({ label: state[0].stateFullName, options: names });
                });
                setOfficeNames(officeOptions);

                var departmentOptions = [];
                responses[1].data.forEach((dept) => {
                    departmentOptions.push({ label: dept.departmentName, value: dept.departmentId });
                });
                setDepartments(departmentOptions);

                setTeamMembers(responses[2].data);

                setLoadingOffices(false);
                setLoadingTeamMembers(false);

            });

    }, [selectedBrand.brandId]);

    useEffect(() => {
        if (officeNames && paramLocation) {
            officeNames.forEach(states => {
                states.options.forEach(office => {
                    if (office.label.toLowerCase() === paramLocation.toLowerCase()) setSelectedOffice(office);
                });
            });
            setSelectedDepartment(null);
        }
    }, [officeNames, paramLocation]);

    useEffect(() => {
        if (paramDepartment) {
            var requestedDept = paramDepartment
            if (requestedDept === "sales") requestedDept = 'sales-and-marketing';
            departments.forEach(dept => {
                if (dept.label.toLowerCase() === requestedDept.toLowerCase()) setSelectedDepartment(dept);
            });
        }
    }, [paramDepartment, departments]);

    useEffect(() => {
        if (paramName) {
            setNameSearch(paramName);
            setSelectedDepartment(null);
        }
    }, [paramName, teamMembers]);

    const doFilterAndSort = () => {
        var results = teamMembers;
        if (selectedOffice) {
            results = filterByOffice(results, selectedOffice).sort(TeamByNameSortFcn);
        }
        if (selectedDepartment) {
            results = filterByDepartment(results, selectedDepartment).sort(TeamByNameSortFcn);
        }
        if (nameSearch) {
            results = filterByNameSearch(results, nameSearch).sort(TeamByNameSortFcn);
            if (paramName && nameSearch === paramName && results.length === 1) {
                setSelectedTeamMember(results[0]);
                var modal = new bootstrap.Modal(teamMemberModal.current, {});
                modal.show();
            }
        }

        if (!selectedOffice && !selectedDepartment && !nameSearch) {
            if (selectedBrand.brandId === 1) {
                results = filterByTitle(results, "Escrow Officer").sort(TeamByNameSortFcn);
            }
            else {
                results = results.sort(TeamByNameSortFcn);
            }
        }
        setFilteredMembers(results);
    };


    const filterByOffice = (t, o) => {
        return t.filter(function (m) {
            return m.offices.some(function (op) { return op.officeId === o.value; });
        }).map((t) => { return { ...t, offices: t.offices.filter((op) => op.officeId === o.value) }; });
    };

    const filterByDepartment = (t, d) => {
        var dept = d.label === 'admin' ? 'admin & other' : d.label;
        return t.filter(function (m) { return m.departments.some(d => d.departmentName.toLowerCase() === dept?.toLowerCase().replaceAll('-and-', ' & ').replaceAll('-', ' ')) });
    };

    const filterByNameSearch = (t, n) => {
        var lowerCaseSearch = n.toLowerCase();
        return t.filter(function (m) {
            return (
                m.firstName.toLowerCase().includes(lowerCaseSearch) ||
                m.lastName.toLowerCase().includes(lowerCaseSearch) ||
                (m.firstName.toLowerCase() + ' ' + m.lastName.toLowerCase()).includes(lowerCaseSearch) ||
                (m.lastName.toLowerCase() + ' ' + m.firstName.toLowerCase()).includes(lowerCaseSearch) ||
                (m.lastName.toLowerCase() + ', ' + m.firstName.toLowerCase()).includes(lowerCaseSearch));
        });
    };

    const filterByTitle = (t, ttl) => {
        return t.filter(function (m) {
            return m.title?.toLowerCase().includes(ttl?.toLowerCase());
        });
    }

    useEffect(() => {
        if (!officeNames || !teamMembers || teamMembers.length === 0) return;
        doFilterAndSort();
    }, [selectedDepartment, selectedOffice, nameSearch, teamMembers, officeNames])

    const teamLayout = () => {
        return <div className="row mb-3">
                {filteredMembers && filteredMembers.map(function (member, index) {
                    return <Fragment key={index}>
                        <div className="col-6 col-md-4 col-lg-3 text-center p-4 team-cards">
                            <span data-bs-toggle="modal" data-bs-target="#teamMemberModal" role="button" onClick={() => setSelectedTeamMember(member)}>
                                <img className="no-localization" loading="lazy" src={FilePath(`/teamimages/${FormatNameToFilePath(member.firstName + " " + member.lastName)}.png`)} alt={member.firstName + " " + member.lastName + " profile image"} width="120" height="120" />
                                <div className="team-member-name fw-bold text-primary no-localization">{member.firstName + " " + member.lastName}</div>
                                <div className="fw-bold lh-sm">{member.title}</div>
                            </span>
                        </div>
                    </Fragment>;
                })}
            </div>
    }

    return (
        <>
            <Helmet>
                <title>Team : {selectedBrand.brandName}</title>
            </Helmet>

            <h1>Our Team</h1>
            <p>With a team of the industry's top professionals, we offer you innovative technology, streamlined closings, and legendary service to ensure your title and escrow transactions are a success.</p>
            {pcUser && <p><Link to="/company-directory">Preferred Customers: View Company Directory & Phone List</Link></p>}
            {(selectedBrand.brandName !== "Sun Valley Title") && <div id="teamfilteroptions" className="row">
                <div className="col-12 col-lg-4 pb-2">
                    <span id="singleOfficeDisplay"></span>
                    <Select styles={{ control: (baseStyles) => ({ ...baseStyles, height: '42px' }) }} classNamePrefix="no-localization" isSearchable={false} isClearable={true} onChange={e => setSelectedOffice(e)} placeholder="View by Location" aria-label="View by Location" options={officeNames} value={selectedOffice} />
                </div>
                <div className="col-12 col-lg-4 pb-2">
                    <Select styles={{ control: (baseStyles) => ({...baseStyles, height: '42px'})}} isSearchable={false} isClearable={true} onChange={e => setSelectedDepartment(e)} value={selectedDepartment} placeholder="View by Department" aria-label="View by Department" options={departments} />
                </div>
                <div className="col-12 col-lg-4 pb-2 ml-auto">
                    <input type="search" className="form-control" placeholder="Search by Name" aria-label="Search by Name" value={nameSearch} onChange={e => setNameSearch(e.target.value)} />
                </div>
            </div>}
            {(loadingTeamMembers || loadingOffices) && <SpinnerLoader /> }
            {(!loadingTeamMembers && !loadingOffices && filteredMembers.length === 0) ? <p className="text-center mt-5">No results found...</p> : teamLayout()}

            <div className="modal" id="teamMemberModal" ref={teamMemberModal} tabIndex="-1" aria-labelledby="teamMemberModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="teamMemberModalLabel">Team Member Details</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <TeamMemberModalBody selectedTeamMember={selectedTeamMember} selectedOffice={selectedOffice} selectedBrand={selectedBrand} pcUser={pcUser} />
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal" onClick={() => setSelectedTeamMember(null)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Team;