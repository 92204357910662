import React, { useEffect, Fragment, useContext } from 'react';
import { MdOutlineInsertDriveFile } from 'react-icons/md';
import apiTools from '../../services/api-tools';
import SpinnerLoader from '../layout/spinner-loader';
import { DateTime } from 'luxon';
import { FormatPhoneNumber, EventTrack } from '../../services/utilities';
import { BrandContext } from '../../contexts/brand-context';
import LinkExternal from '../layout/link-external';

const Deeds = ({ pcUser, activeRegion, selectedPropertyP1Data, deedState, setDeedState, logActivity, showQuickProfileRequestTab }) => {
    const { selectedBrand } = useContext(BrandContext);

    useEffect(() => {
        const getDeeds = async () => {
            apiTools.get(`/deeds?state=${selectedPropertyP1Data.state}&county=${selectedPropertyP1Data.county}&parcelId=${selectedPropertyP1Data.parcelId}&owner=${encodeURIComponent(selectedPropertyP1Data.owner)}`, { noErrorHandling: true })
                .then((response) => {
                    setDeedState({ deeds: response.data, loading: false });
                })
                .catch((error) => { 
                    setDeedState({ deeds: [], loading: false });
                });
        }
        if (selectedPropertyP1Data && selectedPropertyP1Data.parcelId) {
            getDeeds();
        }
    }, [selectedPropertyP1Data]);

    const trackDownload = (deed, parcelId) => {
        logActivity("PropertyOne - Document Downloaded From Sentry", `County: ${selectedPropertyP1Data.county}, \nDocument Number: ${deed.documentNo}, \nParcelID: ${parcelId}`);
    }


    return (
      <>
          {deedState.loading && <SpinnerLoader />} 
            {!deedState.loading && deedState.deeds && deedState.deeds.length > 0 && 
            <div className="p-2">
              
                <div className="row">
                  {selectedBrand.brandId === 2 ? <p className="text-danger">Deeds are available electronically from 1993 forward. Ownership prior to this date won't be available in PropertyOne and will require us to locate the deeds manually for you. Due to the complicated nature of some legal descriptions, not all deeds shown may apply to this property. Results are for informational purposes only.</p> :
                      <p className="text-danger">Due to the complicated nature of some legal descriptions, not all deeds shown may apply to this property.</p>}
                    <p>Recorded documents back to most recent warranty deed.</p>
                    <div className="fw-bold border-bottom border-dark col-4 col-sm-2">Inst. No.</div>
                    <div className="fw-bold border-bottom border-dark col-4 col-sm-2">Type</div>
                    <div className="fw-bold border-bottom border-dark col-4 col-sm-2">Rec. Date</div>
                    <div className="fw-bold border-bottom border-dark col-4 col-sm-3">Sub Name</div>
                    <div className="fw-bold border-bottom border-dark col-3 col-sm-1">Lot/Block</div>
                    <div className="fw-bold border-bottom border-dark col-4 col-sm-2">ARB/T/S/R</div>
                </div>
                {deedState.deeds.map((deed, index) => { 
                return <Fragment key={index}>
                    <div className="row lh-sm pt-2 no-localization">
                        <div className="col-4 col-sm-2"><LinkExternal href={deed.docLink} onClick={() => trackDownload(deed, selectedPropertyP1Data.parcelId)} className="fw-bold"><MdOutlineInsertDriveFile className="sm-react-icons pe-1"/>{ deed.documentNo }</LinkExternal></div>
                        <div className="col-4 col-sm-2">{ deed.instrumentType}</div>
                        <div className="col-4 col-sm-2">{DateTime.fromISO(deed.filedDate).toFormat("M/d/yyyy")}</div>
                        <div className="col-4 col-sm-3">{ deed.addition}</div>
                        <div className="col-4 col-sm-1">{ deed.lot && deed.lot + "/"}{ deed.block && deed.block}</div>
                        <div className="col-4 col-sm-2">{ deed.arb && deed.arb}</div>
                    </div>
                    <div className="row pb-2">
                        <div className="col-12 ps-3">
                            <div><span className="fw-bold">Grantor: </span><span className="no-localization">{deed.grantor}</span></div>
                            <div><span className="fw-bold">Grantee: </span><span className="no-localization">{deed.grantee}</span></div>
                        </div>
                    </div>
                </Fragment>
                })
              }
              
                  
                <div className="row row-cols-1 row-cols-md-2 px-0 px-md-5 ml-md-5 border-top border-dark">
                    <div className="col">DT = Deed of Trust </div>
                    <div className="col">QCD = Quitclaim Deed</div>
                    <div className="col">MOD = Loan Modification</div>
                    <div className="col">PRD = Warranty Deed</div>
                    <div className="col">ND = Notice of Default</div>
                    <div className="col">TD = Trustee Deed</div>
                    <div className="col">WD = Warranty Deed</div>
                    <div className="col">SWD = Special Warranty Deed</div>
                    <div className="col">ADT = Assignment of Deed of Trust</div>
                    <div className="col">RES = Rescission of Notice of Default</div>
                    <div className="col">MTG = Mortgage</div>
                </div>
                Not seeing what you were looking for?  Please contact our customer service department. We will quickly research your request to determine what is available.
                <br/>
                    {activeRegion && <span>By Phone: {FormatPhoneNumber(activeRegion.stateAndCounties[0].phoneNumber)}<br />By Email: <a href={"mailto:" + activeRegion.stateAndCounties[0].csEmailAddress}>{activeRegion.stateAndCounties[0].csEmailAddress}</a><br /></span>}
            </div>
            }
            {!deedState.loading && (!deedState.deeds || deedState.deeds.length === 0) && <div>
                <p>The deeds for this property could not be located automatically.  Not all parcel deeds are available online. Submit this form to have our customer service department locate them for you.</p>

                <p><button type="button" className="btn btn-primary" onClick={() => { showQuickProfileRequestTab(); EventTrack("PropertyOne", "Quick Profile Request Button", "Deeds Tab"); } }>Request Property Profile</button> </p>
            </div>}
      </>
  );
}

export default Deeds;