import currency from 'currency.js';
import ReactGA from 'react-ga4';
import { components } from 'react-select';

export const FilePath = (path) => {
  return process.env.REACT_APP_FILES_BASE_URL + path;
}

export const GetSelectedLanguageSubdirectory = () => { return ("/" + localStorage.getItem('ljs-lang')).replace("/en", ""); }
export const GetSelectedLanguage = () => { return localStorage.getItem('ljs-lang'); }


export const FormatNameToFilePath = (name) => {
    return name.toLowerCase().replace(/ {2}/g, " ").replace(/ /g, "-").replace(/(-)+/g, "-").replace(/[']/g, "").replace(/[.]/g, "");
}

export const EventTrack = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

export const FormatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
}

export const FormatMoney = (number) => {
    if (!number && number !== 0) return null;
    return currency(number , { separator: ',', symbol: '$',   negativePattern: `(!#)` }).format();
}

export const FormatMoneyNoDollarSign = (number) => {
    if (!number && number !== 0) return null;
    return currency(number , { separator: ',', symbol: '',   negativePattern: `(!#)` }).format();
}

export const UnFormatMoney = (string) => {
    return currency(string).value;
}

export const toTitleCase = (str) => {
  return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

export const FormatPhoneInput = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const BuildAxiosClientErrorDetail = (axiosError, accounts) => {
    var clientErrorDetail = {
        title: axiosError.message, 
        baseURL: axiosError.config.baseURL,
        url: axiosError.config.url,
        method: axiosError.config.method
    };
    if (axiosError.data) {
        clientErrorDetail = {
            ...clientErrorDetail,
            detail: axiosError.data.title + " " + axiosError.data.detail,
            status: axiosError.status,
            statusText: axiosError.statusText,
        };
    }
    else if (axiosError.response) {
        clientErrorDetail = {
            ...clientErrorDetail,
            detail: axiosError.response.statusText,
            status: axiosError.response.status,
            statusText: axiosError.response.statusText,
        };
    }
    if (accounts && accounts.length > 0) {
        clientErrorDetail = { ...clientErrorDetail, azureAccountId: accounts[0].localAccountId };
    }
    return clientErrorDetail;
}

export const DisplayHtml = (stringWithHtml) => {
    var createMarkup = (html) => {
        return { __html: html };
    };
    return <div dangerouslySetInnerHTML={createMarkup(stringWithHtml)} />;
}

export const ReactSelectInputNoAutoComplete = ({ children, ...props }) => {
    return (
        <components.Input {...props} autoComplete="chrome-off">
            {children}
        </components.Input>
    );
};

//not for multiple emails
export const GetEmailRegex = () => { 
    return /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
}


export const dateWithoutTime = function (date) {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
}

export const TeamByNameSortFcn = function (a, b) {
    var lcaseA = (a.lastName + a.firstName).toLowerCase();
    var lcaseB = (b.lastName + b.firstName).toLowerCase();
    return (lcaseA === lcaseB) ? 0 : (lcaseA < lcaseB) ? -1 : 1;
}