import React, { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { BrandContext } from '../../contexts/brand-context';

const BrandSafeOutlet = () => {
    const { selectedBrand } = useContext(BrandContext);
    const location = useLocation();

    return (
        <>
            {(selectedBrand || location.pathname === "/oops") && <Outlet />}
        </>
    );
}

export default BrandSafeOutlet;