import axios from 'axios';
import { msalInstance } from '../index';
import { tokenRequest } from '../auth-config';
import apiPublic from './api-public';
import { BuildAxiosClientErrorDetail } from './utilities';

const apiPreferredCustomer = axios.create({
  baseURL: process.env.REACT_APP_API_PREFERREDCUSTOMER_BASE_URL
});


apiPreferredCustomer.interceptors.request.use(
    request => {
        var authorizeHeader = "";
        var accessToken = localStorage.getItem("pcuser.accessToken");
        if (accessToken) {
            authorizeHeader = `Bearer ${accessToken}`;
        }
        request.headers = {
            'Authorization': authorizeHeader,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_PREFERREDCUSTOMER_SUBSCRIPTION_KEY
        };
        return request;
    },
    error => {
        console.log('error with request: ', error);
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

apiPreferredCustomer.interceptors.response.use(
    response => {
        // Return a successful response back to the calling service
        return response;
    },
    error => {
        if (error.response.status === 401) { // Unauthorized
            const account = msalInstance.getActiveAccount();
            console.log("called a pc api and got unauth... checking for msal acct", account);
            if (!account) {
                localStorage.removeItem("pcuser");
                localStorage.removeItem("pcuser.accessToken");
                if (error.config.noErrorHandling) {
                    return Promise.reject("No active account");
                }
                else {
                    window.location.href = '/preferred-customer/account-required';
                }
            }

            // Get new updated access token and try again
            return msalInstance.acquireTokenSilent({
                ...tokenRequest,
                account: account
            }).then((authResult) => {
                var newAccessToken = authResult.accessToken;
                localStorage.setItem("pcuser.accessToken", newAccessToken);
                error.config.headers.Authorization = `Bearer ${newAccessToken}`;
                return apiPreferredCustomer.request(error.config);
            }).catch((retryerror) => {
                console.log("retryerror:", retryerror);
                localStorage.removeItem("pcuser");
                localStorage.removeItem("pcuser.accessToken");
                if (error.config && error.config.noErrorHandling) {
                    return Promise.reject("No active account");
                }
                else { 
                    window.location.href = '/preferred-customer/account-required';
                }
            });
        }
        if (error.response.status !== 401 && error.response.status !== 0 && !error.config.noErrorHandling) {
            const selectedBrand = JSON.parse(localStorage.getItem("selectedBrand"));
            const errObj = { ...BuildAxiosClientErrorDetail(error), brand: selectedBrand };

            return apiPublic.post(`/log-error`, errObj, { noErrorHandling: true })
                .finally(() => {
                    window.location.href = '/oops';
                });
        }
        return new Promise((resolve, reject) => {
            reject(error.response);
        });
    }
);

export default apiPreferredCustomer;