import React, { useState, useEffect, useContext, useRef } from 'react';
import apiPublic from '../../services/api-public';
import { BrandContext } from '../../contexts/brand-context';
import './item-of-the-week.scss';
import { bootstrap } from '../../app';
import { FilePath } from "../../services/utilities";
import LinkExternal from './link-external';
import { EventTrack } from "../../services/utilities";

const ItemOfTheWeek = () => {
    const [itemOfTheWeek, setItemOfTheWeek] = useState(null);
    const { selectedBrand, loadingBrands } = useContext(BrandContext);
    const iotwModal = useRef(null);

    function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    }   
    useEffect(() => {
        if (!selectedBrand || loadingBrands) return;
        const getItemOfTheWeek = async () => {
            apiPublic.get('/item-of-the-week/' + selectedBrand.brandId, { noErrorHandling: true })
                .then((response) => {
                    const iotws = response.data;
                    if (iotws) {
                        const regionaliotws = iotws.filter((i) => i.itemOfTheWeekRegions.length > 0);
                        const branddefaultiotw = iotws.find((i) => i.itemOfTheWeekRegions.length === 0);
                        var lastActiveRegion = JSON.parse(localStorage.getItem('pcuser.activeRegion'))
                        if (regionaliotws.length > 0 && lastActiveRegion && regionaliotws.find(t => t.itemOfTheWeekRegions.find(t => t.regionId === lastActiveRegion.regionId))) {
                            setItemOfTheWeek(regionaliotws.find(t => t.itemOfTheWeekRegions.find(t => t.regionId === lastActiveRegion.regionId)));
                        }
                        else if (branddefaultiotw) {
                            setItemOfTheWeek(branddefaultiotw);
                        }
                    }
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                });
        }
        var showItemOfTheWeek = !document.cookie.includes("ItemOfTheWeekShown") || document.cookie.split(';').some((item) => item.trim().startsWith('ItemOfTheWeekShown=') && Date.parse(item.split('=')[1]) <= getMonday((new Date()).setHours(0, 0, 0, 0)));
        var isMobileIOTW = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i));
        showItemOfTheWeek && !isMobileIOTW && getItemOfTheWeek();
    }, [selectedBrand, loadingBrands]);

    useEffect(() => {
        if (iotwModal.current && itemOfTheWeek) {
            var iotwModalObj = new bootstrap.Modal(iotwModal.current);
            iotwModalObj.show();
            var now = new Date();
            document.cookie = 'ItemOfTheWeekShown=' + now + ';max-age=2592000; path=/';        
            iotwModal.current.addEventListener('hide.bs.modal', (e) => {
                EventTrack("IOTW", "Modal Dismissed", itemOfTheWeek.buttonURL);
            });
        }
    }, [itemOfTheWeek]);

    return (
        <> {itemOfTheWeek && 
            <div className="modal iotw no-localization" ref={iotwModal} tabIndex="-1" aria-hidden="true" id="iotwModal">
                <div className="modal-dialog">
                    <div className="white-popup">
                        <div className="iotw-border">
                            <button type="button" data-bs-dismiss="modal" className="btn-close opacity-100 iotw-close bg-primary" aria-label="Close" />
                            <div className="modal_left d-flex align-items-center p-3">
                                <div className="w-100">
                                    <div className="modal_h1" dangerouslySetInnerHTML={{ __html: itemOfTheWeek.title }}></div>
                                    <div className="no-localization" dangerouslySetInnerHTML={{ __html: itemOfTheWeek.text }}></div>
                                    <div className="text-center mt-2">
                                        <LinkExternal className="btn btn-primary" href={itemOfTheWeek.buttonURL} onClick={() => EventTrack("IOTW", "Modal Action Taken", itemOfTheWeek.buttonURL)} aria-label="Item of the week clickable button">
                                            <span dangerouslySetInnerHTML={{ __html: itemOfTheWeek.buttonText }}></span>
                                        </LinkExternal>
                                    </div>
                                </div>
                            </div>
                            <div className="modal_right mh-100">
                                <LinkExternal className="w-100 float-end" href={itemOfTheWeek.buttonURL} >
                                    <img className="iotw-image" src={FilePath("/images/ItemOfTheWeek/" + itemOfTheWeek.photo)}  alt="Item Of The Week"/>
                                </LinkExternal> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>           
            }
        </>
    );
}

export default ItemOfTheWeek;