import React from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";
import { UserContext } from '../../contexts/user-context';
import { InteractionStatus, InteractionRequiredAuthError, EventMessageUtils } from "@azure/msal-browser";
import { GetSelectedLanguage, GetSelectedLanguageSubdirectory } from '../../services/utilities';

const SignInButton = (props) => {
    const { instance } = useMsal();
    const location = useLocation();
    
    const handleLogin = () => {
        var loginRequest = {
            ...loginRequest,
            redirectUri: window.location.origin + GetSelectedLanguageSubdirectory() + "/preferred-customer/auth", 
            state: location.pathname,
            extraQueryParameters: {
                lang: GetSelectedLanguage()
            }
        };
        instance.loginRedirect(loginRequest)
            .catch(e => {
                console.error('Error After Login Redirect', e);
            });
    }

    return (
        <button className={props.cssClasses} onClick={() => handleLogin()}>LOG IN</button>
    );
}

export default SignInButton;