import React, { useState, useEffect } from 'react';
import apiTools from '../../services/api-tools';
import LinkExternal from '../layout/link-external';
import SpinnerLoader from '../layout/spinner-loader';
import './locator-maps.scss';

const LocatorMaps = ({ state, county, parcelId, setAerialMap, aerialMap, setPlatMap, platMap, setStreetLocatorMap, streetLocatorMap }) => {   
    const [loadingAerial, setLoadingAerial] = useState(false);    
    const [loadingStreet, setLoadingStreet] = useState(false);    
    const [locatorMapView, setLocatorMapView] = useState("aerial");
    const [loadingPlat, setLoadingPlat] = useState(false);  

    useEffect(() => { 
        const getLocatorMaps = async () => {
            setLoadingAerial(true);
            setLoadingPlat(true);
            apiTools.get(`/property-aerial-map?state=${state}&county=${county}&parcelId=${parcelId}`)
                .then((response) => {
                    setAerialMap(response.data.url);
                    setLoadingAerial(false);
                });

            apiTools.get(`/property-street-locator-map?state=${state}&county=${county}&parcelId=${parcelId}`)
                .then((response) => {
                    setStreetLocatorMap(response.data.url);
                    setLoadingStreet(false);
                });

            apiTools.get(`/property-plat-map?state=${state}&county=${county}&parcelId=${parcelId}`)
                .then((response) => {
                    setPlatMap(response.data.url);
                    setLoadingPlat(false);
                });
        }
        getLocatorMaps();    
    }, [state, county, parcelId]);


    const locatorMapViewChange = (e) => {
        setLocatorMapView(e.target.value);
    }


    return (<>
        {(loadingPlat || loadingAerial || loadingStreet) && <SpinnerLoader />}
        <div className="row locator-maps">
            <div className="col-12 col-sm-6 mb-3">
            {(aerialMap && streetLocatorMap) ?
                    <>
                        <div className="pb-2">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lotLineMapType" id="lotLineMapCheckboxAerial" value="aerial" checked={locatorMapView === "aerial"} onChange={locatorMapViewChange} />
                                <label className="form-check-label fw-bold" htmlFor="lotLineMapCheckboxAerial">Aerial</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="lotLineMapType" id="lotLineMapCheckboxStreet" value="street" checked={locatorMapView === "street"} onChange={locatorMapViewChange} />
                                <label className="form-check-label fw-bold" htmlFor="lotLineMapCheckboxStreet">Street</label>
                            </div>
                            <LinkExternal href={(locatorMapView === "aerial") ? aerialMap : streetLocatorMap}>Open Map In Separate Window</LinkExternal>
                        </div>
                        <img className={"locator-map " + ((locatorMapView === "aerial") ? "d-block" : "d-none")} src={aerialMap} alt="Aerial Locator Map" />
                        <img className={"locator-map " + ((locatorMapView === "street") ? "d-block" : "d-none")} src={streetLocatorMap} alt="Street Locator Map" />
                    </>
                :
                    <strong>Aerial Map Not Available</strong>
            }
            </div>
            <div className="col-12 col-sm-6 mb-3">
            {platMap ?
                    <>
                        <div className="pb-2">
                            <strong className="pe-2">Plat Map</strong>
                            <LinkExternal href={platMap}>Open Map In Separate Window</LinkExternal>
                        </div>
                        <div className="aspect-ratio"><embed className="plat-map" src={platMap} /></div>
                    </>
                :
                    <strong>Plat Map Not Available</strong>
            }
            </div>
        </div>
    </>
    );     
}

export default LocatorMaps;