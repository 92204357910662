import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';


const BuyerSidePrepaidExpenses = ({data, propertyInsurance, setPrepaidExpensesTotal, className, saving, setBuyersPrepaidExpenses, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */

    const watchPropertyInsurance = watch("propertyInsurance");
    const watchInterestForMonth = watch("interestForRemainderOfMonth");
    const watchReserveMonthTaxes = watch("reserveMonthsTaxes");
    const watchReserveMonthTaxesDollars = watch("reserveMonthsTaxesDollars");
    const watchReserveMonthsInsurance = watch("reserveMonthsInsurance");
    const watchReserveInsuranceAmount = watch("reserveMonthsInsuranceAmount");
    const watchTotalPrepaidExpenses= watch("totalPrepaidExpenses");
    /* state vars */
    const [manuallyChangedInputs, setManuallyChangedInputs] = useState([]);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && data) {
            var newArr = loadedNetSheet.manuallyChangedValues ? [...loadedNetSheet.manuallyChangedValues] : [];
            for (const [key, value] of Object.entries(loadedNetSheet.buyersPrepaidExpenses)) {
                if (newArr.includes(key)) {
                    if (key === "interestForRemainderOfMonth") {
                        setValue("interestForRemainderOfMonth", currency(loadedNetSheet.netSheetFormData.loanType !== 'Cash' ? loadedNetSheet.buyersPrepaidExpenses.interestForRemainderOfMonth : 0, { separator: ',', symbol: '' }).format());
                    } else { 
                        setValue(key, value);
                    }
                }
            }
            setDefaults(newArr);
            setAlreadyLoadedNetSheet(true);
        } else { 
            if (!data) return;
            setDefaults(manuallyChangedInputs);
        }
    }, [loadedNetSheet, data]);

    const setDefaults = (manuallyChanged) => { 
        for (const [key, value] of Object.entries(data.buyersPrepaidExpenses)) {
            if (!manuallyChanged.includes(key) && key !== 'totalPrepaidExpenses' && key !== "propertyInsurance") {
                if (key === "interestForRemainderOfMonth") {
                    setValue("interestForRemainderOfMonth", currency(data.netSheetFormData.loanType !== 'Cash' ? data.buyersPrepaidExpenses.interestForRemainderOfMonth : 0, { separator: ',', symbol: '' }).format());
                } else if (key === "reserveMonthsTaxes") {
                    setValue("reserveMonthsTaxes", 3);
                } else { 
                    setValue(key, value);
                }
            }
        }
    }

    useEffect(() => { 
        if (!propertyInsurance) return;
        setValue('propertyInsurance', currency(propertyInsurance, { separator: ',', symbol: '' }).format());
    }, [propertyInsurance])

    useEffect(() => {
        const sum = currency(watchPropertyInsurance, { separator: ',', symbol: '' })
            .add(watchInterestForMonth)
            .add(watchReserveMonthTaxesDollars)
            .add(watchReserveInsuranceAmount)
            .format();
        setValue("totalPrepaidExpenses", sum);
        setPrepaidExpensesTotal(sum);
    }, [watchPropertyInsurance, watchInterestForMonth, watchReserveMonthTaxesDollars,watchReserveInsuranceAmount]);

    useEffect(() => { 
        if (!data) return;
        var reserve = data.netSheetFormData.annualTaxes / (12 / watchReserveMonthTaxes);
        setValue('reserveMonthsTaxesDollars', currency(reserve, { separator: ',', symbol: '' }).format());
    }, [watchReserveMonthTaxes, data])

    useEffect(() => { 
        if (!propertyInsurance) return;
        var monthlyInsurance = currency(propertyInsurance).divide(12).value;
        setValue('reserveMonthsInsuranceAmount', currency(monthlyInsurance, { separator: ',', symbol: '' }).multiply(watchReserveMonthsInsurance).format());
    }, [watchReserveMonthsInsurance, propertyInsurance])

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setBuyersPrepaidExpenses({formVals, manuallyChangedInputs});
    };

    const setManuallyChanged = (e) => {

        for (const [key, value] of Object.entries(data.buyersPrepaidExpenses)) {
            if (key === e.target.id
                && currency(value, { separator: ',', symbol: '' }).format() !== e.target.value
                && !manuallyChangedInputs.includes(e.target.id)) {
                setManuallyChangedInputs([...manuallyChangedInputs, e.target.id])
            }
        }
    }

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseBuyerSidePrepaidExpenses" aria-expanded="false" aria-controls="collapseBuyerSideCommonData">
                    <div className="col fw-bold">Prepaid Expenses</div>
                    <div className="col text-end">Total: <span className="no-localization">{currency(watchTotalPrepaidExpenses, { separator: ',', symbol: '$' }).format()}</span></div>
                </div>
                <form onBlur={e => { formatInput(e); setManuallyChanged(e) }} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseBuyerSidePrepaidExpenses">
                        <div className="accordion-body">
                            <div className="row row-cols-1 row-cols-sm-2">
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="propertyInsurance">Property Insurance:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="propertyInsurance" type="text" defaultValue="0.00" readOnly="readonly" tabIndex="-1" id="propertyInsurance" className="form-control currency"  {...register("propertyInsurance")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                 
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="interestForRemainderOfMonth">Interest For Remainder Of Month:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">$</span>
                                                <input name="interestForRemainderOfMonth" type="text" defaultValue="0.00" maxLength="8" id="interestForRemainderOfMonth" className="form-control clear-zero currency"  {...register("interestForRemainderOfMonth")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>                        
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="reserveMonthsTaxes">Reserve Months Taxes*:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">#</span>
                                                <input name="reserveMonthsTaxes" type="number" defaultValue="3" step="1" id="reserveMonthsTaxes" className="form-control clear-zero" {...register("reserveMonthsTaxes")}/>
                                            </div>
                                            <div className="input-group pt-1">
                                                <span className="input-group-text">$</span>
                                                <input name="reserveMonthsTaxesDollars" type="text" defaultValue="0.00" readOnly="readonly" id="reserveMonthsTaxesDollars" tabIndex="-1" className="form-control currency" {...register("reserveMonthsTaxesDollars")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="col">
                                    <div className="row pt-1">
                                        <div className="col-12 col-sm-4 form-label fw-bold p-0">
                                            <label htmlFor="reserveMonthsInsurance">Reserve Months Insurance:</label>
                                        </div>
                                        <div className="col-12 col-sm-7">
                                            <div className="input-group">
                                                <span className="input-group-text">#</span>
                                                <input name="reserveMonthsInsurance" type="number" defaultValue="0" step="1" id="reserveMonthsInsurance" className="form-control clear-zero" {...register("reserveMonthsInsurance")}/>
                                            </div>
                                            <div className="input-group pt-1">
                                                <span className="input-group-text">$</span>
                                                <input name="reserveMonthsInsuranceAmount" type="text" defaultValue="0.00" readOnly="readonly" id="reserveMonthsInsuranceAmount" tabIndex="-1" className="form-control currency" {...register("reserveMonthsInsuranceAmount")}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalPrepaidExpenses">Total Prepaid Expenses:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalPrepaidExpenses" type="text" defaultValue="0.00" maxLength="8" readOnly="readonly" id="totalPrepaidExpenses" tabIndex="-1" className="form-control" {...register("totalPrepaidExpenses")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default BuyerSidePrepaidExpenses;