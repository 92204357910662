import React, { useState, useEffect } from 'react';
import apiTools from '../../../services/api-tools';
import { FormatMoney, DisplayHtml, EventTrack } from '../../../services/utilities';
import { DateTime } from 'luxon';
import { MdEditNote, MdOutlineFileDownload, MdOutlineEmail, MdOutlineSave, MdOutlineWorkOutline, MdOutlineNoteAdd } from 'react-icons/md'
import { Link, useParams } from 'react-router-dom';
import ClosingCostCalcEmail from './email';
import fileDownload from 'js-file-download';
import ClosingCostCalcSaveName from './save-quote-name';

const CalculatorResultDisplay = ({ display, displayId, fieldVal, valCss, valId, ...other }) => {
    return (
        <>
            {fieldVal &&
                <div className="row my-2">
                    <div className="col-12 col-md-7 fw-bold text-start text-md-end lh-sm" id={displayId} >{DisplayHtml(display + ":")}</div>
                    <div className={`col-auto lh-sm ${(valCss) ? valCss : ""}`} id={valId} >{DisplayHtml(fieldVal)} </div>
                </div>
            }
        </>
    );
};

const ClosingCostCalculatorResults = ({ results, setSavedQuoteName, handleBackToForm, handleStartNewQuote, pcUser, selectedBrand, logActivity, countySettings }) => {
    let { paramClosingCostQuoteId } = useParams();
    const [calculatedHtml, setCalculatedHtml] = useState(null);
    const [quoteNameSaved, setQuoteNameSaved] = useState(false);

    useEffect(() => {
        setCalculatedHtml(document.getElementsByTagName("html")[0].outerHTML);
    }, [])

    const doDownload = async () => {
        apiTools.post(`/closing-cost-calc/download`, { htmlString: calculatedHtml }, { responseType: 'blob' })
            .then((response) => {
                fileDownload(response.data, `${selectedBrand.brandName.toLowerCase().replace(/ /g, "-")}-closing-cost-quote.pdf`);
            });
    }
    const saveQuoteName = (quoteName) => {
        const doSaveQuoteName = async (n) => {
            apiTools.post(`/closing-cost-calc/save-name`, { closingCostQuoteId: results.closingCostQuoteId, quoteName: n })
                .then((response) => {
                    setQuoteNameSaved(true);
                    setSavedQuoteName(n);
                    results.calcInput = { ...results.calcInput, savedQuoteName: n };
                });
        };
        doSaveQuoteName(quoteName);
    };

    return (
        <>
            {results.calcInput.savedQuoteName && <div><span className="fw-bold">Closing Cost Quote Name</span> - {results.calcInput.savedQuoteName}</div>}
            <div className="d-flex justify-content-around justify-content-lg-between flex-wrap d-print-none">
                <span className="btn-link text-primary" role="button" onClick={() => handleBackToForm()}><MdEditNote className="sm-react-icons" /> Update Quote</span>
                <span className="btn-link text-primary" role="button" id="emailModalLabel" data-bs-toggle="modal" data-bs-target="#emailClosingCostQuoteModal"><MdOutlineEmail className="sm-react-icons" /> Email</span>
                <span className="text-clickable text-primary" role="button" onClick={() => { doDownload(); EventTrack("Closing Cost Quote", "Download", "Link"); }}><MdOutlineFileDownload className="sm-react-icons" /> Download</span>
                {paramClosingCostQuoteId && <Link to="/closing-cost-calculator" ><MdOutlineNoteAdd className="sm-react-icons" /> New Quote</Link>}
                {!paramClosingCostQuoteId && <span className="text-clickable text-primary" role="button" onClick={() => handleStartNewQuote()}><MdOutlineNoteAdd className="sm-react-icons" /> New Quote</span>}
                {pcUser && <span className="text-clickable text-primary" role="button" id="saveClosingCostQuoteNameModalLabel" data-bs-toggle="modal" data-bs-target="#saveClosingCostQuoteNameModal"><MdOutlineSave className="sm-react-icons" /> Save</span>}
                <Link to="/saved-closing-cost-quotes"><MdOutlineWorkOutline className="sm-react-icons" /> View Saved</Link>
            </div>
            <ClosingCostCalcSaveName previouslySavedQuoteName={results.calcInput.savedQuoteName} saveQuoteName={saveQuoteName} quoteNameSaved={quoteNameSaved} setQuoteNameSaved={setQuoteNameSaved} propAddress={results.calcInput.address} loanAmount={FormatMoney(results.calcInput.loanAmount)} logActivity={logActivity} />
            <ClosingCostCalcEmail selectedBrand={selectedBrand} emailFrom={pcUser?.email} htmlForPdf={calculatedHtml} propAddress={results.calcInput.address}/>
            <div className="border my-2">
                <div className="bg-light p-2 h3">General Information</div> 
                <div className="px-1">
                    <div className="row my-1">
                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Generated By:</div>
                        <div className="my-1 col-12 col-md-8 lh-sm">{results.calcInput.customerName}</div>

                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Email:</div>
                        <div className="my-1 col-12 col-md-8 lh-sm">{results.calcInput.customerEmail}</div>

                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Property Address:</div>
                        <div className="my-1 col-12 col-md-8 lh-sm">{results.calcInput.address}, {results.calcInput.city}, {results.calcInput.state} {results.calcInput.zip}</div>

                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">County:</div>
                        <div className="my-1 col-12 col-md-8 lh-sm">{results.calcInput.propertyCounty.county}</div>


                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Quote Number:</div>
                        <div className="my-1 col-12 col-md-3 lh-sm">{results.closingCostQuoteId}</div>
                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Date:</div>
                        <div className="my-1 col-12 col-md-3 lh-sm">{DateTime.fromISO(results.quoteDate).toFormat("M/d/yyyy")}</div>

                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Property Type:</div>
                        <div className="my-1 col-12 col-md-3 lh-sm">Residential</div>
                        {results.calcInput.transactionType === "Purchase" &&
                            <>
                                <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Purchase Price:</div>
                                <div className="my-1 col-12 col-md-3 lh-sm">{FormatMoney(results.calcInput.purchasePrice)}</div>
                            </>
                        }
                        {results.calcInput.transactionType !== "Purchase" &&
                            <>
                                <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Loan Amount:</div>
                                <div className="my-1 col-12 col-md-3 lh-sm">{FormatMoney(results.calcInput.loanAmount)}</div>
                            </>
                        }
                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Transaction Type:</div>
                        <div className="my-1 col-12 col-md-3 lh-sm">{results.calcInput.transactionType}</div>
                        {results.calcInput.transactionType === "Purchase" &&
                            <>
                                <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Loan Amount:</div>
                                <div className="my-1 col-12 col-md-3 lh-sm">{FormatMoney(results.calcInput.loanAmount)}</div>
                                <div className="my-1 col-12 col-md-6"> </div>
                            </>
                        }
                        <div className="my-1 col-12 col-md-3 fw-bold text-start text-md-end lh-sm">Loans To Pay Off:</div>
                        <div className="my-1 col-12 col-md-3 lh-sm">{results.calcInput.loansToPayOff}</div>                        

                    </div>
                </div>
            </div>
            <div className="border my-2">
                <div className="bg-light p-2 h3">Estimated {(results.calcInput.transactionType === "Refinance") ? "Borrower" : "Buyer"} Closing Costs {results.calcInput.quoteFormat === "TRID" && <span className="small">(as shown on closing disclosure)</span>}</div>
                <div className="px-1">
                    {results.calcInput.quoteFormat === "TRID" && <>
                        <CalculatorResultDisplay display={results.loanPolicyNameLabel + " Premium"} displayId="buyerTitlePremiumLabel" fieldVal={FormatMoney(results.loanPolicyPremium)} valId="buyerTitlePremiumAmount" />
                    </>}
                    {results.calcInput.quoteFormat === "Actual" && <>
                        <CalculatorResultDisplay display={results.loanPolicyNameLabel + " Premium"} displayId="buyerTitlePremiumLabel" fieldVal={FormatMoney(results.loanPolicyPremium - results.sellersSplitOfOwnersPremiumAdjustment)} valId="buyerTitlePremiumAmount" />
                    </>}
                    <CalculatorResultDisplay display="Loan Policy Sales Tax" fieldVal={FormatMoney(results.loanPolicyPremiumSalesTax)} />
                    {results.previousPolicyCredit !== null && results.previousPolicyCredit <= 0 && results.calcInput.previousPolicy !== "false" && <>
                        <CalculatorResultDisplay display={`Less Credit For Prior Insurance (${countySettings.previousPolicyQualifierText}) **`} fieldVal={FormatMoney(results.previousPolicyCredit)} />
                        <CalculatorResultDisplay display="Rate After Previous Policy Credit (rounded up)" fieldVal={FormatMoney(results.loanPolicyPremium + results.previousPolicyCredit)} />
                        </>
                    }
                    {results.endAlta906 && <CalculatorResultDisplay display={`ALTA 9-06 (Restrictions)${(results.endAlta906.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta906.fee)} />}
                    {results.endOtiro209_1006 && <CalculatorResultDisplay display={`OTIRO 209.1006, ALTA 9-06 (Restrictions)${(results.endOtiro209_1006.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endOtiro209_1006.fee)} />}
                    {results.endAlta2206 && <CalculatorResultDisplay display={`ALTA 22-06 (Improvements)${(results.endAlta2206.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta2206.fee)} />}
                    {results.endOtiro222_06 && <CalculatorResultDisplay display={`OTIRO 222-06, ALTA 22-06 (Improvements)${(results.endOtiro222_06.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endOtiro222_06.fee)} />}
                    {results.endAlta8106 && <CalculatorResultDisplay display={`ALTA 8.1-06 (Environmental Liens)${(results.endAlta8106.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta8106.fee)} />}
                    {results.endOtiro2081_070121 && <CalculatorResultDisplay display={`OTIRO 208.1 (7-1-21), ALTA 8.1-06 (Environmental Liens)${(results.endOtiro2081_070121.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endOtiro2081_070121.fee)} />}
                    {results.endAlta506 && <CalculatorResultDisplay display={`ALTA 5-06 (PUD)${(results.endAlta506.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta506.fee)} />}
                    {results.endClta1024 && <CalculatorResultDisplay display={`CLTA 102.4 (Foundation)${(results.endClta1024.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endClta1024.fee)} />}
                    {results.endClta1025 && <CalculatorResultDisplay display={`CLTA 102.5 (Foundation)${(results.endClta1025.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endClta1025.fee)} />}
                    {results.endAlta406 && <CalculatorResultDisplay display={`ALTA 4-06 (Condo)${(results.endAlta406.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta406.fee)} />}
                    {results.endAlta606 && <CalculatorResultDisplay display={`ALTA 6-06 (ARM)${(results.endAlta606.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta606.fee)} />}
                    {results.endOtiro206_06 && <CalculatorResultDisplay display={`OTIRO 206-06, ALTA 6-06 (ARM)${(results.endOtiro206_06.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endOtiro206_06.fee)} />}
                    {results.endAlta706 && <CalculatorResultDisplay display={`ALTA 7-06 (Manufactured Housing)${(results.endAlta706.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endAlta706.fee)} />}
                    {results.endOtiro207_06 && <CalculatorResultDisplay display={`OTIRO 207-06, ALTA 7-06 (Manufactured Housing)${(results.endOtiro207_06.fee === 0) ? ' Included' : ''}`} fieldVal={FormatMoney(results.endOtiro207_06.fee)} />}
                    <CalculatorResultDisplay display="Settlement/Closing Fee" fieldVal={FormatMoney(results.buyersEscrowFee) + ((results.isEscrowFeeNegotiable) ? " or negotiable" : "")} />
                    <CalculatorResultDisplay display="Settlement/Closing Fee Sales Tax" fieldVal={FormatMoney(results.buyersEscrowFeeSalesTax)} />
                    <CalculatorResultDisplay display={`Release Service Fee (${FormatMoney(results.releaseServicesFeePerLoan)} per loan paid off)`} fieldVal={FormatMoney(results.buyersReleaseServicesFee)} />
                    <CalculatorResultDisplay display="Closing Protection Letter" fieldVal={FormatMoney(results.closingProtectionLetterFee)} />
                    <CalculatorResultDisplay display="*Courier" fieldVal={FormatMoney(results.buyersCourierFee)} />
                    <CalculatorResultDisplay display="*Wire" fieldVal={FormatMoney(results.wireFee)} />
                    <CalculatorResultDisplay display="3rd Party Service Fee" fieldVal={FormatMoney(results.buyersThirdPartyServiceFee)} />
                    <CalculatorResultDisplay display="3rd Party Service Fee Sales Tax" fieldVal={FormatMoney(results.buyersThirdPartyServiceFeeSalesTax)} />
                    <CalculatorResultDisplay display="eRecording" fieldVal={FormatMoney(results.eRecordingFee)} />
                    <CalculatorResultDisplay display="State Technology Fee" fieldVal={FormatMoney(results.buyersStateTechFee)} />
                    <CalculatorResultDisplay display="*Government Recording Fees" fieldVal={FormatMoney(results.governmentRecordingFee)} />
                    {results.calcInput.transactionType === "Refinance" && <CalculatorResultDisplay display="Government Lien Search" fieldVal={FormatMoney(results.governmentLienSearch)} />}
                    {(results.calcInput.ownersPolicyType === "ALTA Standard Owners") &&
                        <CalculatorResultDisplay display="Extended Owners Premium" fieldVal={FormatMoney(0)} />
                    }
                    {(results.calcInput.ownersPolicyType !== "ALTA Standard Owners") &&
                        <CalculatorResultDisplay display={`${(results.calcInput.ownersPolicyType)} Premium`} fieldVal={FormatMoney(results.extendedOrCoverageOnePortionOfPolicyPremium)} />
                    }
                    <CalculatorResultDisplay display={(results.calcInput.transactionType === "Refinance") ? "Borrower Total" : "Buyer Total"} fieldVal={(results.calcInput.quoteFormat === "Actual") ? FormatMoney(results.buyersTotal - results.sellersSplitOfOwnersPremiumAdjustment) : FormatMoney(results.buyersTotal)} valCss="fw-bold" valId="buyerTotal" />
                </div>
            </div>
            {results.calcInput.transactionType === "Purchase" &&
                <div className="border my-2">
                    <div className="bg-light p-2 h3">Estimated Seller Closing Costs {results.calcInput.quoteFormat === "TRID" && <span className="small">(as shown on closing disclosure)</span>}</div>
                    <div className="">
                        <CalculatorResultDisplay display="State Technology Fee" fieldVal={FormatMoney(results.sellersStateTechFee)} />
                        <CalculatorResultDisplay display="State Excise Tax" fieldVal={FormatMoney(results.stateExciseTax)} />
                        <CalculatorResultDisplay display="Local Excise Tax" fieldVal={FormatMoney(results.cityExciseTax)} />
                        <CalculatorResultDisplay display={`${results.calcInput.ownersPolicyType} Premium`} fieldVal={FormatMoney(results.sellersPayOwnersPremium)} />
                        {results.calcInput.quoteFormat === "TRID" && <>
                            <CalculatorResultDisplay display="Disclosed Owners Premium" fieldVal={FormatMoney(results.sellersSplitOfOwnersPremium)} valId="sellerOwnersPremiumAmount" />
                            <CalculatorResultDisplay display="Actual Owners Premium Adjustment<br />(seller credit to buyer)" fieldVal={FormatMoney(results.sellersSplitOfOwnersPremiumAdjustment)} valId="sellertOwnersPremiumAdjustmentAmount" />
                        </>}
                        {results.calcInput.quoteFormat === "Actual" && <>
                            <CalculatorResultDisplay display="Actual Owners Premium" fieldVal={FormatMoney(results.sellersSplitOfOwnersPremium + results.sellersSplitOfOwnersPremiumAdjustment)} valId="sellerOwnersPremiumAmount" />
                        </>}
                        <CalculatorResultDisplay display={`${results.calcInput.ownersPolicyType} Premium Sales Tax`} fieldVal={FormatMoney(results.sellersPayOwnersPremiumSalesTax)} />
                        <CalculatorResultDisplay display="Settlement/Closing Fee" fieldVal={FormatMoney(results.sellersEscrowFee) + ((results.isEscrowFeeNegotiable) ? " or negotiable" : "")} />
                        <CalculatorResultDisplay display="Settlement/Closing Fee Sales Tax" fieldVal={FormatMoney(results.sellersEscrowFeeSalesTax)} />
                        <CalculatorResultDisplay display={`Release Service Fee (${FormatMoney(results.releaseServicesFeePerLoan)} per loan paid off)`} fieldVal={FormatMoney(results.sellersReleaseServicesFee)} />
                        <CalculatorResultDisplay display="3rd Party Service Fee" fieldVal={FormatMoney(results.sellersThirdPartyServiceFee)} />
                        <CalculatorResultDisplay display="3rd Party Service Fee Sales Tax" fieldVal={FormatMoney(results.sellersThirdPartyServiceFeeSalesTax)} />
                        <CalculatorResultDisplay display="*Courier" fieldVal={FormatMoney(results.sellersCourierFee)} />
                        <CalculatorResultDisplay display="*Wire" fieldVal={FormatMoney(results.sellersWireFee)} />
                        <CalculatorResultDisplay display="Government Lien Search" fieldVal={FormatMoney(results.governmentLienSearch)} />
                        <CalculatorResultDisplay display="Seller Total" fieldVal={FormatMoney(results.sellersTotal)} valCss="fw-bold" valId="sellerTotal" />
                    </div>
                </div>
            }
            <div className="border my-2 p-3 small">
                {countySettings && countySettings.showRatesUsedMessage && <p className="fw-bold">Calculated using Title Resource Group Rates</p>}
                <p className="lh-sm">
                    {results.calcInput.quoteFormat === "TRID" &&
                        <>
                            <strong className="pe-1">Disclaimer:</strong>
                            This breakout of the title premiums may appear different on the loan estimate form and closing disclosure as described in the CFPB final rule.
                            This closing cost quote is good for 60 days and is accurate only based on the information provided. A title examination may reveal items that necessitate additional title insurance fees or charges, based on hazardous circumstances related to the property and/or transaction. Additional fees may occur as a result of any changed circumstance, or a different underwriter may be selected, which could also impact this fee quote. Escrow fees may change based on location of closing. This quote does not include any fees related to two or more loans.
                        </>
                    }
                    {results.calcInput.quoteFormat === "Actual" &&
                        <>
                            <strong>Disclaimer:</strong> This breakout of the title premiums may appear different on the loan estimate form and closing disclosure as the calculations do not follow the CFPB final rule.
                            This closing cost quote is good for 60 days and is accurate only based on the information provided. A title examination may reveal items that necessitate additional title insurance fees or charges, based on hazardous circumstances related to the property and/or transaction. Additional fees may occur as a result of any changed circumstance, or a different underwriter may be selected, which could also impact this fee quote. Escrow fees may change based on location of closing. This quote does not include any fees related to two or more loans.
                        </>
                    }                    
                </p>
                <p className="lh-sm">* Actual recording, courier, and wire fee will be charged at time of closing.</p>
                {results.previousPolicyCredit !== null && results.previousPolicyCredit <= 0 &&
                    <p className="lh-sm">
                        ** Assumes this is a refinance of an existing deed of trust. Example: Home was purchased through bank financing and was not a cash purchase. For other scenarios please <Link to="/locations">contact us</Link>.
                    </p>
                }
            </div>

        </>
    );
}

export default ClosingCostCalculatorResults;