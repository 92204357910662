import React from 'react';
import { Link } from 'react-router-dom';
import { FilePath, FormatPhoneNumber, FormatNameToFilePath } from "../../services/utilities";
import "./team-member-modal-body.scss"

const TeamMemberModalBody = ({ selectedTeamMember, selectedOffice, selectedBrand, pcUser }) => {   
    return (
        <div className="modal-body">
            {selectedTeamMember && <>
                <div className="d-flex flex-column flex-md-row pb-3">
                    <div className="me-3 mb-3 mb-md-0">
                        <img src={FilePath(`/teamimages/${FormatNameToFilePath(selectedTeamMember.firstName + " " + selectedTeamMember.lastName)}.png`)} alt={selectedTeamMember.firstName + " " + selectedTeamMember.lastName + " Image"} className="teammembermodal-image no-localization" width="120" height="120" />
                    </div>
                    <div>
                        <h3 className="mb-2 no-localization">{selectedTeamMember.firstName + " " + selectedTeamMember.lastName}{selectedTeamMember.nameTitle ? ", " + selectedTeamMember.nameTitle : ""}</h3>
                        <div className="lh-sm mb-2">{selectedTeamMember.title}</div>
                        {selectedTeamMember.primaryOffice && <span data-bs-dismiss="modal"><Link className="no-localization" to={"/locations/" + selectedTeamMember.primaryOffice.officeName}>{selectedTeamMember.primaryOffice.officeName}</Link></span>}
                        {selectedTeamMember.email && pcUser && <div><a className="no-localization" href={"mailto:" + selectedTeamMember.email}>{selectedTeamMember.email}</a></div>}
                        {selectedTeamMember.directLine && <div><a href={"tel:" + selectedTeamMember.directLine}>{FormatPhoneNumber(selectedTeamMember.directLine)}</a></div>}
                        {!selectedTeamMember.directLine && selectedTeamMember.primaryOffice && <div className="no-localization"><a href={"tel:" + selectedTeamMember.primaryOffice.officePhone}>{FormatPhoneNumber(selectedTeamMember.primaryOffice.officePhone)}</a></div>}
                    </div>
                </div>

                {selectedTeamMember.text && selectedTeamMember.text.length > 0 && 
                    <>
                        <h3>About <span className="no-localization">{selectedTeamMember.firstName}</span></h3>
                        <ul>
                            {selectedTeamMember.text.split('**').map((item, index) => {
                                return item !== '' ? <li key={"li_" + index}>{item}</li> : '';
                            })}

                        </ul>
                    </>
                }
            </>}
        </div>
    );
}

export default TeamMemberModalBody;