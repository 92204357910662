import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../contexts/brand-context';
import "./blog-preview.scss"
import apiBlog from '../../services/api-blog';
import { EventTrack, DisplayHtml } from "../../services/utilities";

const BlogPreview = () => {
    const postCount = 3;
    const postTag = "HOMEPAGE";
    const { selectedBrand } = useContext(BrandContext);
    const [postList, setPostList] = useState([]);

    useEffect(() => {
        const getBlogPosts = async (blogCategory) => {
            const response = await apiBlog.get(`/posts?fields=ID,title,date,slug,excerpt,format,featured_image&number=${postCount}&category=${blogCategory}&tag=${postTag}`);
            setPostList(response.data.posts);
        }

        if (selectedBrand) {
            getBlogPosts(`Uncategorized,${selectedBrand.brandName} Only`);
        }
    }, [selectedBrand]);

    var createMarkup = (html) => {
        return { __html: html };
    };

    return (
        <div className="blog-preview">
            {postList && postList.length > 0 &&
                <>
                    <h2 className="text-center pt-0 pt-md-3">Expand Your Knowledge</h2>
                    <div className="row row-cols-1 row-cols-lg-3">
                        {postList && postList.map((post, idx) => (
                            <div className="col mb-2" key={idx} >
                                <article className="px-2 px-lg-4 py-4 card border-0">
                                    <h3>{DisplayHtml(post.title)}</h3>
                                    <img src={post.featured_image} alt={`${post.title}`} className="w-100 mb-2" />
                                    <div dangerouslySetInnerHTML={createMarkup(post.excerpt)} />
                                    <Link to={`/blog/${post.slug}`} aria-label={((post.format === "video") ? "Watch Video" : "Read More") + ' - ' + post.title} className="stretched-link" onClick={() => EventTrack(post.title, "Home Blog Preview Clicked", `Position=${idx + 1}`)}>{(post.format === "video") ? "Watch Video" : "Read More"}</Link>
                                </article>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    );
}

export default BlogPreview;