import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"

const BuyerSideTotals = ({data, total, className, saving, setBuyersTotals, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();
    const watchTotalCashNeeded = watch("totalCashNeeded");
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet && loadedNetSheet.buyerNotes) {
            setValue('buyerNotes', loadedNetSheet.buyerNotes);
            setAlreadyLoadedNetSheet(true);
        } 
    }, [loadedNetSheet]);

    useEffect(() => { 
        if (!total) return;
        setValue('totalCashNeeded', currency(total, { separator: ',', symbol: '' }).format());
    }, [total])

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        setBuyersTotals(formVals);
    };

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseBuyerSideTotals" aria-expanded="false" aria-controls="collapseBuyerSideCommonData">
                    <div className="col fw-bold">Totals</div>
                    <div className="col text-end">Total: <span className="no-localization">{currency(watchTotalCashNeeded, { separator: ',', symbol: '$' }).format()}</span></div>
                </div>
                <form >
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseBuyerSideTotals">
                        <div className="card-body">
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalCashNeeded">Total Cash Needed to Close:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalCashNeeded" type="text" defaultValue="0.00" readOnly="readonly" id="totalCashNeeded" tabIndex="-1" className="form-control" {...register("totalCashNeeded")}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="buyerNotes">Notes:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <textarea name="buyerNotes" id="buyerNotes" className="form-control" {...register("buyerNotes")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default BuyerSideTotals;