import React, { useContext, useState } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/user-context';
import { useEffect } from 'react';
import apiTools from '../../services/api-tools';
import { EventTrack } from '../../services/utilities';
import { useRef } from 'react';
import HoaSearchResults from './hoa-search-results';
import CountySelector from '../layout/county-selector';

const HoaSearch = () => {
    const { selectedBrand } = useContext(BrandContext);
    const { loadingUser, pcUser, activeRegion } = useContext(UserContext);
    const [loadingSearchResults, setLoadingSearchResults] = useState(false);
    const [searchText, setSearchText] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);

    const countySelectRef = useRef(null);
    const subdivisionInputRef = useRef(null);

    useEffect(() => {

        if (!loadingUser && pcUser && activeRegion) {
            if (countySelectRef.current) {
                countySelectRef.current.selectedIndex = 0;
            }
            setSearchText(null);
            if (subdivisionInputRef.current) {
                subdivisionInputRef.current.value = '';
            }
            setSearchResults(null);
        }
    }, [loadingUser, pcUser, activeRegion]);

    function doSearch() {
        setSearchResults(null);
        if (searchText === null) {
            setSearchText("");
        }
        if (searchText !== null && searchText !== "") {
            setLoadingSearchResults(true);
            var searchCriteria = {
                subdivision: searchText,
                regionCounties: Array.isArray(selectedCounty) ? selectedCounty.map((rc) => { return { stateAbbr: rc.state, county: rc.countyValue }; }) : [{ stateAbbr: selectedCounty.state, county: selectedCounty.countyValue }]
            };
            getSearchResults(searchCriteria);
        }
        EventTrack("HOA", "Searched", searchText);
    }

    const getSearchResults = async (searchCriteria) => {
        apiTools.post(`/hoa/search`, searchCriteria)
            .then((response) => {
                setSearchResults(response.data);
                setLoadingSearchResults(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>Homeowners Association Contact Search : {selectedBrand.brandName}</title>
            </Helmet>
            <div>
                <h1>Homeowners Association Contact Search</h1>
                {/*{!selectedCounty && <div>Coming soon to your region!</div>}*/}

                <div className="row pb-2">
                    <div className="col-4 form-label fw-bold">
                        <label htmlFor="County">County:</label>
                    </div>
                    <div className="col-8 col-lg-4">
                        <CountySelector regionId={activeRegion.regionId} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} toolName="HOASearch" toolRoute="hoa-search" allOption={true} />
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-4 form-label fw-bold required">
                        <label htmlFor="Subdivision">Subdivision:</label>
                    </div>
                    <div className="col-8 col-lg-4">
                        <input name="Subdivision" type="text" className="form-control no-localization" ref={subdivisionInputRef} onChange={(e) => { setSearchResults(null); setSearchText(e.target.value); }} onKeyPress={(e) => { if (e.key === "Enter") { doSearch(); } }} />
                        {(searchText === "") && <small className="text-danger">This field is required</small>}
                    </div>
                </div>
                <div className="row pb-2">
                    <div className="col-8 col-lg-4 offset-4">
                        <button className="btn btn-primary" onClick={() => doSearch()}>Search</button>
                    </div>
                </div>
                
                <HoaSearchResults loadingSearchResults={loadingSearchResults} searchResults={searchResults} searchCounty={selectedCounty} expandResults={false} trackView={true} />
             </div>
           </>
        );
}

            export default HoaSearch;