import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import { App } from './app';
import { unregister } from './serviceWorkerRegistration';

const rootElement = ReactDOM.createRoot(document.getElementById('root'));
export const msalInstance = new PublicClientApplication(msalConfig);

unregister();

rootElement.render(
    <App pca={msalInstance} />
);
