import React from 'react';
import currency from 'currency.js';
import "../net-sheet.scss"
import { DateTime } from 'luxon';

const SellerSideCommonData = (props) => {

    return (
        <div className={props.className}>  {props.data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideCommonData" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Common Data</div>
                </div>
                <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideCommonData">
                    <div className="accordion-body">
                        <div className="row pt-1">
                            <div className="col-12 col-sm-4 form-label fw-bold">Closing Date:</div>
                            <div className="col-12 col-sm-8">
                                {DateTime.fromISO(props.data.netSheetFormData.closingDate).toLocaleString()}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-sm-4 form-label fw-bold">Sale Price:</div>
                            <div className="col-12 col-sm-8">
                                {currency(props.data.netSheetFormData.purchasePrice, { separator: ',', symbol: '$' }).format()}
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-12 col-sm-4 form-label fw-bold">Annual Taxes:</div>
                            <div className="col-12 col-sm-8">
                                {currency(props.data.netSheetFormData.annualTaxes, { separator: ',', symbol: '$' }).format()}
                                {(props.data.netSheetFormData.propertyCounty.stateAbbr === "MT") && <span className="small ps-2">May be subject to additional city taxes.</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default SellerSideCommonData;