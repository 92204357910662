import React, { useContext, useRef, useState } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import BlogPreview from './blog-preview';
import HomePageCards from './home-cards';
import HomeTextBlock from './home-text-block';
import TitleOneExchangeHome from './brandspecific/titleoneexchange-home';
import "./home.scss"
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { MdOutlineIosShare } from 'react-icons/md'
import { EventTrack, FilePath } from '../../services/utilities';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

const HomePageComponents = {
    titleoneexchange: TitleOneExchangeHome
};

const Home = () => {
    const [showAddToHomeScreenModal, setShowAddToHomeScreenModal] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const { selectedBrand } = useContext(BrandContext);
    const addToHomeScreenModal = useRef(null);
    const addToHomeScreenEnabled = false; 

    const AddToHomeScreen = (e) => {
        if (!deferredPrompt) return;
        deferredPrompt.prompt();
        deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    EventTrack('Add To Home Screen', 'Added to home screen', 'Button');
                } else {
                    document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
                    document.querySelector("body").classList.remove("modal-open");
                    document.querySelector("body").removeAttribute("style");
                }
                setShowAddToHomeScreenModal(false);
                setDeferredPrompt(null);
            });
    }

    useEffect(() => {
        if (!addToHomeScreenEnabled) return;
        if (window.navigator.standalone === true) return;
        if (window.matchMedia('(display-mode: standalone)').matches) return;
        if (document.querySelectorAll('.modal').length > 2) return;//iotw already open
        if (window.location.href.toLowerCase().includes("titleone1031")) return; //nothing for 1031 yet
        if (navigator.userAgent.match(/Android/i)) {
            window.addEventListener('beforeinstallprompt', function (e) {
                e.preventDefault();
                setDeferredPrompt(e);
                setShowAddToHomeScreenModal(true);            
            });
        } else if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) { 
            setShowAddToHomeScreenModal(true);
        }
    }, [selectedBrand]);

    useEffect(() => {
        if (addToHomeScreenModal.current && showAddToHomeScreenModal) {
            var addToHomeScreenModalObj = new bootstrap.Modal(addToHomeScreenModal.current, {backdrop: 'static'});
            addToHomeScreenModalObj.show();
        }
    }, [showAddToHomeScreenModal]);

    const brandHasCustomContentPage = function (selectedBrandName) {
        return (typeof HomePageComponents[selectedBrandName.toLowerCase().replace(/ /g, "")] !== "undefined");
    }

    const BrandSpecificHomePage = function () {
        if (brandHasCustomContentPage(selectedBrand.brandName)) {
            return React.createElement(HomePageComponents[selectedBrand.brandName.toLowerCase().replace(/ /g, "")], { key: selectedBrand.brandId });
        }
    }

    return (
        <>
            <Helmet>
                <title>{selectedBrand.tagline}</title>
                <meta name="description" content={selectedBrand.description} />
                <script type="application/ld+json">{`{"@context" : "https://schema.org", "@type" : "WebSite", "name" : "${selectedBrand.brandName}", "url" : "${selectedBrand.url}"}`}</script>
            </Helmet>
            {brandHasCustomContentPage(selectedBrand.brandName) &&
                <BrandSpecificHomePage />
            }
            {!brandHasCustomContentPage(selectedBrand.brandName) &&
                <div className="home-page-content">
                    <h1 className="visually-hidden">Welcome to {selectedBrand.brandName}</h1>
                    <h2 className="text-center pt-0 pt-md-4">About {selectedBrand.brandName}</h2>
                    <HomeTextBlock />
                    <HomePageCards />
                    <BlogPreview />
                </div>
            }
            {showAddToHomeScreenModal && <div className="modal" tabIndex="-1" id="addToHomeScreenModal" ref={addToHomeScreenModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header pb-1">
                            <span className="a2hs-header">Our Mobile Web App is Ready for You</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-0">
                            <span className="a2hs-body">All the same bells and whistles as our website, plus more!</span>
                            <img className="w-100 pt-4 fade-image" src={FilePath('/images/web-app-phone-popup-01.webp')} alt="Phone web app" ></img>
                        </div>
                        <div className="modal-footer text-center">
                            {(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) ?
                                <span className="m-auto h3">Just tap <MdOutlineIosShare className="ios-blue" /> then 'Add to Home Screen'</span> :
                                <button id="btnAdd" className="btn btn-primary mt-2 m-auto" aria-label="Add to my home screen" onClick={(e) => AddToHomeScreen(e)}>
                                    Add to Home Screen
                                </button>}
                        </div>
                    </div>
                </div>
            </div>}
        </>
  );
}

export default Home;