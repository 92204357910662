import { useEffect } from 'react';
import LinkExternal from '../../layout/link-external';

const ClosingCostCalcEndorsementOption = ({ register, setValue, dirtyFields, labelText, formId, endorsementDefUrl, endorsementConfig, ...rest }) => {

    useEffect(() => {
        if (!dirtyFields || !dirtyFields[formId]) { 
            setValue(formId, endorsementConfig.defaultSelected);
        }
    }, [endorsementConfig, dirtyFields, formId]);

    return (
        <>
            {endorsementConfig.show &&
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id={formId} {...register(formId)} />
                    <label className="form-check-label" htmlFor={formId} >
                        <LinkExternal href={endorsementDefUrl}>{labelText}</LinkExternal>
                    </label>
                </div>
            }
        </>
    );
}

export default ClosingCostCalcEndorsementOption;