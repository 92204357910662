import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import { useMsal } from '@azure/msal-react';
import { UserContext } from '../../contexts/user-context';
import SpinnerLoader from '../layout/spinner-loader';
import { useState } from 'react';
import SignInButton from './sign-in-button';
import { GetSelectedLanguageSubdirectory } from '../../services/utilities';

const PreferredCustomerLogOut = () => {
    const { selectedBrand } = useContext(BrandContext);
    const [ loading, setLoading ] = useState(false);
    const { instance } = useMsal();
    const { loadingUser, pcUser, setPcUser, errorLoadingPcProfile } = useContext(UserContext);

    useEffect(() => {
        if (pcUser || errorLoadingPcProfile) {
            setLoading(true);
            setPcUser(null);
            localStorage.removeItem("pcuser");
            localStorage.removeItem("pcuser.accessToken");
            instance.logoutRedirect({ postLogoutRedirectUri: GetSelectedLanguageSubdirectory() + "/preferred-customer/log-out" })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [loadingUser, pcUser, errorLoadingPcProfile]);

    return (
        <>
            <Helmet>
                <title>{(pcUser || loading) ? "Logging Out" : "Logged Out"} : {selectedBrand.brandName}</title>
            </Helmet>

            <h1>{(pcUser || loading) ? "Logging Out" : "Logged Out"}</h1>

            {(loading || pcUser) && <SpinnerLoader />}
            {(!pcUser && !loading && !errorLoadingPcProfile) && <>
                <p>You have been logged out.</p>
                <p>Ready to <SignInButton cssClasses="btn btn-link nav-link p-0 d-inline align-baseline" /> again?</p>

                <h2>Looking for more?</h2>
                <ul>
                    <li><Link to="/">Home page</Link></li>
                    <li><Link to="/team">Meet the Team</Link></li>
                    <li><Link to="/locations">Contact and Locations</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/careers">Careers</Link></li>
                    <li><Link to="/our-services">List of Services</Link></li>
                </ul>


            </>
            }
        </>
    );
}

export default PreferredCustomerLogOut;