import React, { useState, useEffect } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';
import { DateTime } from 'luxon';

const BuyerSideCommonData = (props) => {
    const { register, setValue, handleSubmit, watch} = useForm();

    /* watchers */
    const watchPropertyInsuranceYearly = watch("propertyInsurancePerYear");
    const watchPropertyInsuranceMonthly = watch("propertyInsurancePerMonth");
    const watchTotalMonthlyPayment = watch("totalMonthlyPayment");

    /* state vars */
    const [changedElement, setChangedElement] = useState(null);
    const [cashDeal, setCashDeal] = useState(false);


    const [monthlyTaxes, setMonthlyTaxes] = useState(0);
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        var propertyInsuranceMonthly = 0;
        if (!alreadyLoadedNetSheet && props.loadedNetSheet) {
            if (currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerYear).value > 0) {
                setValue("propertyInsurancePerYear", currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerYear, { separator: ',', symbol: '' }).format());
                propertyInsuranceMonthly = currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerYear).divide(12).value;
                setValueCustom("propertyInsurancePerMonth", propertyInsuranceMonthly);
                props.setPropertyInsurance(props.loadedNetSheet.buyerCommonData.propertyInsurancePerYear);
            } else if (currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerMonth).value > 0) {
                propertyInsuranceMonthly = props.loadedNetSheet.buyerCommonData.propertyInsurancePerMonth;
                setValue("propertyInsurancePerMonth", currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerMonth, { separator: ',', symbol: '' }).format());
                var propertyInsuranceYearly = currency(props.loadedNetSheet.buyerCommonData.propertyInsurancePerMonth).multiply(12).value;
                setValueCustom("propertyInsurancePerYear", propertyInsuranceYearly);
                props.setPropertyInsurance(propertyInsuranceYearly);
            }
            setAlreadyLoadedNetSheet(true);
        } else if (watchPropertyInsuranceYearly > 0) {
            propertyInsuranceMonthly = currency(watchPropertyInsuranceYearly).divide(12).value;
            if (currency(watchPropertyInsuranceMonthly).value !== currency(propertyInsuranceMonthly).value) setValueCustom("propertyInsurancePerMonth", propertyInsuranceMonthly);
            setValue('totalMonthlyPayment', currency(props.data.monthlyPayment1, { separator: ',', symbol: '' }).add(monthlyTaxes).add(propertyInsuranceMonthly).add(props.data.monthlyPayment2).format());
        }          
        setDefaults(propertyInsuranceMonthly);
    }, [props.loadedNetSheet, props.data]);

    const setDefaults = (propertyInsuranceMonthly) => { 
        if (!props.data) return;
        var monthlyTaxesCalc = currency(props.data.netSheetFormData.annualTaxes).divide(12).value;
        setMonthlyTaxes(monthlyTaxesCalc);
        setCashDeal(props.data.netSheetFormData.loanType === 'Cash');
        setValue('totalMonthlyPayment', currency(props.data.monthlyPayment1, { separator: ',', symbol: '' }).add(monthlyTaxesCalc).add(props.data.monthlyPayment2).add(propertyInsuranceMonthly).format());
    }

    const setValueCustom = (el, val) => { 
        if (changedElement && changedElement.id === el) return;
        setValue(el, currency(val , { separator: ',', symbol: '' }).format());
    }

    useEffect(() => {   
        if (!changedElement) return;
        if (changedElement.id === 'propertyInsurancePerYear') {
            var propertyInsuranceMonthly = currency(watchPropertyInsuranceYearly).divide(12).value;
            if (currency(watchPropertyInsuranceMonthly).value !== currency(propertyInsuranceMonthly).value) setValueCustom("propertyInsurancePerMonth", propertyInsuranceMonthly);
            setValue('totalMonthlyPayment', currency(props.data.monthlyPayment1, { separator: ',', symbol: '' }).add(monthlyTaxes).add(propertyInsuranceMonthly).add(props.data.monthlyPayment2).format());
        }
        else if (changedElement.id === 'propertyInsurancePerMonth') {
                var propertyInsuranceYearly = currency(watchPropertyInsuranceMonthly).multiply(12).value;
                if (currency(watchPropertyInsuranceYearly).value !== currency(propertyInsuranceYearly).value) setValueCustom("propertyInsurancePerYear", propertyInsuranceYearly);
                setValue('totalMonthlyPayment', currency(props.data.monthlyPayment1, { separator: ',', symbol: '' }).add(monthlyTaxes).add(watchPropertyInsuranceMonthly).add(props.data.monthlyPayment2).format());
        }
        props.setPropertyInsurance(watchPropertyInsuranceYearly)
    }, [watchPropertyInsuranceYearly, watchPropertyInsuranceMonthly]);

    useEffect(() => {
        if (props.saving) handleSubmit(onSubmit)();
    }, [props.saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        props.setBuyersCommonData(formVals);
    };

    return (
        <div className={props.className}>  {props.data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseBuyerSideCommonData" aria-expanded="false" aria-controls="collapseBuyerSideCommonData">
                    <div className="col fw-bold">Common Data</div>
                    <div className={cashDeal ? "d-none" : "col text-end"}>Total: {currency(watchTotalMonthlyPayment, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onChange={e => setChangedElement(e.target)} onBlur={e => formatInput(e)} onFocus={(e) => clearZero(e)}>

                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseBuyerSideCommonData">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">Closing Date:</div>
                                <div className="col-12 col-sm-8">
                                    {DateTime.fromISO(props.data.netSheetFormData.closingDate).toLocaleString()}
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">Purchase Price:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.netSheetFormData.purchasePrice, { separator: ',', symbol: '$' }).format()}
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">Buyer's Down Payment:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.netSheetFormData.buyersDownPayment, { separator: ',', symbol: '$' }).format()}
                                </div>
                            </div>

                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">Loan Information:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {props.data.netSheetFormData.loanType === 'EightyTwenty' ? '' : props.data.netSheetFormData.loanType}{!cashDeal && props.data.netSheetFormData.loanType !== 'EightyTwenty' && <>, </>}{props.data.netSheetFormData.loanType !== 'Cash' && <>{currency(props.data.netSheetFormData.buyersLoanAmount1, { separator: ',', symbol: '$' }).format()} {props.data.netSheetFormData.interestRate1}% ({props.data.netSheetFormData.term1} months)</>}
                                </div>
                            </div>

                            <div className={!cashDeal ? "row pt-1" : "d-none"}>
                                <div className="col-12 col-sm-4 form-label fw-bold">Principal & Interest per Month:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.monthlyPayment1, { separator: ',', symbol: '$' }).format()}
                                </div>
                            </div>

                            <div className={props.data.netSheetFormData.loanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                                <div className="col-12 col-sm-4 form-label fw-bold">2nd Loan Information:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.netSheetFormData.buyersLoanAmount2, { separator: ',', symbol: '$' }).format()} {props.data.netSheetFormData.interestRate2}% ({props.data.netSheetFormData.term2} months)
                                </div>
                            </div>

                            <div className={props.data.netSheetFormData.loanType === 'EightyTwenty' ? "row pt-1" : "d-none"}>
                                <div className="col-12 col-sm-4 form-label fw-bold">2nd Loan Principal & Interest per Month:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.monthlyPayment2, { separator: ',', symbol: '$' }).format()}
                                </div>
                            </div>

                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">Annual Taxes:</div>
                                <div className="col-12 col-sm-8 no-localization">
                                    {currency(props.data.netSheetFormData.annualTaxes, { separator: ',', symbol: '$' }).format()}
                                    {(props.data.netSheetFormData.propertyCounty.stateAbbr === "MT") && <span className="small ps-2">May be subject to additional city taxes.</span> }
                                </div>
                            </div>

                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="propertyInsurancePerYear">Property Insurance:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="propertyInsurancePerYear" type="text" defaultValue="0.00" maxLength="8" id="propertyInsurancePerYear" className="form-control clear-zero currency" {...register("propertyInsurancePerYear")} />
                                        <span className="input-group-text">(yr) =</span>
                                    </div>
                                    <div className="input-group pt-1">
                                        <span className="input-group-text">$</span>
                                        <input name="propertyInsurancePerMonth" type="text" defaultValue="0.00" maxLength="8" id="propertyInsurancePerMonth" className="form-control clear-zero currency" {...register("propertyInsurancePerMonth")} />
                                        <span className="input-group-text">(mth)</span>
                                    </div>
                                </div>
                            </div>

                            <div className={!cashDeal ? "row pt-1" : "d-none"}>
                                <div className="group-footer col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="totalMonthlyPayment">Total Monthly Payment:</label>
                                </div>
                                <div className="col-12 col-sm-8 align-top">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalMonthlyPayment" type="text" readOnly="readonly" id="totalMonthlyPayment" tabIndex="-1" className="form-control" {...register("totalMonthlyPayment")} />
                                    </div>
                                    <small>Does not include mortgage insurance.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default BuyerSideCommonData;