import { createContext, useEffect, useState } from 'react';
import apiPublic from '../services/api-public';
import isEqual from 'lodash/isEqual';
const BrandContext = createContext();

const BrandProvider = ({ children }) => {

    const [brands, setBrands] = useState(null);
    const [brandTools, setBrandTools] = useState(null);
    const [selectedBrand, setSelectedBrand] = useState(JSON.parse(localStorage.getItem("selectedBrand")));
    const [loadingBrands, setLoadingBrands] = useState(true);

    useEffect(() => {
        const getBrands = async () => {
            setLoadingBrands(true);
            await apiPublic.get('/brands', { noErrorHandling: true })
                .then((response) => {
                    setBrands(response.data);
                })
                .catch((error) => {
                    console.log("Something went wrong", error);
                })
        }

        getBrands();
    }, []);

    useEffect(() => {
        if (!loadingBrands && selectedBrand) {
            localStorage.setItem("selectedBrand", JSON.stringify(selectedBrand));
        }
    }, [loadingBrands, selectedBrand])

    const getBrandByURL = function (allBrands) {
        var matchingBrand = allBrands.find(b => b.url.replaceAll(' ', '').split(';').some(u => u === window.location.origin));
        return matchingBrand;
    }

    const getDefaultBrand = function (allBrands) {
        return allBrands[0];
    }

    useEffect(() => {
        if (!brands) return;
        var brandByUrl = getBrandByURL(brands);
        if (brandByUrl && (!selectedBrand || (selectedBrand && brandByUrl.brandId !== selectedBrand.brandId))) {
            setSelectedBrand(brandByUrl);
            setLoadingBrands(false);
            return;
        }

        if (selectedBrand) {
            var matchingBrand = brands.find(b => b.brandId === selectedBrand.brandId);
            if (!isEqual(matchingBrand, selectedBrand)) {
                setSelectedBrand(matchingBrand);
            }
        }
        else {
            setSelectedBrand(getDefaultBrand(brands));
        }
        setLoadingBrands(false);
    }, [brands, selectedBrand]);

    useEffect(() => {
        const getBrandTools = async () => {
            apiPublic.get(`/featured-tools/${selectedBrand.brandId}`, { noErrorHandling: true })
                .then((response) => {
                    setBrandTools(response.data);
                })
                .catch((err) => {
                    console.log("Something went wrong", err);
                });
        }
        if (selectedBrand) {
            getBrandTools();
        }
    }, [selectedBrand]);

    return (
        <BrandContext.Provider value={{ loadingBrands, brands: brands, selectedBrand: selectedBrand, setSelectedBrand, brandTools }}>
            {children}
        </BrandContext.Provider>
    )
}

export { BrandContext, BrandProvider }