import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { BrandContext } from '../../contexts/brand-context';
import { FormatPhoneInput } from '../../services/utilities';
import apiPublic from '../../services/api-public';
import { DateTime } from 'luxon';
import { useEffect } from 'react';
import SpinnerLoader from '../layout/spinner-loader';

const QuickProfileRequest = ({ pcUser, activeRegion, selectedPropertyP1Data, subdivisionSearchData, logActivity }) => {
    const { selectedBrand } = useContext(BrandContext);
    const { register, handleSubmit, getValues, setValue, watch, trigger, formState: { errors } } = useForm();
    const [requestSubmitting, setRequestSubmitting] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [phoneInputValue, setPhoneInputValue] = useState("");
    const [fulfillmentDateTime, setFulfillmentDateTime] = useState(null);
    const watchRequestType = watch("requestType");
    const watchRequestItemCCRs = watch("requestCCRs");
    const watchRequestItemDeeds = watch("requestDeeds");
    const watchRequestItemMaps = watch("requestMaps");
    const watchRequestItemTaxes = watch("requestTaxes");

    const handlePhoneInput = (e) => {
        const formattedPhoneNumber = FormatPhoneInput(e.target.value);
        setPhoneInputValue(formattedPhoneNumber);
    };

    useEffect(() => {
        if (!pcUser || !activeRegion) return;
        if ((!selectedPropertyP1Data || !selectedPropertyP1Data.parcelId) && (!subdivisionSearchData)) return;

        setRequestSubmitting(false);
        setRequestError(null);
        setRequestSubmitted(false);
        if (!subdivisionSearchData) {
            setValue("requestType", "Listing Package/Property Profile");
        }
        else {
            setValue("requestType", "Specific Items");
        }
        setFulfillmentDateTime(getMinimumTurnAroundTime());
        setPhoneInputValue(pcUser.mobilePhone);
    }, [selectedPropertyP1Data, subdivisionSearchData, pcUser, activeRegion]);

    useEffect(() => {
        if (watchRequestType === "Listing Package/Property Profile") {
            setValue("requestCCRs", "CCRs");
            setValue("requestDeeds", "Deeds");
            setValue("requestMaps", "Maps");
            setValue("requestTaxes", "Taxes");
        }
        else {
            setValue("requestCCRs", false);
            setValue("requestDeeds", false);
            setValue("requestMaps", false);
            setValue("requestTaxes", false);
        }
    }, [watchRequestType]);

    const atLeastOneItemChecked = () => {
        var valid = !(
            getValues("requestCCRs") === false && 
            getValues("requestDeeds") === false &&
            getValues("requestMaps") === false &&
            getValues("requestTaxes") === false);
        return valid;
    }

    useEffect(() => {
        trigger("requestType");
    }, [watchRequestItemCCRs, watchRequestItemDeeds, watchRequestItemMaps,watchRequestItemTaxes]);
    
    const getRWProductType = () => {
        if (watchRequestType === "Listing Package/Property Profile") return watchRequestType;
        else {
            var itemsRequested = [];
            if (watchRequestItemTaxes) itemsRequested.push("Taxes");
            if (watchRequestItemCCRs) itemsRequested.push("CC&Rs");
            if (watchRequestItemDeeds) itemsRequested.push("Deeds");
            if (watchRequestItemMaps) itemsRequested.push("Maps");

            if (itemsRequested.length === 1 && watchRequestItemCCRs) return "CC&Rs Only";
            if (itemsRequested.length === 1 && watchRequestItemDeeds) return "Deeds";
            else return "Customer Service";
        }
    }

    const getRWProductTypeNotes = () => {
        if (watchRequestType !== "Listing Package/Property Profile") {
            var itemsRequested = [];
            if (watchRequestItemTaxes) itemsRequested.push("Taxes");
            if (watchRequestItemCCRs) itemsRequested.push("CC&Rs");
            if (watchRequestItemDeeds) itemsRequested.push("Deeds");
            if (watchRequestItemMaps) itemsRequested.push("Maps");

            if (itemsRequested.length > 1) {
                itemsRequested = itemsRequested.join(" and ");
                return itemsRequested;
            }
        }
        return "";
    }

    const getDummyZip = async (state, county) => {
        return apiPublic.post(`/dummy-data`, { state: state, county: county })
            .then((response) => {
                return response.data;
            });
    }

    const generateCSOrderRequestObject = async (formData) => {
        var csWebOrderReq = {
            name: pcUser.firstName + ' ' + pcUser.lastName,
            company: pcUser.businessName,
            customerAddress: pcUser.mailingAddress,
            customerZip: pcUser.mailingZip,
            customerCity: pcUser.mailingCity,
            customerState: pcUser.mailingState,
            customerPhone: formData.phone,
            customerEmail: pcUser.email,
            product: getRWProductType(),
            productNotes: getRWProductTypeNotes(),
            neededByDate: fulfillmentDateTime.toISO(),
            neededByTime: fulfillmentDateTime.toISO(),
            deliverMethod: "Email",
            notes: formData.notes,
            fileUpload: "",
            brand: selectedBrand
        }
        if (selectedPropertyP1Data) {
            csWebOrderReq = {
                ...csWebOrderReq,
                propertyAddressStreetNumber: selectedPropertyP1Data.siteHouseNumber,
                propertyAddressStreetName: selectedPropertyP1Data.siteStreetName,
                propertyAddressDirection: selectedPropertyP1Data.siteDirectionSuffix,
                propertyAddressUnit: selectedPropertyP1Data.siteUnit,
                propertyAddressZip: selectedPropertyP1Data.siteZip,
                propertyAddressCity: selectedPropertyP1Data.siteCity,
                propertyAddressState: selectedPropertyP1Data.siteState,
                propertyAddressCounty: selectedPropertyP1Data.county,
                subdivision: selectedPropertyP1Data.subdivision,
                legalDesc: selectedPropertyP1Data.legalDescription,
                parcelId: selectedPropertyP1Data.parcelId,
                propertyType: selectedPropertyP1Data.propertyType
            };

            if (selectedPropertyP1Data.owner && selectedPropertyP1Data.owner.split(', ').length > 1) {
                csWebOrderReq.propertyOwnerFirstName = selectedPropertyP1Data.owner.split(', ')[1];
                csWebOrderReq.propertyOwnerLastName = selectedPropertyP1Data.owner.split(', ')[0];
            } else {
                csWebOrderReq.propertyOwnerFirstName = selectedPropertyP1Data.owner;
            }

            if (csWebOrderReq.propertyAddressCity === null) {
                var zip = csWebOrderReq.propertyAddressZip;
                if (zip === null) {
                    zip = await getDummyZip(csWebOrderReq.propertyAddressState, csWebOrderReq.propertyAddressCounty)
                        .then((zip) => { return zip; });
                }

                var propZipInfo = await apiPublic.get(`/zipcodeinfo/${zip}`)
                    .then((response) => {
                        return response.data;
                    });
                csWebOrderReq.propertyAddressZip = zip;
                csWebOrderReq.propertyAddressCity = propZipInfo.city;
            }
            return csWebOrderReq;
        }
        else if (subdivisionSearchData) {
            return getDummyZip(subdivisionSearchData.state, subdivisionSearchData.county)
                .then(async (zip) => {
                    var zipInfo = await apiPublic.get(`/zipcodeinfo/${zip}`)
                        .then((response) => {
                            return response.data;
                        });
                    csWebOrderReq = {
                        ...csWebOrderReq,
                        propertyAddressCity: zipInfo.city,
                        propertyAddressZip: zip,
                        propertyAddressState: subdivisionSearchData.state,
                        propertyAddressCounty: subdivisionSearchData.county,
                        subdivision: subdivisionSearchData.subdivision,
                    };
                    return csWebOrderReq;
                });
        }
    };



    const onSubmit = data => {
        const submitOrder = async () => {
            generateCSOrderRequestObject(data)
                .then((orderObj) => {
                    apiPublic.post(`/web-order`, orderObj, { noErrorHandling: true })
                        .then((response) => {
                            setRequestSubmitted(true);
                        })
                        .catch((e) => {
                            setRequestError(e);
                        })
                        .finally(() => {
                            setRequestSubmitting(false);
                            if (selectedPropertyP1Data) scrollTo("requestProfileHeading");
                        });
                });
        };

        setRequestSubmitting(true);
        setRequestError(null);
        submitOrder();
    }

    const getMinimumTurnAroundTime = () => {
        var zone = "America/Boise";
        if (activeRegion.regionName === "Eastern Washington" || activeRegion.regionName === "North Idaho" || activeRegion.regionName === "Oregon") zone = "America/Los_Angeles";

        var dt = DateTime.now().setZone(zone).setLocale("en-US").plus({ hours: 1 }).startOf("hour");
        dt = dt.plus({ hours: 2 });

        var dtEOB = dt.startOf("day").plus({ hours: 17 });
        if (dt >= dtEOB) {
            dt = dt.plus({ days: 1 }).startOf("day").plus({ hours: 8, minutes: 30 });
        }

        var dayOfWeek = dt.toFormat("cccc");
        if (dayOfWeek === "Saturday") {
            dt = dt.startOf('day').plus({ days: 2 }).plus({ hours: 8, minutes: 30 });
        } else if (dayOfWeek === "Sunday") {
            dt = dt.startOf('day').plus({ days: 1 }).plus({ hours: 8, minutes: 30 });
        }
        return dt;
    }

    const scrollTo = (id) => {
        const anchor = document.querySelector('#' + id)
        anchor.scrollIntoView({ behavior: 'smooth' })
    }

    return (<>   
        <h3 id="requestProfileHeading">Request {(!subdivisionSearchData) ?<>a Property Profile</> : <>CC&Rs and Plats</>} From Customer Service</h3>
        {!subdivisionSearchData && <p>Don't feel like downloading or emailing a property profile from PropertyOne?  Don't see all the info you are looking for?  Use the form below to have our Customer Service team send you one.</p>}
        {requestSubmitting && <SpinnerLoader />}
        {requestSubmitted && <p>Thank you, your request has been submitted and you will receive a confirmation email.</p>}
        {requestError && <p className="text-danger">We're sorry, there was a problem submitting your order, please contact a Customer Service Representative at {activeRegion.stateAndCounties[0].phoneNumber} or <a href={"mailto:" + activeRegion.stateAndCounties[0].csEmailAddress}>via email</a>.</p>}
        {!requestSubmitted && !requestSubmitting && pcUser && fulfillmentDateTime && !requestError &&
            <form onSubmit={handleSubmit(onSubmit)}>
                {!subdivisionSearchData && <>
                    {selectedPropertyP1Data.parcelId &&
                        <div className="row pt-1">
                            <div className="col-3 col-sm-4 form-label fw-bold">
                                Parcel ID:
                            </div>
                            <div className="col-9 col-sm-8 no-localization">
                                {selectedPropertyP1Data.parcelId}
                            </div>
                        </div>}
                    {selectedPropertyP1Data.address && <div className="row pt-1">
                        <div className="col-3 col-sm-4 form-label fw-bold">
                            Property Address:
                        </div>
                        <div className="col-9 col-sm-8 no-localization lh-sm pt-1">
                            {selectedPropertyP1Data.siteHouseNumber} {selectedPropertyP1Data.siteStreetName} {selectedPropertyP1Data.siteDirectionSuffix}
                            {selectedPropertyP1Data.siteUnit ? "<br />Unit " + selectedPropertyP1Data.siteUnit : ""}<br />
                            {selectedPropertyP1Data.siteCity}, {selectedPropertyP1Data.siteState} {selectedPropertyP1Data.siteZip}<br />
                            {selectedPropertyP1Data.county} County
                        </div>
                    </div>}
                    {selectedPropertyP1Data.subdivision && <div className="row pt-1">
                        <div className="col-3 col-sm-4 form-label fw-bold">
                            Subdivision:
                        </div>
                        <div className="col-9 col-sm-8 no-localization">
                            {selectedPropertyP1Data.subdivision}
                        </div>
                    </div>}
                    {selectedPropertyP1Data.propertyType && <div className="row pt-1">
                        <div className="col-3 col-sm-4 form-label fw-bold">
                            Property Type:
                        </div>
                        <div className="col-9 col-sm-8 no-localization">
                            {selectedPropertyP1Data.propertyType}
                        </div>
                    </div>}
                    {selectedPropertyP1Data.owner && <div className="row pt-1">
                        <div className="col-3 col-sm-4 form-label fw-bold">
                            Owner:
                        </div>
                        <div className="col-9 col-sm-8 no-localization">
                            {selectedPropertyP1Data.owner}
                        </div>
                    </div>}
                </>}
                {subdivisionSearchData && 
                    <div className="row pt-1">
                        <div className="col-3 col-sm-4 form-label fw-bold">
                            Subdivision:
                        </div>
                        <div className="col-9 col-sm-8 no-localization">
                            {subdivisionSearchData.subdivision}
                        </div>
                    </div>}
                <div className="row pt-1">
                    <div className="col-3 col-sm-4 form-label fw-bold">
                        Your Name:
                    </div>
                    <div className="col-9 col-sm-8 no-localization">
                        {pcUser.firstName + " " + pcUser.lastName}
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3 col-sm-4 form-label fw-bold">
                        Your Email:
                    </div>
                    <div className="col-9 col-sm-8 text-break no-localization">
                        {pcUser.email}
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3 col-sm-4 form-label fw-bold required">
                        <label htmlFor="phone">Phone:</label>
                    </div>
                    <div className="col-9 col-sm-4">
                        <input autoComplete="tel" name="phone" type="tel" id="phone" className="form-control" {...register("phone", { required: true, minLength: 12 })} onChange={(e) => handlePhoneInput(e)} value={phoneInputValue} />
                        {errors?.phone?.type === "required" && <small className="text-danger">This field is required</small>}
                        {errors?.phone?.type === "minLength" && <small className="text-danger">Please enter a valid phone number.</small>}
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3 col-sm-4 form-label fw-bold required">
                        What would you like?
                    </div>
                    <div className="col-9 col-sm-8">
                        <div>
                            {!subdivisionSearchData && <>
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="radio" id="fullPropertyProfile" value="Listing Package/Property Profile" defaultChecked={true}  {...register("requestType", { required: true })} />
                                        Listing Package/Property Profile
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label" htmlFor="specificProfileItems">
                                        <input className="form-check-input" type="radio" id="specificProfileItems" value="Specific Items" {...register("requestType", { required: true })} />
                                        Specific things:
                                    </label>
                                </div>
                            </>}
                            <div className="d-inline-block ms-2 mt-1">
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" id="requestCCRs" value="CCRs" disabled={(watchRequestType === "Listing Package/Property Profile")} {...register("requestCCRs", {validate: {itemsSelectedValid: atLeastOneItemChecked } })} />
                                        CC&Rs
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" id="requestMaps" value="Maps" disabled={(watchRequestType === "Listing Package/Property Profile")} {...register("requestMaps")} />
                                        Maps
                                    </label>
                                </div>
                                {!subdivisionSearchData && <>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" id="requestDeeds" value="Deeds" disabled={(watchRequestType === "Listing Package/Property Profile")} {...register("requestDeeds")} />
                                            Deeds
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" id="requestTaxes" value="Taxes" disabled={(watchRequestType === "Listing Package/Property Profile")} {...register("requestTaxes")} />
                                            Taxes
                                        </label>
                                    </div>
                                </>}
                            </div>
                            {(errors?.requestCCRs?.type === "itemsSelectedValid") && <small className="text-danger">Please select at least one item.</small>}
                        </div>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col offset-3 offset-sm-4 lh-sm">
                        We'll do our best to get it to you by {(fulfillmentDateTime.hasSame(DateTime.now(), "day")) ? "today" : fulfillmentDateTime.toFormat("cccc, M/d")} at {fulfillmentDateTime.toFormat("h:mm a, ZZZZ")}.
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-3 col-sm-4 form-label fw-bold">
                        <label className="lh-sm pt-1" htmlFor="notes" >Notes or Special Requests:</label>
                    </div>
                    <div className="col-9 col-sm-4">
                        <textarea name="notes" rows="2" cols="20" id="notes" className="form-control" {...register("notes")}></textarea>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col offset-3 offset-sm-4 ">
                        <input type="submit" name="submitQuoteRequest" value="Submit" id="submitQuoteRequest" className="btn btn-primary" />
                    </div>
                </div>
            </form>}
    </>
    );     
}

export default QuickProfileRequest;