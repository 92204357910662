import React, { useEffect, Fragment, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './nav-menu.scss';
import { FilePath } from '../../../services/utilities';
import { BrandContext } from '../../../contexts/brand-context';
import NavBrandLinks from './nav-brand-link'
import { useIsAuthenticated } from "@azure/msal-react";
import SignInButton from '../../preferred-customer/sign-in-button';
import LanguageSelection from './language-selection';
import SocialLinks from '../social-links';

const NavMenu = () => {
    const t1NavRef = useRef();
    const { selectedBrand } = useContext(BrandContext);
    const location = useLocation();
    const currentLocation = location.pathname;
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (t1NavRef) {
            t1NavRef.current.classList.remove('show');
        }
    }, [currentLocation]);

    const T1Nav = (props) => {
        const topLevelItemLength = props.brandMenuItems.menuItems.map((i) => i.title).join(' ').length;

        return (
            <ul className={"navbar-nav w-100 d-flex justify-content-between" + ((topLevelItemLength > 80) ? " shrink-on-md" : "")}>
                {props.brandMenuItems.menuItems.map((menuItem, index) => {
                    return <Fragment key={index}>{createNavItem(menuItem, index)}</Fragment>
                })}
                <MobileOnlyNavItems />
            </ul>
        );
    }
    
    const MobileOnlyNavItems = () =>
        <Fragment>
           
            {selectedBrand && selectedBrand.brandId !== 4 && <>
                <li className='nav-item d-lg-none'>
                    <Link to="/tools" className="d-block py-2">TOOLS</Link>
                </li>
                {isAuthenticated ? <>
                    <li className='nav-item d-lg-none'>
                        <Link to="/preferred-customer/profile" className="d-block py-2">PROFILE</Link>
                    </li>
                    <li className='nav-item d-lg-none'>
                        <Link to="/preferred-customer/log-out" className="d-block py-2">LOG OUT</Link>
                    </li>
                </>
                :
                <>
                    <li className='nav-item d-lg-none'>
                        <Link to="/preferred-customer/sign-up" className="d-block py-2">SIGN UP</Link>
                    </li>
                    <li className='nav-item d-lg-none'>
                        <SignInButton cssClasses="btn btn-link m-0 p-0 fw-bold" />
                    </li>
                </>
                }
            </>}
            <li className='nav-item d-lg-none py-2'>
                <LanguageSelection />
            </li>
        </Fragment>

    const createNavItem = (menuItem, index) => {
        if (menuItem.dropdownItems && menuItem.dropdownItems.length > 0) {
            return (
                <li className="nav-item dropdown" key={index}>
                    <button className="btn btn-link nav-link dropdown-toggle fw-bold" id={`navdropdown${index}`} tabIndex="0" data-bs-toggle="dropdown" aria-expanded="false">{menuItem.title}</button>
                    <ul className={ menuItem.dropdownItems.length > 3 ? "dropdown-menu two-column-list" : "dropdown-menu"} aria-labelledby={`navdropdown${index}`}>
                        {menuItem.dropdownItems.map((subMenuItem, subindex) => {
                            if (subMenuItem.route) {
                                return (<T1NavRouteItem key={index + "." + subindex} to={subMenuItem.route}>{subMenuItem.title}</T1NavRouteItem>);
                            }
                            else if (subMenuItem.href && subMenuItem.href.toLowerCase().startsWith('http')) {
                                return (<T1NavLinkItem key={index + "." + subindex} href={subMenuItem.href} target={subMenuItem.target} rel="noreferrer">{subMenuItem.title}</T1NavLinkItem>);
                            }
                            else if (subMenuItem.href) {
                                return (<T1NavLinkItem key={index + "." + subindex} href={subMenuItem.href} target={subMenuItem.target} rel="noreferrer">{subMenuItem.title}</T1NavLinkItem>);
                            }
                            else {
                                return <Fragment key={-1}></Fragment>
                            }
                        })}
                    </ul>
                </li>
            );
        }
        else if (menuItem.route) {
            return (<T1NavRouteItem key={index} to={menuItem.route}>{menuItem.title}</T1NavRouteItem>);
        }
        else if (menuItem.href && menuItem.href.toLowerCase().startsWith('http')) {
            return (<T1NavLinkItem key={index} href={menuItem.href} target={menuItem.target} rel="noreferrer">{menuItem.title}</T1NavLinkItem>);
        }
        else if (menuItem.href) {
            return (<T1NavLinkItem key={index} href={menuItem.href} target={menuItem.target} rel="noreferrer">{menuItem.title}</T1NavLinkItem>);
        }
    }

    const T1NavRouteItem = props => <li className={`nav-item ${(props.className) ? props.className : ''}`}><Link {...props} className="nav-link text-nowrap">{props.children}</Link></li>;
    const T1NavLinkItem = props => <li className={`nav-item ${(props.className) ? props.className : ''}`}><a className="nav-link text-nowrap" {...props}>{props.children}</a></li>;

    return (
        <header>
            <nav aria-label="main navigation menu" id="t1-nav-bar" className="py-1 py-lg-3 navbar navbar-light navbar-expand-lg">
                <div className="container-fluid">
                    {selectedBrand && <Link className="navbar-brand px-1 px-xxl-2 d-print-none" to="/"><img src={FilePath(selectedBrand.logo)} alt={selectedBrand.brandName + " Logo"} /></Link>}
                    {selectedBrand && <div className="navbar-brand px-1 px-xxl-2 d-none d-print-flex"><img src={FilePath(selectedBrand.logo)} alt={selectedBrand.brandName + " Logo"} /></div>}
                    {!selectedBrand && <div className="logoLoading"> </div>}
                    <div>
                        <div className="d-inline d-lg-none d-print-none socialLinks"><SocialLinks brand={selectedBrand} /></div>
                        <button className="navbar-toggler mr-2 d-print-none" type="button" data-bs-toggle="collapse" data-bs-target="#t1-nav-menu" aria-controls="t1-nav-menu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div id="t1-nav-menu" className="collapse navbar-collapse d-lg-inline-flex fw-bold pe-1" ref={t1NavRef}>
                        {selectedBrand && <T1Nav brandMenuItems={selectedBrand && NavBrandLinks.find(bmi => bmi.brandId === selectedBrand.brandId)} />}
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default NavMenu;