import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import currency from 'currency.js';
import "../net-sheet.scss"
import { clearZero, formatInput } from '../utilities';


const SellerSideMortgageInformation = ({ data, setLoansPaidOffCount, setMortgageInformationTotal, className, saving, setSellersMortgageInformation, loadedNetSheet}) => {
    const { register, setValue, watch, handleSubmit } = useForm();

    /* watchers */
    const watchMortgageBalance1Input = watch("firstMortgageBalance");
    const watchMortgageBalance2Input = watch("secondMortgageBalance");
    const watchTotalMortgageDebt= watch("totalMortgageDebt");
    const [alreadyLoadedNetSheet, setAlreadyLoadedNetSheet] = useState(false);

    useEffect(() => {
        if (!alreadyLoadedNetSheet && loadedNetSheet) {
            for (const [key, value] of Object.entries(loadedNetSheet.sellersMortgageInformation)) {
                if (typeof value === 'number') {
                    setValue(key, currency(value, { separator: ',', symbol: '' }).format());
                } else { 
                    setValue(key, value);
                } 
            }
            setAlreadyLoadedNetSheet(true);
        }
    }, [loadedNetSheet]);

    useEffect(() => {
        const sum = currency(watchMortgageBalance1Input, { separator: ',', symbol: '' })
            .add(watchMortgageBalance2Input)
            .format();
        setValue("totalMortgageDebt", sum);
        setMortgageInformationTotal(sum);
        var loansToPayOff = 0;
        if (currency(watchMortgageBalance1Input).value > 0) loansToPayOff++;
        if (currency(watchMortgageBalance2Input).value > 0) loansToPayOff++;   
        setLoansPaidOffCount(loansToPayOff);
    }, [watchMortgageBalance1Input, watchMortgageBalance2Input]);

    useEffect(() => {
        if (saving) handleSubmit(onSubmit)();
    }, [saving]);

    const onSubmit = (formVals) => {
        Object.keys(formVals).forEach(function(key){ if(!formVals[key] || formVals[key] === '') formVals[key] = '0' });
        setSellersMortgageInformation(formVals);
    };

    return (
        <div className={className}> {data &&
            <div className="form-labels-left-md accordion-item">
                <div className="row py-1 group-header m-0 accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSellerSideMortgageInformation" aria-expanded="false" aria-controls="collapseSellerSideCommonData">
                    <div className="col fw-bold">Mortgage Information</div>
                    <div className="col text-end">Total: {currency(watchTotalMortgageDebt, { separator: ',', symbol: '$' }).format()}</div>
                </div>
                <form onBlur={e => formatInput(e)} onFocus={(e) => clearZero(e)}>
                    <div className="accordion-collapse collapse show col-12 mb-2" id="collapseSellerSideMortgageInformation">
                        <div className="accordion-body">
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="firstMortgageBalance">Less Present 1st Mortgage Balance:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="firstMortgageBalance" type="text" defaultValue="0.00" maxLength="8" id="firstMortgageBalance" className="form-control clear-zero currency"  {...register("firstMortgageBalance")}/>
                                    </div>
                                </div>
                            </div>           
                            <div className="row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold">
                                    <label htmlFor="secondMortgageBalance">Less Present 2nd Mortgage Balance:</label>
                                </div>
                                <div className="col-12 col-sm-8">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="secondMortgageBalance" type="text" defaultValue="0.00" maxLength="8" id="secondMortgageBalance" className="form-control clear-zero currency"  {...register("secondMortgageBalance")}/>
                                    </div>
                                </div>
                            </div>         
                            <div className="group-footer row pt-1">
                                <div className="col-12 col-sm-4 form-label fw-bold text-danger">
                                    <label htmlFor="totalMortgageDebt">Total Mortgage Debt:</label>
                                </div>
                                <div className="col-12 col-sm-7">
                                    <div className="input-group">
                                        <span className="input-group-text">$</span>
                                        <input name="totalMortgageDebt" type="text" defaultValue="0.00"  readOnly="readonly" id="totalMortgageDebt" tabIndex="-1" className="form-control" {...register("totalMortgageDebt")}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    );
}

export default SellerSideMortgageInformation;