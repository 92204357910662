import axios from 'axios';

const apiBlog = axios.create({
  baseURL: process.env.REACT_APP_API_BLOG
});

apiBlog.interceptors.response.use(
    response => {
        // Return a successful response back to the calling service
        return response;
    },
    error => {
        console.log('error: ', error.response);
        return new Promise((resolve, reject) => {
            reject(error.response);
        });
    }
);

export default apiBlog;