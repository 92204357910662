import React from 'react';


const SpinnerLoader = ({ style, containerClassesOverride }) => {
    return (
        <div className={(!containerClassesOverride) ? "d-flex justify-content-around my-5" : containerClassesOverride}>
            <div className="spinner-border text-primary" style={{ ...style, height: ((style && style.height) || '125px'), width: ((style && style.width) || '125px') }} role="status">
				<span className="visually-hidden">Loading...</span>
            </div>
        </div> 
    );
}

export default SpinnerLoader;