import React, { useEffect, useState } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserProvider } from './contexts/user-context';

import { MsalProvider } from '@azure/msal-react';

import Layout from './components/layout/layout';
import QuickToolsNav from './components/layout/quick-tools-nav';
import ScrollToTop from './components/scroll-to-top';
import './t1bootstrap.scss'
import './app.scss'

import ReactGA from "react-ga4";

import Home from './components/home/home';
import Locations from './components/about/locations';
import Team from './components/about/team';
import PreferredCustomerAuth from './components/preferred-customer/auth';
import RequireAuth from './services/require-auth';

import PropertyOne from './components/propertyone/propertyone';

import CcrAndPlatSearch from './components/real-estate-pros/ccr-and-plat-search';
import ClosingCostCalculator from './components/real-estate-pros/closing-cost-calc/closing-cost-calc';
import HoaSearch from './components/real-estate-pros/hoa-search';
import NetSheet from './components/real-estate-pros/net-sheet/net-sheet';
import SavedNetSheets from './components/real-estate-pros/saved-net-sheets';
import Tools from './components/real-estate-pros/tools';

import HomePortfolioForm from './components/order/home-portfolio-form';
import PropertyProfile from './components/order/property-profile';
import PreferredCustomerLogOut from './components/preferred-customer/log-out';
import PreferredCustomerAccountRequired from './components/preferred-customer/account-required';
import { lazyLoadWithRetry } from './services/lazy-load-with-retry';

const Codebook = lazyLoadWithRetry(() => import('./components/codebook/codebook'));
const DigitalHomePortfolio = lazyLoadWithRetry(() => import('./components/digital-home-portfolio/digital-home-portfolio'));

const PreferredCustomerProfile = lazyLoadWithRetry(() => import('./components/preferred-customer/profile'));
const PreferredCustomerFeaturePayment = lazyLoadWithRetry(() => import('./components/preferred-customer/feature-payment'));
const PreferredCustomerSignUp = lazyLoadWithRetry(() => import('./components/preferred-customer/sign-up'));
const PreferredCustomerOptOut = lazyLoadWithRetry(() => import('./components/preferred-customer/opt-out'));
const PreferredCustomerRequestReactivation = lazyLoadWithRetry(() => import('./components/preferred-customer/request-reactivation'));
const PreferredCustomerResetPassword = lazyLoadWithRetry(() => import('./components/preferred-customer/reset-password'));
const PreferredCustomerForgotPassword = lazyLoadWithRetry(() => import('./components/preferred-customer/forgot-password'));
const PreferredCustomerSwitchBrand = lazyLoadWithRetry(() => import('./components/preferred-customer/switch-brand'));
const RequirePayment = lazyLoadWithRetry(() => import('./services/require-payment'));

const AboutUs = lazyLoadWithRetry(() => import('./components/about/about-us'));
const Blog= lazyLoadWithRetry(() => import('./components/about/blog'));
const Careers = lazyLoadWithRetry(() => import('./components/about/careers'));
const ClientRelationshipForm = lazyLoadWithRetry(() => import('./components/about/client-relationship-form'));
const CommunitySupport = lazyLoadWithRetry(() => import('./components/about/community-support'));
const CompanyDirectory = lazyLoadWithRetry(() => import('./components/about/company-directory'));
const Counties = lazyLoadWithRetry(() => import('./components/about/counties'));
const GolfTournaments= lazyLoadWithRetry(() => import('./components/about/golf'));
const NewsArticles= lazyLoadWithRetry(() => import('./components/about/news-articles'));
const PrivacyPolicy= lazyLoadWithRetry(() => import('./components/about/privacy-policy'));
const TermsAndConditions= lazyLoadWithRetry(() => import('./components/about/terms-and-conditions'));

const BuyerSellerRoadmap = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/roadmap'));
const BuyerSellerVideoLibrary = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/video-library'));
const ClosingChecklist = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/closing-checklist'));
const HelpfulLinks = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/helpful-links'));
const HomeAffordabilityCalc = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/home-affordability-calc'));
const HomeownerExemptions = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/homeowner-exemptions'));
const MonthlyPaymentCalc = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/monthly-payment-calc'));
const MovingChecklist = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/moving-checklist'));
const WhatsEscrow = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/whats-escrow'));
const WhatsTitleInsurance = lazyLoadWithRetry(() => import('./components/buyers-and-sellers/whats-title-insurance'));

const TitleAndEscrowOrderForm = lazyLoadWithRetry(() => import('./components/order/title-and-escrow'));
const Payments = lazyLoadWithRetry(() => import('./components/order/payments'));

const AboutPropertyOne = lazyLoadWithRetry(() => import('./components/products-and-services/about-propertyone'));
const CEClasses = lazyLoadWithRetry(() => import('./components/products-and-services/classes/classes'));
const CommercialServices = lazyLoadWithRetry(() => import('./components/products-and-services/commercial-services'));
const Earnnest = lazyLoadWithRetry(() => import('./components/products-and-services/earnnest'));
const ForeclosureSales = lazyLoadWithRetry(() => import('./components/products-and-services/foreclosure-sales'));
const HomePortfolio = lazyLoadWithRetry(() => import('./components/products-and-services/home-portfolio'));
const DuraFlyer = lazyLoadWithRetry(() => import('./components/products-and-services/dura-flyer'));
const LongTermEscrow = lazyLoadWithRetry(() => import('./components/products-and-services/long-term-escrow'));
const OneConnect = lazyLoadWithRetry(() => import('./components/products-and-services/oneconnect'));
const OurServices = lazyLoadWithRetry(() => import('./components/products-and-services/our-services'));
const PrintingResources = lazyLoadWithRetry(() => import('./components/products-and-services/printing-resources'));
const SpanishResources = lazyLoadWithRetry(() => import('./components/products-and-services/spanish-resources'));
const TrustServices = lazyLoadWithRetry(() => import('./components/products-and-services/trust-services'));
const Underwriters = lazyLoadWithRetry(() => import('./components/products-and-services/underwriters'));

const RepsOfTheYear = lazyLoadWithRetry(() => import('./components/internal/reps-of-the-year'));
const SalesToolkit = lazyLoadWithRetry(() => import('./components/internal/sales-toolkit'));
const SentryStatus = lazyLoadWithRetry(() => import('./components/internal/sentry-status'));
const Sitemap = lazyLoadWithRetry(() => import('./components/layout/sitemap'));

const ElectronicForms = lazyLoadWithRetry(() => import('./components/real-estate-pros/electronic-forms'));
const Endorsements = lazyLoadWithRetry(() => import('./components/real-estate-pros/endorsements'));
const ForeclosureSearch = lazyLoadWithRetry(() => import('./components/real-estate-pros/foreclosure-search'));
const OneMap = lazyLoadWithRetry(() => import('./components/real-estate-pros/onemap/onemap'));
const OneMapEmailLink = lazyLoadWithRetry(() => import('./components/real-estate-pros/onemap/onemap-email-link'));
const ReverseNetSheet = lazyLoadWithRetry(() => import('./components/real-estate-pros/reverse-net-sheet/reverse-net-sheet'));
const SavedClosingCostQuotes = lazyLoadWithRetry(() => import('./components/real-estate-pros/closing-cost-calc/saved-quotes'));
const TargetedMarketing = lazyLoadWithRetry(() => import('./components/real-estate-pros/targeted-marketing/targeted-marketing'));
const TaxCalculator = lazyLoadWithRetry(() => import('./components/real-estate-pros/tax-calculator'));
const TitleGlossary = lazyLoadWithRetry(() => import('./components/real-estate-pros/title-glossary'));
const WireFraud= lazyLoadWithRetry(() => import('./components/real-estate-pros/wire-fraud'));

const TitleOneExchangeContactUs = lazyLoadWithRetry(() => import('./components/titleone-exchange/contact-us'));
const TitleOneExchangeBasics = lazyLoadWithRetry(() => import('./components/titleone-exchange/basics'));
const TitleOneExchangeOrder = lazyLoadWithRetry(() => import('./components/titleone-exchange/order'));
const TitleOneExchangeRegulatoryCompliance = lazyLoadWithRetry(() => import('./components/titleone-exchange/regulatory-compliance'));
const TitleOneExchangeTestimonials = lazyLoadWithRetry(() => import('./components/titleone-exchange/testimonials'));
const TitleOneExchangeTools = lazyLoadWithRetry(() => import('./components/titleone-exchange/tools'));
const TitleOneEconomicForum = lazyLoadWithRetry(() => import('./components/titleone-exchange/economic-forum'));


const Oops = lazyLoadWithRetry(() => import('./components/oops'));
const PageNotFound = lazyLoadWithRetry(() => import('./components/page-not-found'));

const App = ({ pca }) => {
    var manifest = document.getElementById('manifestPlaceholder');
    const GAURL = window.location.href.toLowerCase();
    if (GAURL.includes("titleonecorp")) {
        ReactGA.initialize(process.env.REACT_APP_GACODET1);
        manifest.setAttribute('href', 't1.webmanifest')
    } else if (GAURL.includes("sunvalleytitle")) {
        ReactGA.initialize(process.env.REACT_APP_GACODESVT);
        manifest.setAttribute('href', 'svt.webmanifest')
    } else if (GAURL.includes("titleone1031")) {
        ReactGA.initialize(process.env.REACT_APP_GACODEEX);
        manifest.setAttribute('href', '1031.webmanifest')
    } else {
        ReactGA.initialize(process.env.REACT_APP_GACODETEST);
        manifest.setAttribute('href', 't1.webmanifest')
    }

    ReactGA.send("pageview");

    const [selectedLanguageSubdirectory, setSelectedLanguageSubdirectory] = useState('');

    useEffect(() => {
        window.onpopstate = e => {
            document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
            document.querySelector("body").classList.remove("modal-open");
            document.querySelector("body").removeAttribute("style");
        };

        const parsedPath = window.location.pathname.split("/");
        let langCodeFromUrl = parsedPath[1];
        window.Localize.getAvailableLanguages(function (err, languages) {
            if (err) return console.log(err);
            if (!languages.find(l => l.code === langCodeFromUrl)) {
                window.Localize.setLanguage('en');
                setSelectedLanguageSubdirectory('');
            }
            else {
                setSelectedLanguageSubdirectory(langCodeFromUrl);
            }
        });

    }, []);
    
    return (
        <MsalProvider instance={pca}>
            <BrowserRouter basename={'/' + selectedLanguageSubdirectory}>
                <UserProvider>
                    <ScrollToTop>
                        <Routes>
                            <Route path='/' element={<Layout />}>
                                <Route path="*" element={<QuickToolsNav><PageNotFound /></QuickToolsNav>} />
                                <Route path="/oops" element={<Oops />} />

                                <Route index element={<Home />} />

                                <Route path='/about-us' element={<QuickToolsNav><AboutUs /></QuickToolsNav>} />
                                <Route path='/blog'>
                                    <Route path='' element={<QuickToolsNav><Blog /></QuickToolsNav>} />
                                    <Route path=':slug' element={<QuickToolsNav><Blog /></QuickToolsNav>} />
                                </Route>
                                <Route path='/careers' element={<QuickToolsNav><Careers /></QuickToolsNav>} />
                                <Route path='/codebook' element={<Codebook />} />

                                <Route path='/commercial-services' element={<QuickToolsNav><CommercialServices /></QuickToolsNav>} />
                                <Route path='/client-relationship-form' element={<QuickToolsNav><ClientRelationshipForm /></QuickToolsNav>} />
                                <Route path='/community-support' element={<QuickToolsNav><CommunitySupport /></QuickToolsNav>} />
                                <Route path='/company-directory' element={<QuickToolsNav><CompanyDirectory /></QuickToolsNav>} />
                                <Route path='/counties' element={<QuickToolsNav><Counties /></QuickToolsNav>} />
                                <Route path='/digital-home-portfolio'>
                                    <Route path=':paramDigitalHomePortfolioId' element={<DigitalHomePortfolio />} />
                                </Route>
                                <Route path='/golf' element={<QuickToolsNav><GolfTournaments region={1} /></QuickToolsNav>} />
                                <Route path='/golf/mv' element={<QuickToolsNav><GolfTournaments region={4} /></QuickToolsNav>} />
                                <Route path='/locations'>
                                    <Route path='' element={<Locations />} />
                                    <Route path=':paramLocation' element={<Locations />} />
                                </Route>
                                <Route path='/news-articles' element={<QuickToolsNav><NewsArticles archived={false} /></QuickToolsNav>} />
                                <Route path='/news-articles/archive' element={<QuickToolsNav><NewsArticles archived={true} /></QuickToolsNav>} />
                                <Route path='/printing-resources' element={<QuickToolsNav><PrintingResources /></QuickToolsNav>} />
                                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                                <Route path='/spanish-resources' element={<QuickToolsNav><SpanishResources /></QuickToolsNav>} />
                                <Route path='/team'>
                                    <Route path='' element={<Team />} />
                                    <Route path='/team/location/:paramLocation' element={<Team />} />
                                    <Route path='/team/department/:paramDepartment' element={<Team />} />
                                    <Route path='/team/name/:paramName' element={<Team />} />
                                </Route>
                                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />

                                <Route path='/sitemap' element={<QuickToolsNav><Sitemap /></QuickToolsNav>} />

                                <Route path='/reps-of-the-year' element={<RepsOfTheYear />} />
                                <Route path='/sentry-status' element={<RequireAuth><SentryStatus /></RequireAuth>} />
                                <Route path='/sales-toolkit' element={<RequireAuth><SalesToolkit /></RequireAuth>} />

                                <Route path='/about-propertyone' element={<QuickToolsNav><AboutPropertyOne /></QuickToolsNav>} />
                                <Route path='/classes' element={<CEClasses />}>
                                    <Route path='/classes/cancel/:paramRegGuid' element={<CEClasses />} />
                                    <Route path='/classes/:paramClassId' element={<CEClasses />} />
                                    <Route path='/classes/region/:paramRegionName' element={<CEClasses />} />
                                </Route>
                                <Route path='/productsandservices/continuinged_cancel.aspx' element={<CEClasses />} />
                                <Route path='/earnest' element={<QuickToolsNav><Earnnest /></QuickToolsNav>} />
                                <Route path='/earnnest' element={<QuickToolsNav><Earnnest /></QuickToolsNav>} /> 
                                <Route path='/foreclosure-sales' element={<QuickToolsNav><ForeclosureSales /></QuickToolsNav>} />
                                <Route path='/home-portfolio' element={<QuickToolsNav><HomePortfolio /></QuickToolsNav>} />
                                <Route path='/dura-flyer' element={<QuickToolsNav><DuraFlyer /></QuickToolsNav>} />
                                <Route path='/long-term-escrow' element={<QuickToolsNav><LongTermEscrow /></QuickToolsNav>} />
                                <Route path='/oneconnect' element={<QuickToolsNav><OneConnect /></QuickToolsNav>} />
                                <Route path='/our-services' element={<QuickToolsNav><OurServices /></QuickToolsNav>} />
                                <Route path='/trust-services' element={<QuickToolsNav><TrustServices /></QuickToolsNav>} />
                                <Route path='/underwriters' element={<QuickToolsNav><Underwriters /></QuickToolsNav>} />

                                <Route path='/1031-exchange/basics' element={<TitleOneExchangeBasics />} />
                                <Route path='/1031-exchange/tools' element={<TitleOneExchangeTools />} />
                                <Route path='/1031-exchange/contact-us' element={<TitleOneExchangeContactUs />} />
                                <Route path='/1031-exchange/order' element={<TitleOneExchangeOrder />} />
                                <Route path='/1031-exchange/regulatory-compliance' element={<TitleOneExchangeRegulatoryCompliance />} />
                                <Route path='/1031-exchange/testimonials' element={<TitleOneExchangeTestimonials />} />
                                <Route path='/economic-forum' element={<TitleOneEconomicForum />} />

                                <Route path='/ccr-and-plat-search' element={<RequireAuth><RequirePayment featureId="21"><QuickToolsNav><CcrAndPlatSearch /></QuickToolsNav></RequirePayment></RequireAuth>} />
                                <Route path='/closing-cost-calculator'>
                                    <Route path='' element={<QuickToolsNav><ClosingCostCalculator /></QuickToolsNav>} />
                                    <Route path=':paramClosingCostQuoteId' element={<RequireAuth><QuickToolsNav><ClosingCostCalculator /></QuickToolsNav></RequireAuth>} />
                                </Route>
                                <Route path='/saved-closing-cost-quotes' element={<RequireAuth><QuickToolsNav><SavedClosingCostQuotes /></QuickToolsNav></RequireAuth> } />
                                <Route path='/electronic-forms' element={<RequireAuth><QuickToolsNav><ElectronicForms /></QuickToolsNav></RequireAuth>} />
                                <Route path='/endorsements'>
                                    <Route path='' element={<QuickToolsNav><Endorsements /></QuickToolsNav>} />
                                    <Route path=':paramEndorsementId' element={<QuickToolsNav><Endorsements /></QuickToolsNav>} />
                                    <Route path='search/:paramSearchTitle' element={<QuickToolsNav><Endorsements /></QuickToolsNav>} />
                                </Route>
                                <Route path='/onemap' element={<RequireAuth><OneMap /></RequireAuth>} />
                                <Route path='/onemap-email-link'>
                                    <Route path=':paramRegion/:paramCounty/:paramCity/:paramMapType/:paramPOIs/:paramLat/:paramLng/:paramZoom' element={<OneMapEmailLink/>} />
                                </Route>    
                                <Route path='/targeted-marketing'>
                                    <Route path='' element={<RequireAuth><TargetedMarketing /></RequireAuth>} />
                                    <Route path='radius/:paramState/:paramCounty/:paramParcelId' element={<RequireAuth><TargetedMarketing /></RequireAuth>} />
                                </Route>                                
                                <Route path='/foreclosure-search' element={<RequireAuth><RequirePayment featureId="18"><QuickToolsNav><ForeclosureSearch /></QuickToolsNav></RequirePayment></RequireAuth>} />
                                <Route path='/hoa-search' element={<RequireAuth><RequirePayment featureId="20"><QuickToolsNav><HoaSearch /></QuickToolsNav></RequirePayment></RequireAuth>} />
                                <Route path='/net-sheet'>
                                    <Route path='' element={<RequireAuth><QuickToolsNav><NetSheet /></QuickToolsNav></RequireAuth>} />
                                    <Route path=':paramNetSheetId' element={<RequireAuth><QuickToolsNav><NetSheet /></QuickToolsNav></RequireAuth>} />
                                    <Route path='address/:paramCounty/:paramParcelId' element={<RequireAuth><QuickToolsNav><NetSheet /></QuickToolsNav></RequireAuth>} />
                                </Route>
                                <Route path='/net-sheet-comparison'>
                                    <Route path='' element={<RequireAuth><QuickToolsNav><SavedNetSheets /></QuickToolsNav></RequireAuth>} />
                                    <Route path=':paramRegularOrReverse' element={<RequireAuth><QuickToolsNav><SavedNetSheets /></QuickToolsNav></RequireAuth>} />
                                </Route>
                                <Route path='/propertyone' element={<RequireAuth><RequirePayment featureId="17"><PropertyOne /></RequirePayment></RequireAuth>} />
                                <Route path='/reverse-net-sheet'>
                                    <Route path='' element={<RequireAuth><QuickToolsNav><ReverseNetSheet /></QuickToolsNav></RequireAuth>} />
                                    <Route path=':paramNetSheetId' element={<RequireAuth><QuickToolsNav><ReverseNetSheet /></QuickToolsNav></RequireAuth>} />
                                </Route>
                                <Route path='/tax-calculator' element={<RequireAuth><QuickToolsNav><TaxCalculator /></QuickToolsNav></RequireAuth>} />
                                <Route path='/title-glossary' element={<QuickToolsNav><TitleGlossary /></QuickToolsNav>} />
                                <Route path='/tools' element={<RequireAuth><Tools /></RequireAuth>} />
                                <Route path='/wire-fraud' element={<QuickToolsNav><WireFraud /></QuickToolsNav>} />

                                <Route path='/roadmap' element={<BuyerSellerRoadmap />} />
                                <Route path='/video-library' element={<QuickToolsNav><BuyerSellerVideoLibrary /></QuickToolsNav>} />
                                <Route path='/closing-checklist' element={<QuickToolsNav><ClosingChecklist /></QuickToolsNav>} />
                                <Route path='/helpful-links' element={<HelpfulLinks />} />
                                <Route path='/home-affordability-calc' element={<QuickToolsNav><HomeAffordabilityCalc /></QuickToolsNav>} />
                                <Route path='/homeowner-exemptions' element={<QuickToolsNav><HomeownerExemptions /></QuickToolsNav>} />
                                <Route path='/monthly-payment-calc' element={<QuickToolsNav><MonthlyPaymentCalc /></QuickToolsNav>} />
                                <Route path='/moving-checklist' element={<QuickToolsNav><MovingChecklist /></QuickToolsNav>} />
                                <Route path='/whats-escrow' element={<QuickToolsNav><WhatsEscrow /></QuickToolsNav>} />
                                <Route path='/whats-title-insurance' element={<QuickToolsNav><WhatsTitleInsurance /></QuickToolsNav>} />
                               
                                <Route path='/home-portfolio-form' element={<HomePortfolioForm />} />
                                <Route path='/property-profile' element={<QuickToolsNav><PropertyProfile /></QuickToolsNav>} />
                                <Route path='/title-and-escrow-order' element={<TitleAndEscrowOrderForm /> } />
                                <Route path='/payments'>
                                    <Route path='' element={<QuickToolsNav><Payments /></QuickToolsNav>} />
                                    <Route path=':paramWebPaymentType' element={<QuickToolsNav><Payments /></QuickToolsNav>} />
                                </Route> 

                                <Route path='/preferred-customer/auth' element={<PreferredCustomerAuth />} />
                                <Route path='/preferred-customer/sign-up' element={<PreferredCustomerSignUp />} />
                                <Route path='/preferred-customer/log-out' element={<PreferredCustomerLogOut />} />
                                <Route path='/preferred-customer/profile' element={<RequireAuth><QuickToolsNav><PreferredCustomerProfile /></QuickToolsNav></RequireAuth>} />
                                <Route path='/preferred-customer/feature-payment' element={<RequireAuth><QuickToolsNav><PreferredCustomerFeaturePayment /></QuickToolsNav></RequireAuth>} />
                                <Route path='/preferred-customer/account-required' element={<PreferredCustomerAccountRequired />} />
                                <Route path='/preferred-customer/opt-out' element={<PreferredCustomerOptOut />}>
                                    <Route path='/preferred-customer/opt-out/:paramPreferredCustomerId/:paramEmail' element={<PreferredCustomerOptOut />} />
                                </Route>
                                <Route path='/preferredcustomer/optout.aspx' element={<PreferredCustomerOptOut />}>
                                    <Route path='/preferredcustomer/optout.aspx?id=:paramPreferredCustomerId&email=:paramEmail' element={<PreferredCustomerOptOut />} />
                                </Route>
                                <Route path='/preferred-customer/request-reactivation' element={<QuickToolsNav><PreferredCustomerRequestReactivation /></QuickToolsNav>} />
                                <Route path='/preferred-customer/reset-password' element={<RequireAuth><QuickToolsNav><PreferredCustomerResetPassword /></QuickToolsNav></RequireAuth>} />
                                <Route path='/preferred-customer/switch-brand'>
                                    <Route path='' element={<PreferredCustomerSwitchBrand />} />
                                    <Route path=':paramDesiredRegion' element={<PreferredCustomerSwitchBrand />} />
                                </Route>
                                <Route path='/preferred-customer/forgot-password' element={<PreferredCustomerForgotPassword />} />
                            </Route>
                        </Routes>
                    </ScrollToTop>
                </UserProvider>
            </BrowserRouter>
        </MsalProvider>
    );
}

export { App, bootstrap };
