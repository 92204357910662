import React from 'react';
import { Link } from 'react-router-dom';
import { FilePath, EventTrack } from '../../services/utilities';
import './banner-ad.scss';

const BannerAd = (props) => {

    return (
        <div className="row marketing-banner d-print-none">
            <div className="col-12">
                <div className="mb-3">
                    <Link to={"/onemap" + ((props && props.customPath) ? props.customPath : "")} className="d-block w-100" onClick={() => EventTrack("OneMap", "Marketing Banner Clicked", "")}>
                        <video autoPlay muted loop preload="auto" aria-labelledby="marketingBannerDescr" poster={FilePath('/images/banners/onemap-poster.webp')} height="150" width="1296" className="img-fluid" playsInline={true} >
                            <source src={FilePath('/images/banners/onemap.mp4')} type="video/mp4" />
                            Check out our OneMap tool
                        </video>
                        <span className="visually-hidden" id="marketingBannerDescr" aria-hidden>Animation: OneMap tool with map of zip codes, parks and schools.</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default BannerAd;