import axios from 'axios';
import React, { useContext, useState} from 'react';
import { MdLocationPin } from 'react-icons/md';
import { bootstrap } from '../../app';
import apiTools from '../../services/api-tools';
import { UserContext } from '../../contexts/user-context';

const PropertiesNearMe = (props) => {
    const [invalidCounty, setInvalidCounty] = useState(false);
    const { activeRegion } = useContext(UserContext);

    const getLocation = () => { 
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(getNearbyResults);
        }
    }

    function getNearbyResults(position) {
        setInvalidCounty(false);
        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + 
        '&key=' + process.env.REACT_APP_GOOGLE_MAPS_KEY)
            .then((response) => { 
                checkIfResults(response.data.results);
            })
    }

    const checkIfResults = (searchArray) => {
        var fullAddress = searchArray[0].formatted_address;
        var address = fullAddress.split(',')[0];
        var currentCounty = "";
        searchArray[0].address_components.forEach((component) => {
            component.types.forEach((type) => {
                if (type === "administrative_area_level_2") {
                    currentCounty = component.short_name;
                }
            });
        });
        currentCounty = currentCounty.replace("County", "").replace(" ", "").trim();
        var validCounty = false;
        var selectedCounty = null;
        activeRegion.stateAndCounties.forEach((county) => { 
            if (county.countyValue === currentCounty) 
            {
                validCounty = true;
                selectedCounty = county;
            }
        })
        if (!validCounty) {
            setInvalidCounty(true);
            return;
        } 
        apiTools.get(`/property-search?state=${selectedCounty.state}&county=${currentCounty}&searchType=siteAddr&value=${address}&batch=0`)
            .then((response) => {
                if (response.data.totalCount === 0) {
                    searchArray.shift();
                    checkIfResults(searchArray);
                } else { 
                    props.onSubmit({ county: currentCounty, searchType: "siteAddr", searchCriteria: address, property: response.data.records[0] });
                    props.logActivity(props.toolName + " - Properties Near Me Search Performed", `County: ${currentCounty}, \nAddress: ${address}`);
                    var propsNearMeCollapse = document.getElementById('LocationSearchBody')
                    new bootstrap.Collapse(propsNearMeCollapse, {
                        toggle: true
                    });
                }
            });
    }

  return (
      <>
        <div className="card mb-2 d-block d-md-none">
              <div className={"card-header collapse-trigger " + props.headerClasses} data-bs-toggle="collapse" data-bs-target="#LocationSearchBody">
                  <MdLocationPin className="sm-react-icons" /><strong className="ps-2">{props.title}</strong>
            </div>
            <div id="LocationSearchBody" className="collapse in show">
                <div className="card-body text-white bg-dark text-center">
                    <div className="row">
                        <div className="col-12">
                            <h2>MOBILE EXCLUSIVE</h2>
                              <label htmlFor="btnSearchLocation">{props.description}</label>
                            <input type="button" id="btnSearchLocation" className="btn btn-primary m-3" value="Search Around Me" onClick={() => getLocation()}/>
                        </div>
                    </div>
                      {invalidCounty && <small className="text-danger d-block">Your location is not valid for the region you are logged in to.</small>}
                </div>
            </div>
        </div>
      </>
  );
}

export default PropertiesNearMe;