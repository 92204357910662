import React, { useState, useEffect} from 'react';
import apiTools from '../../services/api-tools';
import './search-result.scss';

const SearchResult = ({ property, county, index, onSelectProperty, expandedProperty, setExpandedProperty }) => {
    const [propertyImage, setPropertyImage] = useState(null);

    useEffect(() => {
        apiTools.get(`/property-image?state=${property.siteState}&county=${county}&parcelId=${property.parcelId}`)
            .then((response) => {
                if (response.data && response.data.url) {
                    setPropertyImage(response.data.url);
                } else { 
                    setPropertyImage("https://maps.googleapis.com/maps/api/streetview?size=400x300&location=" + property.latitude + "," + property.longitude + "&key=" + process.env.REACT_APP_GOOGLE_MAPS_KEY);
                }
            });
    }, [property]);

  return (
      <>
          <div onClick={() => setExpandedProperty(property)} id={property.parcelId} className={expandedProperty === property ? "col-12 border border-light collapse-trigger" : "col-12 border border-light collapse-trigger collapsed"} data-bs-toggle="collapse" data-bs-target={"#additionalInfo" + index}>
              <div className="row p-2">
                {propertyImage && <div className="col-12 col-lg-5 col-xl-4 pe-0">
                      <img src={propertyImage} className="property-image no-localization" alt={"Property Thumbnail for " + property.siteAddress} />
                </div>}
                <div className="col-12 col-lg-7 col-xl-8">
                    <div className="row">
                        <div className="col-12">
                            <span className="form-label fw-bold">Primary Owner: </span>
                            <span className="no-localization">{ property.ownerName || "Not Available"}</span>
                        </div>
                        <div className="col-12">
                            <span className="form-label fw-bold">Property Addr: </span>
                            <span className="no-localization">{property.siteAddress !== null ?  property.siteAddress + (property.siteCity !== null ? ', ' + property.siteCity : '') : "To Be Determined"}</span>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-md-6 pe-0">
                                    <span className="form-label fw-bold">Bedrooms: </span>
                                    <span className="no-localization">{ property.bedCount}</span>
                                </div>
                                <div className="col-12 col-md-6 pe-0">
                                    <span className="form-label fw-bold">Bathrooms: </span>
                                    <span className="no-localization">{ property.bathTotalCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              <div className={expandedProperty === property ? "row p-2 collapse show" : "row p-2 collapse"} id={"additionalInfo" + index } data-bs-parent="#propertiesList">
                  <div className="col-12">
                      <div className="row">
                          <div className="col-12">
                              <span className="form-label fw-bold">Property Type: </span>
                              <span className="no-localization">{property.recordTypeDescription}</span>
                          </div>
                          <div className="col-12 pe-0">
                              <span className="form-label fw-bold">Parcel: </span>
                              <span className="no-localization">{property.parcelId}</span>
                          </div>
                          <div className="col-12">
                              <span className="form-label fw-bold">Abbreviated Legal Description: </span>
                              <span className="no-localization">{property.legalDescription  || "Not Available"}</span>
                          </div>
                          <div className="col-12">
                              <input type="button" value="Create Profile" className="btn btn-primary float-end" onClick={() => onSelectProperty()}/>
                          </div>
                      </div>
                  </div>
              </div>
        </div>
      </>
  );
}

export default SearchResult;