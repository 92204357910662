import React, { useContext, useState, useRef, useEffect } from 'react';
import { BrandContext } from '../../../contexts/brand-context';
import { MdSearch } from 'react-icons/md'
import './site-search-nav.scss';

const SiteSearchNav = ({ className }) => {
    const { selectedBrand } = useContext(BrandContext);
    const [googleSearchLoaded, setGoogleSearchLoaded] = useState();
    const [showSearchBox, setShowSearchBox] = useState(false);
    const gSearchInputRef = useRef();

    const googleCxCodes = [{ "code": "014823055041626938240:jfgswhgfgj0", "brandId": 1 },
        { "code": "014823055041626938240:vlrghhwzymu", "brandId": 2 },
        { "code": "f57b78c9048aaf57e", "brandId": 4 }]

    const [searchTextValue, setSearchTextValue] = useState("");

    useEffect(() => {
        if (selectedBrand && !googleSearchLoaded) {
            (function () {
                const cx = googleCxCodes.find((cx) => parseInt(cx.brandId) === parseInt(selectedBrand.brandId)).code;
                const gcse = document.createElement("script");
                gcse.type = "text/javascript";
                gcse.async = true;
                gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
                const s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(gcse, s);
            })();

            var renderSearchForms = function () {
                if (document.readyState === "complete") {
                    window.google.search.cse.element.render({
                        div: "googlesearch",
                        tag: "search",
                        gname: "gsearch",
                    });
                } else {
                    window.google.setOnLoadCallback(function () {
                        window.google.search.cse.element.render({
                            div: "googlesearch",
                            tag: "search",
                            gname: "gsearch",
                        });
                    }, true);
                }
            };

            var clearSearchBox = function () {
                setSearchTextValue("");
                setShowSearchBox(false);
            }

            window.__gcse = {
                parsetags: "explicit",
                initializationCallback: renderSearchForms,
                searchCallbacks: {
                    image: {
                        rendered: clearSearchBox
                    },
                    web: {
                        rendered: clearSearchBox
                    }
                }
            };
            setGoogleSearchLoaded(true);
        }
    }, [selectedBrand, googleSearchLoaded]);

    useEffect(() => {
        const input = document.getElementById("gSearchInputBox");
        const submit = (e) => {
            if (e.keyCode === 13) {
                const element = window.google.search.cse.element.getElement("gsearch");

                element.execute(searchTextValue);
            }
        };
        input.addEventListener("keyup", submit);

        return () => input.removeEventListener("keyup", submit);
    }, [searchTextValue]);

    useEffect(() => {
        if (showSearchBox) {
            var txtBox = gSearchInputRef.current;
            txtBox.focus();
        }
    }, [showSearchBox])

    const handleChange = (e) => {
        setSearchTextValue(e.target.value);
    };

    const handleClick = () => {
        if (showSearchBox && searchTextValue.length > 0) {
            const element = window.google.search.cse.element.getElement("gsearch");
            element.execute(searchTextValue);
        }
        if (showSearchBox && searchTextValue.length === 0) setShowSearchBox(false);
        if (!showSearchBox) setShowSearchBox(true);
    };

    return (
        <>
            <div className={className}>
                <div className="clear-fix">
                    <div className="float-end ps-1"><MdSearch className="sm-react-icons" role="button" aria-label="Site Search" onClick={() => handleClick()} /></div>
                    <div className="float-end"><input ref={gSearchInputRef} name="gSearchInputBox" id="gSearchInputBox" className={`${(showSearchBox) ? "" : "d-none"} form-control form-control-sm`} value={searchTextValue} onChange={handleChange} /> </div>
                </div>
                <div id="googlesearch"></div>
            </div>
        </>
    );
}

export default SiteSearchNav;