import React, { useState, useContext, useEffect } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import SpinnerLoader from '../layout/spinner-loader';
import { UserContext } from '../../contexts/user-context';
import { useForm } from "react-hook-form";
import { FilePath } from '../../services/utilities';
import apiPreferredCustomer from '../../services/api-preferred-customer';
import '../preferred-customer/profile.scss';
import { Dropzone } from "dropzone";
import "dropzone/dist/dropzone.css";

const AddYourLogo = ({toolName, setIncludeLogo}) => {
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser,  getUserProfileSilently } = useContext(UserContext);
    const [uploading, setUploading] = useState(false);
    const { register, handleSubmit, watch } = useForm();
    const [dropzone, setDropzone] = useState(null);
    const watchInclude = watch("includeLogo");

    useEffect(() => {
        setIncludeLogo(watchInclude);
    }, [watchInclude]);

    useEffect(() => {
        if (pcUser == null) return;
        if (!dropzone) { 
            var authorizeHeader = "";
            var accessToken = localStorage.getItem("pcuser.accessToken");
            if (accessToken) {
                authorizeHeader = `Bearer ${accessToken}`;
            }
            var headers = {
                'Authorization': authorizeHeader,
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_PREFERREDCUSTOMER_SUBSCRIPTION_KEY
            };
            setDropzone(new Dropzone("div#logo", { url: process.env.REACT_APP_API_PREFERREDCUSTOMER_BASE_URL +'/upload-logo', createImageThumbnails: true, maxFiles: 1, headers: headers, maxFilesize: 15000000 }));
        }
    }, [pcUser]);

    const onAddLogoSubmit = data => {
        setUploading(true);
        data.selectedBrand = selectedBrand;
        data.preferredCustomerId = pcUser.preferredCustomerId;
        if (dropzone.getAcceptedFiles().length === 1) data.logo = pcUser.preferredCustomerId + '-' + dropzone.getAcceptedFiles()[0].name;
        dropzone.disable();
        setDropzone(null);
        const submitProfileUpdate = async () => {
            await apiPreferredCustomer.post(`/change-logo-on-account/${data.logo}`).then(() => { setUploading(false); getUserProfileSilently() });
        }
        submitProfileUpdate();
    }

    const removeLogo = () => {
        setUploading(true);
        const remove = async () => {
            await apiPreferredCustomer.post('/remove-logo').then((response) => { 
                setUploading(false);
                getUserProfileSilently();
                dropzone.disable();
                setDropzone(null);
            });
        }
        remove();
    }
    
              
    return (
        <>
           <div className="modal" id="addLogoModal" tabIndex="-1" aria-labelledby="addLogoModalLabel" aria-hidden="true" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addLogoModalLabel">Add Your Logo</h5>
                        </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onAddLogoSubmit)}>
                        {!uploading && <>
                            <div className="row">
                                <div className="col-6 mt-2">
                                    <label className="fw-bold">Add your logo to this {toolName} when downloading and emailing?:</label>
                                </div>
                                <div className="col-6 mt-2">
                                    <label htmlFor="includeLogo"><input type="checkbox" id="includeLogo" {...register("includeLogo")} /> Yes</label>
                                </div>
                            </div>
                            <div className="row pt-1">
                                <div className="col-6 mt-2">
                                    <label className="fw-bold" htmlFor="logo">Selected Logo:</label>
                                    {pcUser.logo && <><br/><small>Current logo is displayed, upload a new logo using the drop area below.</small></>}
                                </div>
                                <div className="col-6 mt-2">
                                    {pcUser.logo && <input name="removeLogo" type="button" value="Remove Logo" className="btn btn-primary" id="removeLogo" onClick={() => removeLogo()}/>}
                                    {pcUser.logo ? <img className="pt-2 pc-logo" alt="Preferred Customer Logo" src={FilePath('/preferredcustomerlogos/' + pcUser.logo)} /> : <span>No logo uploaded.</span> }
                                </div>
                            </div>
                            <div className="row py-2">
                                <div className="col-12 col-sm-6 offset-sm-6">
                                    <div id="logo" className="dropzone dropzone-previews border"/>
                                </div>
                            </div>
                        </>
                        }
                        {uploading && <SpinnerLoader />}
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary m-auto" disabled={uploading}>Upload New Logo</button>
                            <button type="button" className="btn btn-primary m-auto" data-bs-dismiss="modal">Close</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default AddYourLogo;