import React, { useState, useContext, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../../contexts/brand-context';
import { useParams, Link, useNavigate } from 'react-router-dom';
import NetSheetCommonData from './common-data';
import BuyerSide from './buyer-side/buyer-side';
import NetSheetOptionsMenu from './options-menu';
import apiTools from '../../../services/api-tools';
import SpinnerLoader from '../../layout/spinner-loader';
import { UserContext } from '../../../contexts/user-context';
import SellerSide from './seller-side/seller-side';
import currency from 'currency.js';
import { EventTrack } from '../../../services/utilities';

const NetSheet = () => {
    let { paramNetSheetId, paramCounty, paramParcelId } = useParams();
    const { selectedBrand } = useContext(BrandContext);
    const { pcUser, logActivity, activeRegion } = useContext(UserContext);
    const navigate = useNavigate();

    const headingRef = useRef(null);
    const [loadedNetSheet, setLoadedNetSheet] = useState(null);
    const [calcResults, setCalcResults] = useState(null);
    const [inputData, setInputData] = useState(null);
    const [sideShowing, setSideShowing] = useState('input');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [completeNetSheetObj, setCompleteNetSheetObj] = useState(false);
    const [includeLogo, setIncludeLogo] = useState(false);
    const [selectedCountyState, setSelectedCountyState] = useState(null);
    const [disclaimer, setDisclaimer] = useState("");
    const [showRatesUsedMessage, setShowRatesUsedMessage] = useState(true);

    const [commonData, setCommonData] = useState(null);
    const [commonDataSaveDone, setCommonDataSaveDone] = useState(false);
    const [buyersData, setBuyersData] = useState(null);
    const [buyersDataSaveDone, setBuyersDataSaveDone] = useState(false);

    const [sellersData, setSellersData] = useState(null);
    const [sellersDataSaveDone, setSellersDataSaveDone] = useState(false);

    const [netSheetSaved, setNetSheetSaved] = useState(false);
    const [netSheetName, setNetSheetName] = useState(null);

    const [miscData, setMiscData] = useState({ hoaFeeCreditToSeller: '0.00', 
        homeOwnersAssociationCreditFromBuyer: '0.00', 
        assistanceCredit: '0.00', 
        assistanceToBuyer: '0.00', 
        secondHalfTaxesCredit: '0.00', 
        secondHalfTaxes: '0.00', 
        propertyTaxFromSellerCredit: '0.00', 
        taxProration: '0.00',
        manuallyChanged: []
    });

    useEffect(() => {
        const getNetSheet = async () => {
            const response = await apiTools.get(`/net-sheet/${paramNetSheetId}`);
            if (response.data &&
                !activeRegion.stateAndCounties.find(sc => sc.county === response.data.netSheetFormData.propertyCounty.county &&
                    sc.state === response.data.netSheetFormData.propertyCounty.stateAbbr)) {
                navigate("/net-sheet-comparison");
            }
            else {
                setLoadedNetSheet(response.data);
                setNetSheetName(response.data.netSheetName);
            }
        }
        if (paramNetSheetId) getNetSheet();

    }, [paramNetSheetId, activeRegion]);

    const onSubmit = (data, side) => {
        computeForm(data, side);
        setInputData(data);
    }
    
    useEffect(() => {
        if (pcUser) { logActivity("Netsheet Calculator", "Main Netsheet Page"); }
    }, []);

    useEffect(() => {
        if (!saving) return;
        setCommonDataSaveDone(true);
    }, [commonData]);

    useEffect(() => {
        if (!saving) return;
        setBuyersDataSaveDone(true);
    }, [buyersData]);

    useEffect(() => {
        if (!saving) return;
        setSellersDataSaveDone(true);
    }, [sellersData]);

    useEffect(() => {
        if (saving && commonDataSaveDone && buyersDataSaveDone && sellersDataSaveDone) { 
            var obj = { netSheetFormData: { ...commonData },
                ...buyersData ,  
                ...sellersData, 
                netSheetName: netSheetName,
                netSheetId: paramNetSheetId, 
                ManuallyChangedValues: [...new Set([...buyersData.ManuallyChangedValues, ...sellersData.ManuallyChangedValues, ...miscData.manuallyChanged])]
            };
            setSaving(false);
            setCommonDataSaveDone(false);
            setBuyersDataSaveDone(false);
            setSellersDataSaveDone(false);
            setCompleteNetSheetObj(obj);
            const submitSave = async () => {
                await apiTools.post(`/net-sheet/save/regular`, obj).then((response) => setNetSheetSaved(true));
                logActivity("Netsheet Saved", `Buyer: ${commonData.buyerName}, \nSeller: ${commonData.sellerName}, \nAddress: ${commonData.propertyAddress}, \nBy: ${commonData.preparedBy}`);
                EventTrack("Net Sheet", "Saved", commonData.propertyAddress);
            }
            if (downloading) setNetSheetSaved(true);
            if (netSheetName && !downloading) submitSave();
        };
    }, [commonDataSaveDone, buyersDataSaveDone, sellersDataSaveDone, netSheetName]);

    useEffect(() => {
        const getDisclaimer = async() => apiTools.get(`/net-sheet/disclaimer/${selectedCountyState}`)
            .then((resp) => {
                setDisclaimer(resp.data);
            });
        
        if (selectedCountyState) getDisclaimer();

    }, [selectedCountyState]);

    const saveNetSheet = () => {
        setSaving(true);
    }
    const setMiscOpposites = (id, value) => { 
        var newObj = { ...miscData };
        switch (id) {
            case 'hoaFeeCreditToSeller':
                newObj.hoaFeeCreditToSeller = value;
                newObj.homeOwnersAssociationCreditFromBuyer = currency(value).multiply(-1).value;
                if (!newObj.manuallyChanged.includes('hoaFeeCreditToSeller')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'hoaFeeCreditToSeller', 'homeOwnersAssociationCreditFromBuyer'];
                break;
            case 'assistanceCredit':
                newObj.assistanceCredit = value;
                newObj.assistanceToBuyer = currency(value).multiply(-1).value;  
                if (!newObj.manuallyChanged.includes('assistanceCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'assistanceCredit', 'assistanceToBuyer'];
                break;
            case 'secondHalfTaxesCredit':
                newObj.secondHalfTaxesCredit = value;
                newObj.secondHalfTaxes = currency(value).multiply(-1).value; 
                if (!newObj.manuallyChanged.includes('secondHalfTaxesCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'secondHalfTaxesCredit', 'secondHalfTaxes'];
                break;            
            case 'propertyTaxFromSellerCredit':
                newObj.propertyTaxFromSellerCredit = value;
                newObj.taxProration = currency(value).multiply(-1).value; 
                if (!newObj.manuallyChanged.includes('propertyTaxFromSellerCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'propertyTaxFromSellerCredit', 'taxProration'];
                break;
            case 'homeOwnersAssociationCreditFromBuyer':
                newObj.homeOwnersAssociationCreditFromBuyer = value;
                newObj.hoaFeeCreditToSeller = currency(value).multiply(-1).value;  
                if (!newObj.manuallyChanged.includes('hoaFeeCreditToSeller')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'hoaFeeCreditToSeller', 'homeOwnersAssociationCreditFromBuyer'];
                break;
            case 'assistanceToBuyer':
                newObj.assistanceToBuyer = value;
                newObj.assistanceCredit = currency(value).multiply(-1).value;
                if (!newObj.manuallyChanged.includes('assistanceCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'assistanceCredit', 'assistanceToBuyer'];
                break;
            case 'secondHalfTaxes':
                newObj.secondHalfTaxes = value;
                newObj.secondHalfTaxesCredit = currency(value).multiply(-1).value;   
                if (!newObj.manuallyChanged.includes('secondHalfTaxesCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'secondHalfTaxesCredit', 'secondHalfTaxes'];
                break;            
            case 'taxProration':
                newObj.taxProration = value;
                newObj.propertyTaxFromSellerCredit = currency(value).multiply(-1).value;
                if (!newObj.manuallyChanged.includes('propertyTaxFromSellerCredit')) newObj.manuallyChanged = [...newObj.manuallyChanged, 'propertyTaxFromSellerCredit', 'taxProration'];
                break;
            default:
                break;
        }
        setMiscData(newObj);
    }
    
    const computeForm = (formVals, side) => {
        setLoading(true);
        apiTools.post(`/net-sheet/calculate`, formVals)
            .then((response) => {
                setCalcResults(response.data);
                setLoading(false);
                setSideShowing(side);

            });
    };
     
    return (
        <>
            <Helmet>
                <title>Net Sheet: {selectedBrand.brandName}</title>
            </Helmet>
            <h1 ref={headingRef}>Net Sheet Calculator</h1>
            <p>
                Real estate agents, use this tool to estimate buyer and seller costs. Lenders, please use our <Link to="/closing-cost-calculator">closing cost calculator</Link> to estimate the title policy fees in compliance with the CFPB requirements to disclose the loan policy at the full rate.
            </p>
            <div id="netSheet">
            {loadedNetSheet && <p><span className="fw-bold">Net Sheet Name</span> - {loadedNetSheet.netSheetName}</p>}
            <NetSheetOptionsMenu sideShowing={sideShowing} setSideShowing={setSideShowing} showLogo={false} saveNetSheet={saveNetSheet} netSheetName={netSheetName} setNetSheetName={setNetSheetName} netSheetSaved={netSheetSaved} setNetSheetSaved={setNetSheetSaved} completeNetSheetObj={completeNetSheetObj} setDownloading={setDownloading} downloading={downloading} includeLogo={includeLogo} setIncludeLogo={setIncludeLogo} logActivity={logActivity} />
            {loading && <SpinnerLoader/>}
            <NetSheetCommonData submitData={onSubmit} className={!loading && sideShowing === 'input' ? "" : "d-none"} saving={saving} setCommonData={setCommonData} loadedNetSheet={loadedNetSheet} paramCounty={paramCounty} paramParcelId={paramParcelId} selectedCountyState={selectedCountyState} setSelectedCountyState={setSelectedCountyState} setShowRatesUsedMessage={setShowRatesUsedMessage} />
            <BuyerSide data={calcResults} className={!loading && inputData && sideShowing === 'buyer' ? "" : "d-none"} saving={saving} setBuyersData={setBuyersData} setMiscOpposites={setMiscOpposites} miscData={miscData} loadedNetSheet={loadedNetSheet} />
            <SellerSide data={calcResults} className={!loading && inputData && sideShowing === 'seller' ? "" : "d-none"} saving={saving} setSellersData={setSellersData} setMiscOpposites={ setMiscOpposites} miscData={miscData} loadedNetSheet={loadedNetSheet} />

            {sideShowing !== 'input' && <NetSheetOptionsMenu sideShowing={sideShowing} setSideShowing={setSideShowing} showLogo={true} saveNetSheet={saveNetSheet} completeNetSheetObj={completeNetSheetObj} setDownloading={setDownloading} downloading={downloading} includeLogo={includeLogo} setIncludeLogo={setIncludeLogo} logActivity={logActivity} /> }

                {showRatesUsedMessage && <input type="text" tabIndex="-1" value="Calculated using Title Resource Group rates." className="form-control" readOnly="readonly" />}
                <p>{disclaimer}</p>
                <p>
                    The net sheet calculator will not disclose fees in the format required for the closing disclosure form. If you need that format, refer to our closing cost calculator.
                </p>
            </div>
        </>
    );
}

export default NetSheet;