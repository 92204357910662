import React, {  useContext, useEffect } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, Link } from 'react-router-dom';
import SpinnerLoader from '../layout/spinner-loader';

const PreferredCustomerAuth = () => {
    const { selectedBrand } = useContext(BrandContext);
    const { loadingUser, pcUser, returnUrl, errorLoadingPcProfile, cancelLoginOrPasswordUpdate, setCancelLoginOrPasswordUpdate } = useContext(UserContext);
    const navigate = useNavigate(); 

    useEffect(() => {
        if (!loadingUser && pcUser) {
            if (returnUrl) {
                navigate(returnUrl);
            }
            else {
                navigate("/");
            }
        }
        else if (!loadingUser && cancelLoginOrPasswordUpdate) {
            setCancelLoginOrPasswordUpdate(false);
            navigate("/");
        }
    }, [loadingUser, pcUser, returnUrl, navigate, cancelLoginOrPasswordUpdate]);

    return (
        <>
            <Helmet>
                <title>Preferred Customer Signing In : {selectedBrand.brandName}</title>
            </Helmet>

            <h1>Signing In</h1>
            {(loadingUser || (!errorLoadingPcProfile && !pcUser)) &&
                <div>
                    <p>Hold tight... we're getting your account information.</p>
                    <SpinnerLoader />
                </div>
            }
            {!loadingUser && errorLoadingPcProfile &&
                <div>
                    <p>Oops! There was a problem getting your preferred customer profile.</p>
                    <p><Link to="/preferred-customer/log-out" className="btn btn-link p-0 ">LOG OUT</Link> and try again?</p>
                </div>
            }
        </>
    );
}

export default PreferredCustomerAuth;