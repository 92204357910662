import React, { useContext } from 'react';
import { BrandContext } from '../../contexts/brand-context';
import { UserContext } from '../../contexts/user-context';
import Slider from "react-slick";
import './tools-slider.scss';
import { FilePath, EventTrack } from '../../services/utilities';
import { Link } from 'react-router-dom';
import filter from 'lodash/filter';

const ToolsSlider = () => {
    const { selectedBrand, brandTools } = useContext(BrandContext);
    const { activeRegion } = useContext(UserContext);
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    const filterRegionTools = (tool) => {
        if (activeRegion && (activeRegion.regionName === "Eastern Washington" || activeRegion.regionName === "Oregon")) {
            return tool.name !== "Foreclosure Search";
        }
        else {
            return true;
        }
    };

    return (
        <> 
            {brandTools && brandTools.length > 0 ? <div className="container-fluid featured-tools-slider pt-3 pt-lg-4">
            <Slider {...settings}>
                    {filter(brandTools, filterRegionTools).map((tool, index) => {
                        return <Link key={index} to={tool.link} className="text-center w-100 lh-sm px-0 px-lg-2 px-xl-3" onClick={() => EventTrack(tool.name, "Home Tool Clicked", `Position=${index + 1}`)}>
                            <img src={FilePath('/images' + tool.image)} className="m-auto tool-icon" alt={tool.name + " icon"} />
                            <div className="pt-2">{tool.name}</div>
                        </Link>
                    })}
            </Slider>
            </div> : <div className={selectedBrand && selectedBrand.brandId !== 4 && "featured-tools-slider"}></div>
            }
        </>
    );  
}

export default ToolsSlider;