import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BrandContext } from '../../contexts/brand-context';

const DefaultHeaderTags = () => {
    const { selectedBrand } = useContext(BrandContext);
    const location = useLocation();

    useEffect(() => {
    }, [location]);

    const toTitleCase = (str) => {
        return str.toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
            return match.toUpperCase();
        }).replace(/[/-]/g, " ");
    }

    return (
        <Helmet>
            <title>{toTitleCase(location.pathname) + " : " + ((selectedBrand) ? selectedBrand.brandName : "")}</title>
        </Helmet>
    );
}

export default DefaultHeaderTags;