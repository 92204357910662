import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineEmail, MdOutlineFileDownload, MdOutlineCalculate, MdOutlineRemove, MdOutlineAdd, MdOutlineMap } from 'react-icons/md'
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import fileDownload from 'js-file-download';
import apiTools from '../../services/api-tools';
import EmailP1Package from './email-profile';
import "./download-email-profile.scss"
import axios from 'axios';
import SpinnerLoader from '../layout/spinner-loader';
import { EventTrack } from '../../services/utilities';

const DownloadEmailProfile = ({ parcelHtml, pcUser, selectedBrand, activeRegion, ccrState, deedState, aerialMap, streetLocatorMap, platMap, streetMapAvailable, address, folderGroupOpen, setFolderGroupOpen, position, county, state, parcelId, city, logActivity, setOriginAddress, originAddress }) => {
   
    const [checkedParcel, setCheckedParcel] = useState(false);
    const [checkedDeed, setCheckedDeed] = useState([]); //array of pdfs to include
    const [checkedCcr, setCheckedCcr] = useState([]); //array of pdfs to include
    const [checkedAerial, setCheckedAerial] = useState(false);
    const [checkedStreetLocator, setCheckedStreetLocator] = useState(false);
    const [checkedPlat, setCheckedPlat] = useState(false);
    const [checkedStreet, setCheckedStreet] = useState(false);
    const [checkedRoute, setCheckedRoute] = useState(false); //if checked use originAddress
    const [checkedLogo, setCheckedLogo] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const { register, getValues, reset } = useForm();
    const [origin, setOrigin] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

    const doDownload = async (emailData) => {
        setDownloading(true);
        var parcelData = (checkedLogo && checkedParcel && pcUser.logo) ? parcelHtml.replace("t1.png", pcUser.logo) : parcelHtml;
        apiTools.post(`/property-package/download-email`, {
            parcelHtml: checkedParcel ? parcelData : '',
            deeds: checkedDeed,
            ccrs: ccrState.ccrs ? ccrState.ccrs.filter((ccr) =>
                checkedCcr.includes(getDisplayName(ccr)) || checkedCcr.includes(ccr.documentLink)).map((ccr) => { return {
                    documentSource: ccr.documentSource, 
                    ccrId: ccr.ccrId ? ccr.ccrId : ccr.documentNumber,
                    fileName: ccr.fileName ? ccr.fileName : ccr.documentLink.split('/')[1],
                    fullPath: ccr.documentLink ? ccr.documentLink : ''
                    } 
                }) : [], 
            aerial: checkedAerial ? aerialMap : '', 
            streetLocator: checkedStreetLocator ? streetLocatorMap : '',
            plat: checkedPlat ? platMap : '', 
            includeStreetMap: checkedStreet,
            streetMap:  {
                originLat: checkedRoute && origin ? origin.lat() : null,
                originLng: checkedRoute && origin ? origin.lng() : null,
                destinationLat: position ? position.lat() : null,
                destinationLng: position ? position.lng() : null,
            },
            selectedBrand: selectedBrand,
            activeRegion: activeRegion,
            downloadOrEmail: emailData ? "email" : "download",
            state: state, 
            county: county,
            address: address,
            city: city,
            to: emailData ? emailData.to : '', 
            notes: emailData ? emailData.comments : '',
            from: pcUser?.email
            }, { responseType: 'blob' })
            .then((response) => {
                setDownloading(false);
                if (emailData) setEmailSent(true);
                if (emailData) {
                    logActivity("PropertyOne - Profile Emailed", `County: ${county}, \nParcelID: ${address}`);
                    EventTrack("PropertyOne", "Profile Emailed", address);
                } else {
                    logActivity("PropertyOne - Profile Downloaded", `County: ${county}, \nParcelID: ${address}`);
                    EventTrack("PropertyOne", "Profile Downloaded", address);
                    if (response.statusText === 'No Content' || response.status !== 200) return;
                    fileDownload(response.data, `${selectedBrand.brandName.toLowerCase().replace(/ /g, "-")}-package-info.pdf`);
                }
            });
    }

    useEffect(() => {
        if (!streetMapAvailable) return;
        const setupMapSearch = async () => {
            const { SearchBox } = await window.google.maps.importLibrary("places");
            var searchBoxDownload = new SearchBox(document.getElementById("originDownload"));
            searchBoxDownload.addListener("places_changed", () => {
                const places = searchBoxDownload.getPlaces();
                if (places.length >= 0) {
                    axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + encodeURIComponent(getValues("originDownload")) + "&key=" + googleMapsAPIKey)
                        .then((response) => {
                            var point = new window.google.maps.LatLng(response.data.results[0].geometry.location.lat, response.data.results[0].geometry.location.lng);
                            setOrigin(point);
                        });
                }
            });
        }
        setupMapSearch();
    }, [streetMapAvailable]);
    function toggleAll(value) {
        setIsCheckAll(!isCheckAll);
        setCheckedParcel(!value);
        setCheckedAerial(!value);
        setCheckedStreetLocator(!value);
        setCheckedPlat(!value);
        setCheckedStreet(!value);
        setCheckedLogo(!value);
        setCheckedRoute(!value);
        if (deedState && deedState.deeds) {
            setCheckedDeed(deedState.deeds.map(li => li.docLink));
        }
        if (ccrState.ccrs) selectedBrand.brandId === 2 ? setCheckedCcr(ccrState.ccrs.map(li => li.documentLink)) : setCheckedCcr(ccrState.ccrs.map(li => getDisplayName(li)));
        if (isCheckAll) {
            setCheckedDeed([]);
            setCheckedCcr([]);
        }
        return !value;
    }

    function toggleCheckbox(value) {
        return !value;
    }
   
    useEffect(() => {
        if (checkedRoute && !checkedStreet) setCheckedStreet(toggleCheckbox);
    },[checkedRoute, checkedStreet])

    useEffect(() => {
        setCheckedParcel(false);
        setCheckedDeed([]);
        setCheckedCcr([]);
        setCheckedAerial(false);
        setCheckedStreetLocator(false);
        setCheckedPlat(false);
        setCheckedStreet(false);
        setCheckedRoute(false);
        setOriginAddress(null);
        reset();
    },[parcelHtml])

    const getDisplayName = (ccr) => {
        return `${ccr.documentNumber ? ccr.documentNumber + ' - ' : ''}${ccr.display ? ccr.display : ccr.documentName + (ccr.instrumentType.includes('204') ? '' : ` (${ccr.instrumentType})`)}`
    }
    
    return (<>
        <EmailP1Package emailFrom={pcUser?.email} emailSent={emailSent} setEmailSent={setEmailSent} doDownload={doDownload} />
        {downloading ? <SpinnerLoader /> :
            <div>
                <div className="row mx-1">
                    <div className="col-10"></div>
                    <div className="col-2 downloadInfo text-end small pe-2">Include in package:</div>
                </div>
                {selectedBrand.brandId !== 2 && <div className="row mx-1">
                    <div className="col-11 text-end">Select All:</div>
                    <div className="col-1 text-center p-0 ps-md-2">
                        <input aria-label="Select All"
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            checked={isCheckAll}
                            onChange={() => setIsCheckAll(toggleAll)}
                        />
                    </div>
                </div>}
                <div className="row shadowbox mx-1">
                    <div className="col-11"><strong>Parcel Info</strong></div>
                    <div className="col-1 text-center p-0 ps-md-2">
                        <input aria-label="Parcel Info"
                            type="checkbox"
                            checked={checkedParcel}
                            onChange={() => setCheckedParcel(toggleCheckbox)}
                        />
                    </div>
                </div>
                <div className="row pt-1 mx-1">
                    <div className="col-12"><strong>Deeds</strong></div>
                </div>
                {deedState && deedState.loading && <SpinnerLoader style={{ height: '25px', width: '25px' }} /> }
                {deedState && (!deedState.loading && (!deedState.deeds || deedState.deeds.length === 0)) ? <div className="downloadInfo ms-3">Sorry, there are no deeds found for this property.</div> :
                    deedState && deedState.deeds && deedState.deeds.map(function (deed, index) {
                        return <Fragment key={index}>
                            <div className="row shadowbox mx-1 lh-sm pb-2 downloadInfo no-localization">
                                <div className="col-3">{deed.documentNo}</div>
                                <div className="col-1 p-0 ps-md-2">{deed.instrumentType}</div>
                                <div className="col-2 p-0 ps-md-2">{DateTime.fromISO(deed.filedDate).toFormat("M/d/yyyy")}</div>
                                <div className="col-4 ps-1-0 ps-md-2">{deed.addition}</div>
                                <div className="col-1 p-0 ps-md-2">{deed.lot && deed.lot + "/"}{deed.block && deed.block}</div>
                                <div className="col-1 text-center  p-0 ps-md-2">
                                    <input aria-label={"Deed " + deed.documentNo}
                                        onChange={(e) => {
                                            const { id, checked } = e.target;
                                            setCheckedDeed([...checkedDeed, id]);
                                            if (!checked) {
                                                setCheckedDeed(checkedDeed.filter(item => item !== id));
                                            }
                                        }}
                                        id={deed.docLink}
                                        value={checkedDeed}
                                        type="checkbox"
                                        checked={checkedDeed.includes(deed.docLink)}
                                    />
                                </div>
                            </div>
                        </Fragment>;
                    }
                    )}
                <div className="row pt-1 mx-1">
                    <div className="col-12"><strong>CC&Rs</strong></div>
                </div>
                {ccrState.loading && <SpinnerLoader style={{ height: '25px', width: '25px' }} />}
                {(!ccrState.loading && (!ccrState.ccrs || ccrState.ccrs.length === 0)) ? <div className="downloadInfo ms-3">Sorry, there are no CC&Rs found for this property.</div> :
                    selectedBrand.brandId === 2 ?
                        Object.entries(ccrState.ccrs.reduce(function (r, a) {
                            r[a.folderName] = r[a.folderName] || [];
                            r[a.folderName].push(a);
                            return r;
                        }, Object.create(null))).map((folder, index) => {
                            return <Fragment key={"folder" + index}><span className="downloadInfo d-block" >{folderGroupOpen === index ? <MdOutlineRemove className="sm-react-icons pe-1" /> : <MdOutlineAdd className="sm-react-icons pe-1" />}<Link to="" onClick={() => setFolderGroupOpen(folderGroupOpen === index ? -1 : index)} data-bs-toggle="collapse" data-bs-target={"#folder" + index}>{folder[0]}</Link></span>
                                <div className="collapse" id={"folder" + index}>
                                    {folder[1].map((ccr, ccrIndex) => {
                                        return <div className="row shadowbox mx-1 downloadInfo no-localization" key={"ccr" + ccrIndex}>
                                            <div className="col-6">{ccr.documentName}</div>
                                            <div className="col-5">{ccr.instrumentType}</div>
                                            <div className="col-1 text-center p-0 ps-md-2">
                                                <input aria-label={"CCR " + ccr.documentName }
                                                    onChange={(e) => {
                                                        const { id, checked } = e.target;
                                                        setCheckedCcr([...checkedCcr, id]);
                                                        if (!checked) {
                                                            setCheckedCcr(checkedCcr.filter(item => item !== id));
                                                        }
                                                    }}
                                                    id={ccr.documentLink}
                                                    value={checkedCcr}
                                                    type="checkbox"
                                                    checked={checkedCcr.includes(ccr.documentLink)}
                                                />
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <ul>

                                </ul>
                            </Fragment>
                        }) : ccrState.ccrs && ccrState.ccrs.map(function (ccr, index) {
                            return <Fragment key={index}>
                                <div className="row shadowbox mx-1 downloadInfo no-localization">
                                    <div className="col-11">
                                        {getDisplayName(ccr)}
                                    </div>
                                    <div className="col-1 text-center p-0 ps-md-2">
                                        <input aria-label={"CCR " + getDisplayName(ccr) }
                                            onChange={(e) => {
                                                const { id, checked } = e.target;
                                                setCheckedCcr([...checkedCcr, id]);
                                                if (!checked) {
                                                    setCheckedCcr(checkedCcr.filter(item => item !== id));
                                                }
                                            }}
                                            id={getDisplayName(ccr)}
                                            value={checkedCcr}
                                            type="checkbox"
                                            checked={checkedCcr.includes(getDisplayName(ccr))}
                                        />

                                    </div>
                                </div>
                            </Fragment>;
                        })
                }
                <div className="row pt-1 mx-1">
                    <div className="col-12"><strong>Locator Maps</strong></div>
                </div>
                {(!aerialMap || aerialMap.length === 0) ? <div className="downloadInfo ms-3">Sorry, no aerial map was found for this property.</div> :
                    <div className="row downloadInfo shadowbox mx-1">
                        <div className="col-11">Lot Line Aerial Map</div>
                        <div className="col-1 text-center p-0 ps-md-2">
                            <input aria-label="Lot Line Aerial Map"
                                type="checkbox"
                                checked={checkedAerial}
                                onChange={() => setCheckedAerial(toggleCheckbox)}
                            />
                        </div>
                    </div>
                }
                {(!streetLocatorMap || streetLocatorMap.length === 0) ? <div className="downloadInfo ms-3">Sorry, no street locator map was found for this property.</div> :
                    <div className="row downloadInfo shadowbox mx-1">
                        <div className="col-11">Lot Line Street Map</div>
                        <div className="col-1 text-center p-0 ps-md-2">
                            <input aria-label="Lot Line Street Map"
                                type="checkbox"
                                checked={checkedStreetLocator}
                                onChange={() => setCheckedStreetLocator(toggleCheckbox)}
                            />
                        </div>
                    </div>
                }
                {(!platMap || platMap.length === 0) ? <div className="downloadInfo ms-3">Sorry, no plat map was found for this property.</div> :
                    <div className="row downloadInfo shadowbox mx-1">
                        <div className="col-11">Plat Map</div>
                        <div className="col-1 text-center p-0 ps-md-2">
                            <input aria-label="Plat Map"
                                type="checkbox"
                                checked={checkedPlat}
                                onChange={() => setCheckedPlat(toggleCheckbox)}
                            />
                        </div>
                    </div>
                }
                {!streetMapAvailable ? <div className="downloadInfo ms-3">Sorry, no street map was found for this property.</div> :
                    <Fragment>
                        <div className="row downloadInfo shadowbox mx-1">
                            <div className="col-11">Street Map</div>
                            <div className="col-1 text-center p-0 ps-md-2">
                                <input aria-label="Street Map"
                                    type="checkbox"
                                    checked={checkedStreet}
                                    onChange={() => setCheckedStreet(toggleCheckbox)}
                                />
                            </div>
                        </div>
                        <div className="row downloadInfo shadowbox mx-1">
                            <div className="col-11">Include Street Map Route From:</div>
                            <div className="col-1 text-center p-0 ps-md-2">
                                <input aria-label="Street map route"
                                    type="checkbox"
                                    checked={checkedRoute}
                                    onChange={() => setCheckedRoute(toggleCheckbox)}
                                />
                            </div>
                        </div>
                        <div className="row pt-1">
                            <div className="col-11">
                                <form>
                                    <div className="row mb-2">
                                        <div className="col-8">
                                            <input name="originDownload" type="text" id="originDownload" placeholder="Enter from address" className="form-control" {...register("originDownload")} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Fragment>
                }
                <div className="row pt-1 shadowbox mx-1">
                    <div className="col-11"><strong>Add My Logo</strong></div>
                    <div className="col-1 text-center p-0 ps-md-2">
                        <input
                            type="checkbox" aria-label="Add My Logo"
                            checked={checkedLogo}
                            onChange={() => setCheckedLogo(toggleCheckbox)}
                        />
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12 text-end">
                        <Link to="/" data-bs-toggle="modal" data-bs-target="#emailModal" className="pe-2"><MdOutlineEmail className="sm-react-icons" /> Email</Link>
                        <Link to="" onClick={() => doDownload()}><MdOutlineFileDownload className="sm-react-icons" /> Download</Link>
                    </div>
                </div>

                <div className="row pt-3">
                    <div className="col-12 text-end"><strong><Link to={"/net-sheet/address/" + county + "/" + parcelId}>Start a net sheet for this property<MdOutlineCalculate className="sm-react-icons" /></Link></strong>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-12 text-end"><strong><Link to={"/targeted-marketing/radius/" + state + "/" + county + "/" + parcelId}>Create a farming list<MdOutlineMap className="sm-react-icons" /></Link></strong>
                    </div>
                </div>
            </div>}
    </>
    );
}

export default DownloadEmailProfile;
