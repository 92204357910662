import React, { useEffect, useState, useRef } from "react";
import { useContext } from "react";
import { FilePath, EventTrack } from "../../services/utilities";
import { BrandContext } from "../../contexts/brand-context";
import "./home-hero.scss"
import { bootstrap } from '../../app';
import { Link, useHref } from "react-router-dom";

const HomeHero = () => {
    const [slides, setSlides] = useState([]);
    const { selectedBrand } = useContext(BrandContext);
    const heroCarousel = useRef(null);
    const basename = useHref('/');
    const [selectedVideo, setSelectedVideo] = useState();

    useEffect(() => {
        if (!selectedBrand || !selectedBrand.heroImages) return;
        var startingIndex = (selectedBrand.brandId === 1) ? Math.floor(Math.random() * selectedBrand.heroImages.length) : 0;
        setSlides(selectedBrand.heroImages.map(function (heroImage, index) {
            var cls = index === startingIndex ? "carousel-item circleWipe active" : "carousel-item circleWipe";
            const alt = heroImage.altTag ? heroImage.altTag : "Hero " + (index + 1);

            const fileType = heroImage.url.substring(heroImage.url.length - 3);
            return fileType === 'mp4' ? <div className={cls} key={index}><Link to={heroImage.link ? heroImage.link : "/"} onClick={() => EventTrack(heroImage.altTag, "Home Hero Clicked", `Position=${index + 1}`)}><video muted autoPlay loop playsInline controls key={index}><source key={index} src={FilePath(`${(basename === "/") ? "" : basename}${heroImage.url}`)} type="video/mp4" /></video></Link></div>
                : <div className={cls} key={index}><Link to={heroImage.link ? heroImage.link : "/"} onClick={() => EventTrack(heroImage.altTag, "Home Hero Clicked", `Position=${index + 1}`)}><img key={index} className="d-block w-100" src={FilePath(`${(basename === "/") ? "" : basename}${heroImage.url}`)} alt={alt} /></Link></div>;
        }));

    }, [selectedBrand, basename]);

    useEffect(() => {

        if (heroCarousel.current && slides.length > 0) {
            var heroCarouselObj = new bootstrap.Carousel(heroCarousel.current);
            heroCarouselObj.cycle();
        }
    }, [slides]);

    if (selectedBrand && selectedBrand.heroImages && selectedBrand.heroImages.length === 1)
        return (<div className="row g-0 pb-2">
            <div className="col-12">
                <Link to={selectedBrand.heroImages[0].link ? selectedBrand.heroImages[0].link : "/"} onClick={() => EventTrack(selectedBrand.heroImages[0].altTag, "Home Hero Clicked")}><img className="d-block w-100 img-fluid mx-auto" src={FilePath(selectedBrand.heroImages[0].url)} alt="Hero" /></Link>
            </div>
        </div>);

    return (

        selectedBrand &&
            <>
                {slides && slides.length > 0 ?
                    <div id="carouselHomeHeros" className="carousel slide carousel-fade carousel-dark" data-bs-interval="6000" data-bs-pause="false" ref={heroCarousel}>
                        <div className="carousel-inner">{slides}</div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselHomeHeros" data-bs-slide="prev">
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselHomeHeros" data-bs-slide="next">
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    : <div className={selectedBrand && selectedBrand.brandId !== 4 && 'hero-image-spacer'} ></div>
                }
            </>
    );

}

export default HomeHero;